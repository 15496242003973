<template>
  <div>
    <!-- <div class="content-body"> -->
    <!-- <div class="container-fluid"> -->

    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <!-- <div class="card-header">
                  <h6 class="text-success">Create roles for Staffs</h6>
                </div> -->
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Name of Supplier </label>
                  <input
                    type="text"
                    placeholder="Full Name"
                    class="form-control"
                    v-model="supplier.supplier_name"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Phone Number</label>
                  <input
                    type="text"
                    placeholder="080000"
                    class="form-control"
                    v-model="supplier.phone_no"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Email</label>
                  <input
                    type="text"
                    placeholder="supplier@gmail.com"
                    class="form-control"
                    v-model="supplier.email"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label> Address</label>
                  <textarea
                    class="form-control"
                    placeholder="Wisteria Lane"
                    rows="4"
                    v-model="supplier.address"
                  ></textarea>
                </div>
              </div>

              <button class="btn btn-success btn-block" type="submit">
                Submit <i class="las la-paper-plane"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
  
  <script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Loading,
  },
  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      edit: false,
      activities: "",
      supplier: {
        supplier_name: "",
        facility_id: this.$selected_facility.id,
        email: "",
        phone_no: "",
        address: "",
      },
    };
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addSupplier();
      } else {
        this.updateSupplier();
      }
    },
    addSupplier() {
      this.isLoading = true;

      this.axios
        .post("/staff/suppliers", this.supplier)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toastr.s("Supplier Added Successfully", "Success!");
          this.clearForm();
          this.$emit("update");
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");
          this.isLoading = false;
        });
    },
    updateSupplier() {
      this.isLoading = true;
      this.axios
        .patch(`/staff/suppliers/${this.supplier.id}`, this.supplier)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.clearForm();
          this.$emit("update");
          this.$toastr.s("Supplier Updated Successfully", "Success!");

        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.$toastr.e("not successful", "Error!");
        });
    },

    setValue(value) {
      this.edit = true;
      this.supplier = value;
      console.log(value);
    },
    clearForm() {
      this.supplier.id = "";
      this.supplier.supplier_name = "";
      this.supplier.address = "";
      this.supplier.phone_no = "";
      this.supplier.email = "";
      this.edit = false;
    },
  },
  created() {
   //
  },
};
</script>
  