<template>
  <div>
    <div class="col-md-12">
      <div class="">
        <div class="settings-form">
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label> Select Product</label>
                  <v-select
                    v-model="item.product_id"
                    :reduce="(name) => name.id"
                    label="name"
                    :options="products.data"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Date Transferred</label>
                  <input
                    type="date"
                    placeholder=""
                    class="form-control"
                    v-model="item.date_dispensed"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label>Quantity</label>
                  <input
                    type="number"
                    placeholder="20"
                    class="form-control"
                    v-model="item.quantity"
                  />
                </div>

                <div class="form-group col-md-12">
                  <label>Receiving Department</label>
                  <v-select
                    v-model="item.department_id"
                    :reduce="(name) => name.id"
                    label="name"
                    :options="departments.data"
                  />
                </div>

                <div class="form-group col-md-12">
                  <label> Collecting Officer</label>
                  <v-select
                    v-model="item.collecting_officer"
                    :reduce="(first_name) => first_name.id"
                    label="first_name"
                    :options="staffs.data"
                  />
                </div>

                <div class="form-group col-md-12">
                  <div class="custom-control custom-checkbox mb-3">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="customCheckBox1"
                      v-model="item.is_pharmacy"
                      required
                    />
                    <label class="custom-control-label" for="customCheckBox1"
                      >Transferring to Pharmacy?</label
                    >
                  </div>
                </div>

                <div class="card col-md-12">
                  <div class="card-header">
                    <p>Receiving Officer Append Signature below</p>
               
                  </div>
                  <div class="card-body">
                    <!-- <vue-drawing-canvas
                      :image.sync="item.signature"
                      width="400"
                      ref="VueCanvasDrawing"
                      height="300"
                    /> -->
                  </div>
                  <div class="buttons mb-2">
                    <button
                      class="btn btn-outline-success"
                      @click.prevent="$refs.VueCanvasDrawing.undo()"
                    >
                      <i class="las la-undo-alt"></i>
                      Undo
                    </button>
                  </div>
                </div>
              </div>

              <button class="btn btn-success btn-block mb-4" type="submit">
                Submit <i class="las la-paper-plane"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
    
    <script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin
// import VueDrawingCanvas from "vue-drawing-canvas";
// import { reloadPage } from "@/functions/Settings";

export default {
  components: {
    Loading,
    // VueDrawingCanvas,
  },
  data() {
    return {
      user: null,
      windowwith: "",
      isLoading: false,
      fullPage: true,
      edit: false,
      departments: "",
      staffs: "",
      products: "",
      items: "",
      item: {
        product_id: "",
        department_id: "",
        collecting_officer: "",
        transferring_officer: this.$user.id,
        quantity: "",
        is_pharmacy: false,
        date_dispensed: "",
        expiry_date: "",
        date_time: "",
        signature: "",
      },
    };
  },
  // beforeCreate() {
  //    reloadPage();
  // },
  beforeMount() {
    this.windowwith = window.innerWidth * 0.75;
  },
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addItem();
      } else {
        this.updateItem();
      }
    },
    addItem() {
      this.isLoading = true;
      this.axios
        .post("/staff/stock-transfer", this.item)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.clearForm()
          this.$toastr.s("Transfer Completed Successfully", "Success!");
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");
          this.isLoading = false;
        });
    },
    updateItem() {
      this.isLoading = true;
      this.axios
        .patch(`/staff/stock-transfer/${this.item.id}`, this.item)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.clearForm();
          this.$emit("update");
          this.$toastr.s("Updated Successfully", "Error!");
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.$toastr.e("not successful", "Error!");
        });
    },
    getStaffs() {
      this.isLoading = true;
      this.axios
        .get("/staff/profile")
        .then((response) => {
          console.log(response);
          this.staffs = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");
          this.isLoading = false;
        });
    },

    getProducts() {
      this.isLoading = true;
      this.axios
        .get("/staff/products")
        .then((response) => {
          console.log(response);
          this.products = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");
          this.isLoading = false;
        });
    },

    getDepts() {
      this.isLoading = false;
      this.axios
        .get(`/staff/departments`)
        .then((response) => {
          this.departments = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.$toastr.e("Error Querying Department", "Error!");
        });
    },

    setValue(value) {
      this.edit = true;
      this.item = value;
      console.log(value);
    },

    getStocks() {
      this.isLoading = false;
      this.axios
        .get(`/item`)
        .then((response) => {
          this.items = response.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },

    clearForm() {
      this.item.id = "";
      this.item.product_id = "";
      this.item.department_id = "";
      this.item.collecting_officer = "";
      this.item.quantity = "";
      this.item.description = "";
      this.item.expiry_date = "";
      this.item.date_time = "";
      this.item.is_pharmacy = false;
      this.edit = false;
    },
  },
  created() {
    this.getDepts();
    this.getStaffs();
    this.getProducts();
  },
};
</script>
    