<template>
  <div>
    <!-- <div class="content-body"> -->
    <!-- <div class="container-fluid"> -->

    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <!-- <div class="card-header">
                <h6 class="text-success">Create roles for Staffs</h6>
              </div> -->
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="inputPassword4">Select Role </label>
                  <v-select
                    v-model="form_data.name"
                    label="Title"
                    :options="roles"
                  />
                </div>

                <div class="form-group col-md-12">
                  <label>Role Title </label>
                  <input
                    type="text"
                    disabled
                    placeholder="Doctor, Nurse, Receptionist"
                    class="form-control"
                    v-model="form_data.name"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label> Description</label>
                  <textarea
                    class="form-control"
                    placeholder="Describe the Role..."
                    rows="4"
                    v-model="form_data.description"
                  ></textarea>
                </div>
              </div>

              <!-- <div class="form-group col-md-12">
                <label for="inputPassword4"
                  >Select Activities for this Role
                </label>
                <v-select
                  multiple
                  v-model="form_data.Acts"
                  :reduce="(Title) => Title.Id"
                  label="Title"
                  :options="activities"
                />
              </div> -->

              <button class="btn btn-success btn-block" type="submit">
                Submit <i class="las la-paper-plane"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>

<script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Loading,
  },

  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      edit: false,
      activities: "",
      form_data: {
        id: "",
        name: "",
        // Acts: "",
        description: "...",
      },
      roles: [
        "Doctor",
        "Nurse",
        "Pharmacy",
        "Laboratory",
        "Reception",
        "Accountant",
        "Driver",
        "Cashier",
        "Records",
        "Inventory",
        "Insurance",
      ],
    };
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.selected_facility = JSON.parse(
      localStorage.getItem("selected_facility")
    );
  },
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addDept();
      } else {
        this.editDept();
      }
    },
    addDept() {
      this.isLoading = true;
      this.axios
        .post("/staff/job-titles", {
          name: this.form_data.name,
          facility_id: this.$selected_facility.id,
          description: this.form_data.description,
        })
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toastr.s("Role Created Successfully", "Success!");
          this.$emit("update");
          this.clearForm();
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");

          this.isLoading = false;
        });
    },
    editDept() {
      this.isLoading = true;
      this.axios
        .put(`/staff/job-titles/${this.form_data.id}`, this.form_data)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toasted.success("Updated Successfully!", {
            position: "top-center",
            duration: 3000,
          });
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },

    clearForm() {},

    getActivities() {
      this.isLoading = false;
      this.selected_facility = JSON.parse(
        localStorage.getItem("selected_facility")
      );

      this.axios
        .get(`/activities`)
        .then((response) => {
          this.activities = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },

    setValue(value) {
      this.edit = true;
      this.form_data = value;
      console.log(value);
    },
  },
  created() {
    this.getActivities();
  },
};
</script>
