<template>
    <div>
      <Header />
  
      <div class="content-body">
        <div class="container-fluid">
           <div class="form-head d-flex mb-sm-4 mb-3">
            <div class="mr-auto">
              <h2 class="text-black font-w600">Invoice Manager</h2>
              <p class="mb-0">Create and Manage invoices here</p>
            </div>
            <div>
              <a
                href="javascript:void(0)"
                class="btn btn-success mr-3"
                @click="showForm = !showForm"
                v-b-toggle.sidebar-right
                ><i class="las la-plus scale5 mr-3"></i> Create Invoice</a
              >
  
  
              <!-- <a href="#" class="btn btn-outline-success"
                ><i class="las la-calendar-plus scale5 mr-3"></i>Filter</a
              > -->
            </div>
         
          </div>
           <b-sidebar id="sidebar-right" title="Create Invoice" right  shadow width="630px">
  
        <div class="px-3 py-2">
          <AddInvoice v-show="showForm" />
          
        </div>
      </b-sidebar>
  
          
       
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">Invoices</h4>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-responsive-md">
                      <thead>
                        <tr>
                          <th class="width80"><strong>#</strong></th>
                          <th><strong>Title</strong></th>
                          <th><strong>Description</strong></th>
                          <th><strong>Amount</strong></th>
                          <th><strong>Created At</strong></th>
                          <th><strong>Action</strong></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(service, index) in services.data"
                          :key="service.Id"
                        >
                          <td>
                            <strong> {{ index + 1 }} </strong>
                          </td>
                          <td>{{ service.Title }}</td>
                          <td>{{ service.Description }}</td>
                          <td>{{ service.Amount | numeral(0,0) }}</td>
                          <td>{{ service.Created  | moment("from", "now") }}</td>
                          <td>
                            <span>
                              <a
                                href="javascript:void()"
                                class="mr-4"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Edit"
                                @click="updateRole(role)"
                                ><i class="fa fa-pencil color-muted text-success"></i>
                              </a>
                              <a
                                href="javascript:void()"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Close"
                                @click="deleteRole(role)"
                                ><i class="fa fa-close color-danger text-success"></i
                              ></a>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
   <div class="vld-parent">
        <loading
          :active.sync="isLoading"
          loader="spinner"
          :can-cancel="true"
          :is-full-page="fullPage"
        ></loading>
      </div>
  
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Header from "@/views/Header.vue";
  import AddInvoice from "@/views/finances/AddInvoice.vue";
  // Import component
  import Loading from "vue-loading-overlay";
  // Import stylesheet
  import "vue-loading-overlay/dist/vue-loading.css";
  // Init plugin
  
  export default {
    components: {
      Header,
      Loading, AddInvoice
    },
    data() {
      return {
        user: null,
        selected_facility: null,
        isLoading: false,
        fullPage: true,
        showForm: false,
        edit: false,
        services: "",
        bed: {
          Title: "",
          Description: "",
          Acts: "",
        },
      };
    },
    beforeMount() {
      this.user = JSON.parse(localStorage.getItem("user"));
      this.selected_facility = JSON.parse(
        localStorage.getItem("selected_facility")
      );
    },
    methods: {
      submitForm() {
        if (this.edit == false) {
          this.addDept();
        } else {
          this.editDept();
        }
      },
      addRole() {
        this.isLoading = true;
        this.axios
          .post("/roles", {
            Title: this.role.Title,
            Acts: this.role.Acts,
            Description: this.role.Description,
          })
          .then((response) => {
            console.log(response);
            this.isLoading = false;
            this.$toastr.s("Role Created Successfully", "Success!");
            this.getRoles();
          })
          .catch((error) => {
            console.log(error.response);
            this.$toastr.e("Error on Adding!");
  
            this.isLoading = false;
          });
      },
      updateRole(role) {
        this.isLoading = true
         this.axios
          .put(`/roles`, role)
          .then((response) => {
            console.log(response);
            this.isLoading = false
            this.getRoles();
            this.isLoading = false
            this.$toasted.success("Updated Successfully!", {
              position: "top-center",
              duration: 3000,
            });
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false
          });
      },
      getServices() {
        this.isLoading = true
        this.selected_facility = JSON.parse(
          localStorage.getItem("selected_facility")
        );
  
        this.axios
          .get(`/services`)
          .then((response) => {
            this.services = response.data;
            console.log(response);
             this.isLoading = false
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false
          });
      },
      deleteRole(role) {
  if (confirm('Are you Sure you want to delete?') ) {
        this.isLoading = true
         this.axios
          .patch(`/roles/${role.Id}`)
          .then((response) => {
            console.log(response);
            this.isLoading = false
            this.getRoles();
            this.isLoading = false
            this.$toasted.success("Deleted Successfully!", {
              position: "top-center",
              duration: 3000,
            });
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false
          });
  }
      },
      
    },
    created() {
      this.getServices();
    },
  };
  </script>
  