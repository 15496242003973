<template>
  <div>
    <!-- <div class="content-body"> -->
    <!-- <div class="container-fluid"> -->

    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <!-- <div class="card-header">
                    <h6 class="text-success">Create roles for Staffs</h6>
                  </div> -->
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Date of Order </label>
                  <input
                    type="date"
                    placeholder="11/11/11"
                    class="form-control"
                    v-model="order.date"
                  />
                </div>

                <div class="form-group col-md-12">
                  <label for="inputPassword4">Select Supplier</label>
                  <v-select
                    v-model="order.supplier_id"
                    :reduce="(supplier_name) => supplier_name.id"
                    label="supplier_name"
                    :options="suppliers.data"
                  />
                </div>

                <div class="form-group col-md-12">
                  <label for="inputPassword4">Select Product</label>
                  <v-select
                    v-model="order.product_id"
                    :reduce="(name) => name.id"
                    label="name"
                    :options="products.data"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Amount</label>
                  <input
                    type="number"
                    placeholder="45000.00"
                    class="form-control"
                    v-model="order.amount"
                  />
                </div>

                <div class="form-group col-md-12">
                  <label>Quantity</label>
                  <input
                    type="number"
                    placeholder="30"
                    class="form-control"
                    v-model="order.quantity"
                  />
                </div>
              </div>

            <div class="form-row">
              <div class="form-group col-md-12">
                  <label>Total Amount</label>
                  <input disabled
                    type="number"
                    placeholder="30"
                    class="form-control"
                    :value="totalAmount"
                  />
                </div>
             

              <div class="form-group col-md-12">
                <label for="inputPassword4">Status of Order</label>
                <v-select
                  v-model="order.status"
                  label="Select Status"
                  :options="statuses"
                />
              </div>

            </div>
              <button class="btn btn-success btn-block" type="submit">
                Submit <i class="las la-paper-plane"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
    
    <script>

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Loading,
  },
  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      edit: false,
      activities: "",
      statuses: ["pending", "paid", "delivered"],
      suppliers: "",
      products: "",
      order: {
        id: "",
        facility_id: this.$selected_facility.id,
        date: "",
        product_id: "",
        supplier_id: "",
        amount: "",
        quantity: "",
        total: "",
        total_amount:"",
        status: "",
      },
    };
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  computed:{
    totalAmount(){
      return this.order.amount  *   this.order.quantity
    }
  },
 
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addOrder();
      } else {
        this.updateOrder();
      }
    },
    addOrder() {
      this.isLoading = true;

      this.axios
        .post("/staff/orders", {
        facility_id: this.$selected_facility.id,
        date: this.order.date,
        product_id: this.order.product_id,
        supplier_id: this.order.supplier_id,
        amount: this.order.amount,
        quantity: this.order.quantity,
        total: this.order.total,
        total_amount:this.totalAmount,
        status: this.order.status,

        })
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toastr.s("Receive Inventory Created Successfully", "Success!");
          this.clearForm();
          this.$emit("update");
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");
          this.isLoading = false;
        });
    },
    updateOrder() {
      this.isLoading = true;
      this.axios
        .patch(`/staff/orders/${this.order.id}`, this.order)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.clearForm();
          this.$emit("update");
          this.$toastr.s("Updated Successfully", "Success!");

        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.$toastr.e("not successful", "Error!");
        });
    },

    getInventories() {
      this.isLoading = true;
      this.axios
        .get(`/staff/products`)
        .then((response) => {
          this.products = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },

    getSuppliers() {
      this.isLoading = true;

      this.axios
        .get(`/staff/suppliers`)
        .then((response) => {
          this.suppliers = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.$toastr.e("error on querying", "Error!");
        });
    },

    setValue(value) {
      this.edit = true;
      this.order = value;
      console.log(value);
    },
    clearForm() {
      this.order.id = "";
      this.order.supplier_id = "";
      this.order.product_id = "";
      this.order.amount = "";
      this.order.quantity = "";
      this.order.status = "";
      this.order.date = "";
      this.edit = false;
    },
  },
  created() {
    this.getInventories();
    this.getSuppliers();
  },
};
</script>
    