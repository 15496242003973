<template>
  <!-- Chart Card -->
  <div class="card">
    <!-- <div class="card-title">
      <h2 class="fs-20 font-w600 pl-4 pt-2">Outpatient Vs Inpatient Trend</h2>
    </div> -->
    <div class="card-body">
      <apexchart
        type="bar"
        height="300"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>

  <!-- End of Chart -->
</template>
  
  <script>
export default {
  data() {
    return {
      series: [
        {
          name: "Out Patient",
          data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
        },
        {
          name: "In Patient",
          data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
        },
       
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        title: {
          text: "Outpatient Vs Inpatient Trend",
          align: "left",
        },
        xaxis: {
          categories: [
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
          ],
        },
        yaxis: {
          title: {
            text: "Number of Admissions",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return  val + " patients";
            },
          },
        },
      },
    };
  },
};
</script>