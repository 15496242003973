<template>
  <body class="h-100" style="margin-top: 100px">
    <div class="authincation h-100">
      <div class="container h-100">
        <div class="row justify-content-center h-100 align-items-center">
          <div class="col-md-6">
            <div class="authincation-content">
              <div class="row no-gutters">
                <div class="col-xl-12">
                  <div class="auth-form">
                    <div class="text-center mb-3 text-white">Hello Admin,</div>
                    <h4 class="text-center mb-4 text-white">Account Locked</h4>
                    <form @submit.prevent="unlockApp">
                      <div class="form-group">
                        <label class="text-white"
                          ><strong
                            >Password
                          </strong></label
                        >
                        <input
                          type="password"
                          class="form-control"
                          v-model="password"
                        />
                      </div>
                      <div class="text-center">
                        <button
                          type="submit"
                          class="btn bg-white text-success btn-block"
                        >
                          Unlock
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      password: "",
    };
  },
  beforeMount() {},
  methods: {
    unlockApp() {
      if (this.$store.state.password == this.password) {
        this.$router.push("/dashboard");
        this.$toastr.s(`Welcome Back`, "Success!");

      } else {
        this.$toastr.e(`Invalid Password`, "Error!");
      }
    },
  },
  created() {},
};
</script>
