<template>
  <div>
    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <!-- <div class="card-header">
                <h6 class="text-success">Create inventorys for Staffs</h6>
              </div> -->
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label> Select Product</label>
                  <v-select
                    v-model="pharmacy.product_id"
                    :reduce="(name) => name.id"
                    label="name"
                    :options="products.data"
                  />
                </div>

                <div class="form-group col-md-12">
                  <label>Price</label>
                  <input
                    type="number"
                    placeholder="25000.00"
                    class="form-control"
                    v-model="pharmacy.price"
                  />
                </div>

                <div class="form-group col-md-12">
                  <label>Quantity </label>
                  <input
                    type="number"
                    placeholder="30"
                    class="form-control"
                    v-model="pharmacy.quantity"
                  />
                </div>

                <div class="form-group col-md-12">
                  <label>Minimum Required Quantity</label>
                  <input
                    type="number"
                    placeholder="100"
                    class="form-control"
                    v-model="pharmacy.minimum_required"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label> Description</label>
                  <textarea
                    class="form-control"
                    placeholder="Type your message..."
                    rows="4"
                    v-model="pharmacy.description"
                  ></textarea>
                </div>
              </div>

              <button class="btn btn-success btn-block" type="submit">
                Submit <i class="las la-paper-plane"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>

<script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Loading,
  },
  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      edit: false,
      products: "",
      pharmacy: {
        product_id: "",
        facility_id: "",
        quantity: "",
        price: "",
        minimum_required: "",
        description: "",
      },
    };
  },
  beforeMount() {},
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addModel();
      } else {
        this.updateModel();
      }
    },
    addModel() {
      this.isLoading = true;
      this.axios
        .post("/staff/pharmacy", this.pharmacy)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.clearForm();
          this.$emit("update");
          this.$toastr.s("Inventory Created Successfully", "Success!");
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");
          this.isLoading = false;
        });
    },
    updateModel() {
      this.isLoading = true;
      this.axios
        .patch(`/staff/pharmacy/${this.pharmacy.id}`, this.pharmacy)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.clearForm();
          this.$emit("update");
          this.$toastr.s("Updated Successfully", "Error!");
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.$toastr.e("not successful", "Error!");
        });
    },

    getProducts() {
      this.isLoading = true;
      this.axios
        .get("/staff/products")
        .then((response) => {
          console.log(response);
          this.products = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");
          this.isLoading = false;
        });
    },
    clearForm() {
      this.pharmacy.product_id = "";
      this.pharmacy.quantity = "";
      this.pharmacy.price = "";
      this.pharmacy.minimum_required = "";
      this.pharmacy.description = "";
      this.edit = false;
    },
    setValue(value) {
      this.edit = true;
      this.pharmacy = value;
      console.log(value);
    },
  },
  created() {
    this.getProducts();
  },
};
</script>
