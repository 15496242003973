<template>
  <div>
    <Header />

    <div class="content-body">
      <div class="container-fluid">
        <div class="form-head d-flex mb-sm-4 mb-3">
          <div class="mr-auto">
            <h2 class="text-black font-w600">Inventories Manager</h2>
            <p class="mb-0">Manage your inventories here</p>
          </div>
          <div>
            <div class="dropdown ml-auto mr-2">
              <a
                href="#"
                class="btn btn-success light sharp"
                data-toggle="dropdown"
                aria-expanded="true"
              >
                <li class="las la-ellipsis-h scale5"></li>
              </a>
              <ul class="dropdown-menu dropdown-menu-right">
                <li class="dropdown-item">
                  <span v-b-toggle.sidebar-inventory>
                    <a
                      href="javascript:void()"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Add"
                    >
                      <i class="lar la-object-group text-success mr-2"></i>
                    </a>
                    New Inventory
                  </span>
                </li>

                <li class="dropdown-item">
                  <span v-b-toggle.sidebar-stock>
                    <a
                      href="javascript:void()"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Add"
                    >
                      <i class="las la-store text-success mr-2"></i>
                    </a>
                    Add Stock
                  </span>
                </li>

                <li class="dropdown-item">
                  <span v-b-toggle.sidebar-category>
                    <a
                      href="javascript:void()"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Export"
                      ><i class="las la-file color-muted text-success mr-2"></i
                    ></a>
                    New Category
                  </span>
                </li>

                <li class="dropdown-item">
                  <span v-b-toggle.sidebar-transfer>
                    <a
                      href="javascript:void()"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Export"
                      ><i
                        class="
                          las
                          la-exchange-alt
                          color-muted
                          text-success
                          mr-2
                        "
                      ></i
                    ></a>

                    Transfer Item
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <b-sidebar
          id="sidebar-inventory"
          title="Add Inventory"
          :visible="showSidebar"
          right
          shadow
          width="500px"
        >
          <div class="px-3 py-2">
            <AddInventory @update="onUpdate($event)" ref="AddInventory" />
          </div>
        </b-sidebar>

        <b-sidebar
          id="sidebar-category"
          title="Manage Category"
          right
          shadow
          width="500px"
        >
          <div class="px-3 py-2">
            <AddInventoryCategory />
          </div>
        </b-sidebar>

        <b-sidebar
          id="sidebar-stock"
          title="Add Inventory to Stock"
          right
          shadow
          width="500px"
        >
          <div class="px-3 py-2">
            <AddInventoryStock />
          </div>
        </b-sidebar>

        <b-sidebar
          id="sidebar-transfer"
          title="Transfer Inventory"
          right
          shadow
          width="500px"
        >
          <div class="px-3 py-2">
            <TransferInventory />
          </div>
        </b-sidebar>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group col-md-3 float-right">
              <b-form-input
                v-model="search"
                type="search"
                size="sm"
                placeholder="search inventory"
              ></b-form-input>
            </div>
          </div>
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Inventories</h4>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-responsive-md">
                    <thead>
                      <tr>
                        <th class="width80"><strong>#</strong></th>
                        <th><strong>Name</strong></th>
                        <th><strong>Minimum Required</strong></th>
                        <th><strong>Created At</strong></th>
                        <th><strong>Action</strong></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(inventory, index) in inventories.data"
                        :key="inventory.id"
                      >
                        <td>
                          <strong> {{ index + 1 }} </strong>
                        </td>
                        <td>{{ inventory.name }}</td>
                        <td>{{ inventory.minimum_required }}</td>
                        <td>
                          {{
                            inventory.created_at | moment("dddd, MMMM Do YYYY")
                          }}
                        </td>
                        <td>
                          <span>
                            <a
                              :href="`/inventory/${inventory.id}`"
                              class="mr-4"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="View"
                              ><i class="las la-eye text-success"></i>
                            </a>
                            <a
                              href="javascript:void()"
                              class="mr-4"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Edit"
                              v-b-toggle.sidebar-inventory
                              @click="startUpdate(inventory)"
                              ><i
                                class="fa fa-pencil color-muted text-success"
                              ></i>
                            </a>
                            <a
                              href="javascript:void()"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Close"
                              @click="deleteInventory(inventory)"
                            >
                              <i class="las la-trash text-success"></i
                            ></a>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="vld-parent">
          <loading
            :active.sync="isLoading"
            loader="spinner"
            :can-cancel="true"
            :is-full-page="fullPage"
          ></loading>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/views/Header.vue";
import AddInventory from "@/views/inventories/AddInventory.vue";
import AddInventoryCategory from "@/views/inventories/AddInventoryCategory.vue";
import AddInventoryStock from "@/views/inventories/AddInventoryStock.vue";
import TransferInventory from "@/views/inventories/TransferInventory.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin
import { reloadPage } from "@/functions/Settings";

export default {
  components: {
    Header,
    Loading,
    AddInventory,
    AddInventoryCategory,
    AddInventoryStock,
    TransferInventory,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      showSidebar: false,
      edit: false,
      search: "",
      inventories: "",
    };
  },
  beforeMount() {},
  beforeCreate() {
     reloadPage();
  },
  methods: {
    startUpdate(inventory) {
      this.showForm = true;
      this.$refs.AddInventory.setValue(inventory);
      console.log(inventory);
    },
    getInventories() {
      this.isLoading = true;
      this.axios
        .get(`/staff/products`)
        .then((response) => {
          this.inventories = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    deleteInventory(inventory) {
      if (confirm("Are you Sure you want to delete?")) {
        this.isLoading = true;
        this.axios
          .delete(`/staff/products/${inventory.id}`)
          .then((response) => {
            console.log(response);
            this.isLoading = false;
            this.getInventories();
            this.$toastr.s("Deleted Successfully", "Success!");
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
          });
      }
    },
    onUpdate() {
      this.getInventories();
      this.showSidebar = !this.showSidebar;
    },
  },
  created() {
    this.getInventories();
  },
};
</script>
