<template>
  <div>
    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="inputPassword4">Select Patient </label>
                  <v-select
                    v-model="form_data.patient_id"
                    :reduce="(first_name) => first_name.id"
                    label="full_name"
                    :options="patients.data"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label for="inputPassword4">Select Ambulance </label>
                  <v-select
                    v-model="form_data.ambulance_id"
                    :reduce="(vehicle_model) => vehicle_model.id"
                    label="vehicle_model"
                    :options="ambulances.data"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label>Date</label>
                  <input
                    type="date"
                    placeholder="12/12/12"
                    class="form-control"
                    v-model="form_data.date_of_call"
                  />
                </div>

                <div class="form-group col-md-12">
                  <label>Other Info.</label>
                  <textarea
                    class="form-control"
                    id=""
                    v-model="form_data.message"
                    rows="5"
                  ></textarea>
                </div>
              </div>

              <button class="btn btn-success btn-block" type="submit">
                Submit <i class="las la-paper-plane"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
      
      <script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Loading,
  },
  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      edit: false,
      patients: "",
      staffs: "",
      ambulances: "",
      form_data: {
        facility_id: this.$selected_facility.id,
        date_of_call: "",
        patient_id: "",
        ambulance_id: "",
        message: "",
      },
      statuses: ["Active", "Inactive"],
      ownerships: ["Owned", "Contractual"],
    };
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addDept();
      } else {
        this.updateCall();
      }
    },
    addDept() {
      this.isLoading = true;

      this.axios
        .post("/staff/emergency-calls", this.form_data)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toastr.s("Emergency Created Successfully", "Success!");
          this.clearForm();
          this.$emit("update");
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");
          this.isLoading = false;
        });
    },
    updateCall() {
      this.isLoading = true;
      this.axios
        .patch(`/staff/emergency-calls/${this.form_data.id}`, this.form_data)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.clearForm();
          this.$emit("update");
          this.$toastr.s("Updated Successfully", "Success!");
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.$toastr.e("not successful", "Error!");
        });
    },

    getPatients() {
      this.isLoading = true;
      this.axios
        .get(`/staff/patients`)
        .then((response) => {
          this.patients = response.data.data;
          this.isLoading = false;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    getAmbulances() {
      this.isLoading = true;
      this.axios
        .get(`/staff/ambulances`)
        .then((response) => {
          this.ambulances = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    setValue(value) {
      this.edit = true;
      this.form_data = value;
    },
    clearForm() {
      this.form_data.id = "";
      this.form_data.date_of_call = "";
      this.form_data.patient_id = "";
      this.form_data.ambulance_id = "";
      this.form_data.message = "";
      this.edit = false;
    },
  },
  created() {
    this.getAmbulances();
    this.getPatients();
  },
};
</script>
      