<template>
  <div>
    <Header />

    <!--**********************************
              Content body start
          ***********************************-->
    <div class="content-body" id="printDiv">
      <div class="container-fluid">
        <div class="page-titles">
          <h4>Appointment Details</h4>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="javascript:void(0)">Appointment</a>
            </li>
            <li class="breadcrumb-item active">
              <a href="javascript:void(0)"> APP{{ appointment.id }}</a>
            </li>
          </ol>
        </div>
        <!-- row -->
        <div class="row">
          <div class="col-md-4 col-lg-4 col-sm-12">
            <PatientCard :patient="patient" />
          </div>

          <div class="col-md-8">
            <div class="card">
              <div class="card-body">
                <div class="ml-0 ml-sm-4 ml-sm-0" ref="printNow">
                  <div class="row">
                    <div class="col-12">
                      <div class="right-box-padding">
                        <div class="read-content">
                          <div
                            class="media pt-3 d-sm-flex d-block justify-content-between"
                          >
                            <div class="clearfix mb-3 d-flex">
                              <div class="media-body mr-2">
                                <h5 class="text-success mb-0 mt-1">
                                  With
                                  {{ appointment.staff.initials }}
                                  {{ appointment.staff.full_name }}
                                </h5>
                                <p class="mb-0">
                                  {{
                                    appointment.date | moment("MMMM Do YYYY")
                                  }}
                                  {{ appointment.time | moment("h:mm:ss a") }}
                                </p>

                                <p class="mt-1">
                                  <a
                                    href="javascript:void()"
                                    class="btn btn-primary px-3 light"
                                  >
                                    {{ appointment.status }}
                                  </a>
                                </p>
                              </div>
                            </div>
                            <div class="clearfix mb-3">
                             

                              <a
                                href="javascript:void()"
                                @click="closeAppointment"
                                class="btn btn-primary px-3 light"
                              >
                              <i class="las la-check-square"></i>
                              </a>

                              <a
                                href="javascript:void()"
                               
                                data-toggle="modal"
                                data-target="#transferPatient"
                                class="btn btn-primary px-3 light ml-2"
                              >
                              <i class="las la-exchange-alt"></i>
                              </a>

                              <router-link
                                :to="`/add-record/${patient.card_no}`"
                                class="btn btn-primary px-3 light ml-2"
                                ><i class="las la-edit"></i>
                              </router-link>
                            </div>
                          </div>

                          <div class="read-content-body">
                            <hr />
                            <h5 class="mb-4">
                              <strong> Description </strong>
                            </h5>
                            <p class="mb-2">{{ appointment.description }}</p>

                            <hr />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Transfer Patient Modal -->
    <div class="modal fade" id="transferPatient">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Transfer Patient to Another Staff</h5>
            <button type="button" class="close" data-dismiss="modal">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="transferPatient">
              <div class="form-group col-md-12">
                <label for="inputPassword4">Select Staff </label>
                <v-select
                  v-model="staff_id"
                  label="full_name"
                  :reduce="(first_name) => first_name.id"
                  :options="staffs.data"
                />
              </div>

              <div class="form-group">
                <button type="submit" class="btn btn-success btn-block">
                  Submit

                  <i class="la la-send"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- End of Transfer Patient -->

    <!--**********************************
              Content body end
          ***********************************-->

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
</template>
  
  <script>
import Header from "@/views/Header.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin
import PatientCard from "@/views/records/PatientCard.vue";

export default {
  components: {
    Header,
    Loading,
    PatientCard,
  },
  data() {
    return {
      user: null,
      isLoading: false,
      fullPage: true,
      patient: "",
      appointment: "",
      staffs: "",
      staff_id: "",
    };
  },
  beforeMount() {},
  methods: {

    getStaffs() {
      this.isLoading = true;

      this.axios
        .get(`/staff/profile`)
        .then((response) => {
          this.staffs = response.data.data;
          this.isLoading = false;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },

    getAppointment() {
      this.axios
        .get(`/staff/appointments/${this.$route.params.id}`)
        .then((response) => {
          this.appointment = response.data.data;
          this.patient = response.data.data.patient;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    closeAppointment() {
      if (confirm("You are about to Mark Appointment as Completed?")) {
        this.axios
          .post(`/staff/update-appointment/status`, {
            id: this.$route.params.id,
            status: "completed",
          })
          .then((response) => {
            console.log(response);
            this.$toastr.s("Updated Successfully", "Success!");
            this.getAppointment();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },

    transferPatient() {
      if (confirm("Are you Sure you want to Transfer Patient?")) {
        this.axios
          .post(`/staff/transfer-patient`, {
            id: this.$route.params.id,
            staff_id: this.staff_id,
          })
          .then((response) => {
            console.log(response);
            this.$toastr.s("Updated Successfully", "Success!");
            this.getAppointment();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },

    printMe() {
      var printContents = document.getElementById("printDiv").innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    },
  },
  created() {
    this.getAppointment();
    this.getStaffs();
  },
};
</script>
  
  <style scoped></style>
  