<template>
  <div>
    <Header />

    <!--**********************************
              Content body start
          ***********************************-->
    <div class="content-body" id="printDiv">
      <div class="container-fluid">
        <div class="page-titles">
          <h4>Bill Details</h4>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="javascript:void(0)">Bill</a>
            </li>
            <li class="breadcrumb-item active">
              <a href="javascript:void(0)"> BLX{{ bill.id }}</a>
            </li>
          </ol>
        </div>
        <!-- row -->
        <div class="row"  id="printDiv">
          <div class="col-md-4 col-lg-4 col-sm-12">
            <PatientCard :patient="patient" />
          </div>

          <div class="col-md-8">
            <div class="card">
              <div class="card-body">
                <div class="ml-0 ml-sm-4 ml-sm-0" ref="printNow">
                  <div class="row">
                    <div class="col-12">
                      <div class="right-box-padding">
                        <div class="read-content">
                          <div
                            class="media pt-3 d-sm-flex d-block justify-content-between"
                          >
                            <div class="clearfix mb-3 d-flex">
                             
                              <div class="media-body mr-2">
                                <h5 class="text-success mb-0 mt-1"></h5>
                                <p class="mb-0">
                                  {{ bill.created_at | moment("MMMM Do YYYY") }}
                                </p>
                              </div>
                            </div>
                            <div class="clearfix mb-3">
                              <a
                              @click="printMe"
                                href="javascript:void()"
                                class="btn btn-primary px-3 light"
                              >
                                <i class="las la-print"></i>
                              </a>
                             
                            </div>
                          </div>

                          <div class="read-content-body">
                            <div class="row">
                              <div class="col-lg-12">
                                <div class="card">
                                  <div class="card-header">
                                    Bill
                                    <!-- <strong> {{ bill.created_at   | moment("MMMM Do YYYY") }} </strong> -->
                                    <span class="float-right">
                                      <strong>Status:</strong> {{ bill.status }}
                                    </span>
                                  </div>
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-xl-6 col-sm-6 mb-4">
                                        <h6>From:</h6>
                                        <div><strong> {{ $selected_facility.title }} </strong></div>
                                        
                                      </div>
                                      <div class="col-xl-6 col-sm-6 mb-4">
                                        <h6>To:</h6>
                                        <div><strong> {{ bill.patient.full_name }} </strong></div>
                                        <div> {{ bill.patient.address }} </div>
                                        <div>Phone: {{ bill.patient.phone_no }} </div>
                                      </div>
                                      <div
                                        class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-4 d-flex justify-content-lg-end justify-content-md-center justify-content-xs-start"
                                      >
                                      
                                      </div>
                                    </div>
                                    <div class="table-responsive">
                                      <table
                                        class="table table-striped table-responsive-md"
                                      >
                                        <thead>
                                          <tr>
                                            <th class="center">#</th>
                                            <th>Item</th>
                                            <th class="right">Unit Cost</th>
                                            <th class="center">Qty</th>
                                            <th class="right">Total</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr v-for="(item, index) in bill.bill_items" :key="item.id">
                                            <td class="center">{{ index +1 }} </td>
                                            <td class="left strong">
                                              {{ item.service.name }}
                                            </td>
                                           
                                            <td class="right"> {{ item.amount / item.quantity  | numeral(0,0.00) }} </td>
                                            <td class="center"> {{ item.quantity }} </td>
                                            <td class="right">&#x20A6;{{ item.amount | numeral(0,0.00) }} </td>
                                          </tr>
                                        
                                         
                                        
                                        </tbody>
                                      </table>
                                    </div>
                                    <div class="row">
                                      <div class="col-lg-4 col-sm-5"></div>
                                      <div class="col-lg-4 col-sm-5 ml-auto">
                                        <table class="table table-clear">
                                          <tbody>
                                            <tr>
                                              <td class="left">
                                                <strong>Subtotal</strong>
                                              </td>
                                              <td class="right"> &#x20A6;{{ bill.bill_items_sum_amount | numeral(0,0.0)}} </td>
                                            </tr>
                                           
                                          
                                            <tr>
                                              <td class="left">
                                                <strong>Total</strong>
                                              </td>
                                              <td class="right">
                                                <strong>&#x20A6;{{ bill.bill_items_sum_amount | numeral(0,0.0) }} </strong><br />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--**********************************
              Content body end
          ***********************************-->

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
</template>
  
  <script>
import Header from "@/views/Header.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin
import PatientCard from "@/views/records/PatientCard.vue";

export default {
  components: {
    Header,
    Loading,
    PatientCard,
  },
  data() {
    return {
      user: null,
      isLoading: false,
      fullPage: true,
      patient: "",
      bill: "",
    };
  },
  beforeMount() {},
  methods: {
    getBill() {
      this.axios
        .get(`/staff/bills/${this.$route.params.id}`)
        .then((response) => {
          this.bill = response.data;
          this.patient = response.data.patient;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    printMe() {
      var printContents = document.getElementById("printDiv").innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    },
  },
  created() {
    this.getBill();
  },
};
</script>
  
  <style scoped></style>
  