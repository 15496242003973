import { connection } from "./jsstore_con";

export class PatientService {

    constructor() {

        this.tableName = "patients";
    }

    getPatients() {
        return connection.select({
            from: this.tableName,
        })
    }

    addPatient(patient) {
        return connection.insert({
            into: this.tableName,
            values: [patient],
            return: true
        })
    }

    getPatientById(id) {
        return connection.select({
            from: this.tableName,
            where: {
                id: id
            }
        })
    }

    getPatientByCard(card_no) {

        console.log(card_no)
        return connection.select({
            from: this.tableName,
            where: {
                card_no: card_no
            }
        })
    }

    removePatient(id) {
        return connection.remove({
            from: this.tableName,
            where: {
                id: id
            }
        })
    }
    clearTable(){
   return  connection.clear('patients')
   .then(response=>{
     console.log(response);

   })


    }

    updatePatientById(patient) {
        return connection.update({
            in: this.tableName,
            set: {
                first_name: patient.first_name,
                last_name: patient.last_name,
                email: patient.email,
                phone_no: patient.phone_no,
                dob: patient.dob,
                gender: patient.gender,
                genotype: patient.genotype,
            },
            where: {
                id: patient.id
            }
        })
    }
}
