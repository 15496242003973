<template>
  <div>
    <!-- <div class="content-body"> -->
    <!-- <div class="container-fluid"> -->

    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <!-- <div class="card-header">
                    <h6 class="text-success">Create roles for Staffs</h6>
                  </div> -->
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Admission Date </label>
                  <input
                    type="date"
                    placeholder="20/20/2022"
                    class="form-control"
                    v-model="form_data.admission_date"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label>Blood Pressure (mm/Hg) </label>
                  <input
                    type="text"
                    placeholder="mm/Hg"
                    class="form-control"
                    v-model="form_data.blood_pressure"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label>Height (M) </label>
                  <input
                    type="number"
                    step=".01"
                    placeholder="2.1M"
                    class="form-control"
                    v-model="form_data.height"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label>Weight (Kg) </label>
                  <input
                    type="number"
                    step=".01"
                    placeholder="65Kg"
                    class="form-control"
                    v-model="form_data.weight"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="inputPassword4"
                    >Select Ward
                  </label>

                  <select
                    class="form-control"
                    v-model="form_data.ward_id"
                 @change="getBeds"
                  >
                    <option
                      v-for="ward in wards.data"
                      :key="ward.id"
                      :value="ward.id"
                    >
                      {{ ward.name }}
                    </option>
                  </select>
                </div>

                <div class="form-group col-md-6">
                  <label for="inputPassword4">Select Bed </label>
                  <v-select
                    v-model="form_data.bed_id"
                    :reduce="(bed_code) => bed_code.id"
                    class="form-control"
                    label="bed_code"
                    :options="beds.data"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label> Symptoms</label>
                  <textarea
                    class="form-control"
                    placeholder="Type your message..."
                    rows="4"
                    v-model="form_data.symptoms"
                  ></textarea>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label> Notes</label>
                  <textarea
                    class="form-control"
                    placeholder="Type your message..."
                    rows="4"
                    v-model="form_data.notes"
                  ></textarea>
                </div>
              </div>

              <button class="btn btn-success btn-block" type="submit">
                Submit <i class="las la-paper-plane"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
    
    <script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      edit: false,
      File: "",
      patients: "",
      services: "",
      wards: "",
      beds: "",
      form_data: {
        patient_id: this.$route.params.id,
        facility_id: this.$selected_facility.id,
        staff_id: this.$user.id,
        height: "",
        weight: "",
        blood_pressure: "",
        admission_date: "",
        ward_id: "",
        bed_id: "",
        symptoms: "",
        notes: "",
        status: "active",
      },
      statuses: ["pending", "paid"],
      months: ["January", "February", "March"],
    };
  },
  beforeMount() {
  
  },
  computed: {},
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.makeInpatient();
      } else {
        this.editDept();
      }
    },
    makeInpatient() {
      this.isLoading = true;
      this.axios
        .post("/staff/hospitalizations", this.form_data)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toastr.s("In-patient Created Successfully", "Success!");
          this.form_data= {}
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");

          this.isLoading = false;
        });
    },

    getWards() {
      this.isLoading = true;
      this.axios
        .get(`/staff/wards`)
        .then((response) => {
          this.wards = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },

    getBeds() {
      this.isLoading = true;
      this.axios
        .get(`/staff/beds`, {
          params: {
            scope: `ward_id:${this.form_data.ward_id}`,
          },
        })
        .then((response) => {
          this.beds = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },

  
  },
  created() {
    this.getWards();
  },
};
</script>
    