<template>
  <div>
    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label>Bill Date</label>
                  <input
                    type="date"
                    placeholder="20/20/20"
                    class="form-control"
                    v-model="form_data.bill_date"
                  />
                </div>
                <!-- <div class="form-group col-md-6">
                  <label>Discount(%) </label>
                  <input
                    type="number"
                    placeholder="In Percentage"
                    class="form-control"
                    v-model="form_data.discount"
                  />
                </div> -->
                <!-- <div class="form-group col-md-6">
                  <label for="inputPassword4">Select Patient </label>
                  <v-select
                    v-model="form_data.patient_id"
                    :reduce="(first_name) => first_name.id"
                    class="form-control"
                    label="first_name"
                    :options="patients.data"
                  />
                </div> -->
              </div>

              <div class="form-row">
                <!-- <div class="form-group col-md-6">
                  <label for="inputPassword4">Status</label>
                  <v-select
                    v-model="form_data.status"
                    label="Select Status"
                    class="form-control"
                    :options="statuses"
                  />
                </div> -->
              </div>

              <div class="col-md-12 mb-3 mt-3">
                <span class="h4 align-left">Charge Items </span>
               
              </div>

              <div
                class="form-row"
                v-for="(service_renderred, index) in form_data.services"
                v-bind:key="service_renderred"
              >
                <div class="form-group col-md-6">
                  <label for="inputPassword4"
                    >{{ index + 1 }}. Service Item
                  </label>
                  <v-select
                    v-model="service_renderred.serviceId"
                    class="form-control"
                    label="name"
                    :options="services.data"
                  />
                </div>
                <div class="form-group col-md-2">
                  <label>Unit Price </label>
                  <input
                    type="number"
                    placeholder=""
                    class="form-control"
                    v-model="service_renderred.unit_price"
                  />
                  <p>{{ service_renderred.serviceId.amount }}  </p>
                </div>

                <div class="form-group col-md-2">
                  <label>Qty </label>
                  <input
                    type="number"
                    placeholder=""
                    class="form-control"
                    v-model="service_renderred.quantity"
                  />
                </div>

                <div
                  class="form-group col-md-2"
                  v-if="service_renderred.serviceId"
                >
                  <label>Amount </label>
                  <input
                    disabled
                    type="number"
                    placeholder="0.00"
                    class="form-control"
                    :value="
                      service_renderred.unit_price *
                      service_renderred.quantity
                    "
                  />
                </div>

                <div class="form-group col-md-2">

                  <a
                  class="btn btn-outline-success btn-sm mr-1"
                  @click="addArray()"
                  style="margin-top: 40px"
                >
                  <i class="las la-plus"></i>
                </a>


                  <a
                    @click="reduceArray(index)"
                    class="btn btn-outline-danger btn-sm"
                    style="margin-top: 40px"
                  >
                    <i class="las la-trash"></i>
                  </a>
                </div>
                <hr class="mt-2 mb-3" />
              </div>

              <button class="btn btn-success btn-block" type="submit">
                Submit <i class="las la-paper-plane"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
    
    <script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Loading,
  },
  props: ["patient"],
  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      edit: false,
      patients: "",
      services: "",
      form_data: {
        bill_date: "",
        patient_id: "",
        facility_id: this.$selected_facility.id,
        discount: "0.00",
        status: "completed",
        type: "offline",
        payment_method: "offline",
        services: [
          {
            serviceId: "",
            unit_price: "",
            quantity: "",
            amount: "",
          },
        ],
      },
      statuses: ["pending", "paid"],
    };
  },
  beforeMount() {},
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addBill();
      } else {
        this.editDept();
      }
    },
    addBill() {
      if (confirm("Are you Sure")) {
        this.isLoading = true;
        this.form_data.patient_id = this.patient.id;
        this.axios
          .post("/staff/bills", this.form_data)
          .then((response) => {
            console.log(response);
            this.isLoading = false;
            this.$toastr.s("Bill Created Successfully", "Success!");
            let bill_id = response.data.data.id;
            this.addBillItem(bill_id);
          })
          .catch((error) => {
            console.log(error.response);
            this.$toastr.e("Error on Adding!");

            this.isLoading = false;
          });
      }
    },
    addBillItem(bill_id) {
      let services = this.form_data.services;

      // service_renderred.serviceId.amount * service_renderred.quantity

      services.forEach((value, index) => {
        this.isLoading = true;
        this.axios
          .post("/staff/bill-items", {
            facility_id: this.$selected_facility.id,
            bill_id: bill_id,
            service_id: value.serviceId.id,
            quantity: value.quantity,
            amount: value.unit_price * value.quantity,
          })
          .then((response) => {
            console.log(response);
            console.log(index);
            this.isLoading = false;
            // this.$toastr.s("Bill Item Created Successfully", "Success!");
          })
          .catch((error) => {
            console.log(error.response);
            this.$toastr.e("Error on Adding!");

            this.isLoading = false;
          });
      });

      window.location.href = "/manage-bills";

    },
    updateDept() {
      //
    },

    getServices() {
      this.isLoading = true;
      this.axios
        .get(`/staff/services`,{
          params:{
            facility_id: this.$selected_facility.id
          }
        })
        .then((response) => {
          this.services = response.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },

    addArray() {
      this.form_data.services.push({
        serviceId: "",
        unit_price: "",
        quantity: "",
        amount: "0.00",
      });
    },

    reduceArray(index) {
      // this.form_data.services.pop();
      this.form_data.services.splice(index, 1);
    },
  },
  created() {
    // this.getPatients();
    this.getServices();
  },
};
</script>
    