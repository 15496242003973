<template>
  <!-- Chart Card -->
  <div class="card">
    <div class="card-body">
      <apexchart
        type="bar"
        height="300"
        :options="options"
        :series="series"
      ></apexchart>
    </div>
  </div>

  <!-- End of Chart -->
</template>
  
  <script>
export default {
  data() {
    return {
      series: [
        {
          name: "Product Quantity",
          data: [39, 8, 12, 40, 20, 67, 16, 9],
        },
      ],
      options: {
        chart: {
          id: "emr-blood",
        },
        xaxis: {
          categories: [
            "Paracetamol",
            "Drug 1",
            "Drug 4",
            "Product 3",
            "Product 8",
            "Product 6",
            "Product 14",
          ],
        },

        colors: ["#097969"],
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: 0,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        title: {
          text: "Best Selling Products (Last 30 Days)",
          floating: true,
          margin: 10,
          offsetY: 5,
          align: "center",
          style: {
            color: "#444",
          },
        },
      },
    };
  },
};
</script>