<template>
  <div>
    <div class="modal fade" id="uploadPhoto">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Upload Picture</h5>
            <button type="button" class="close" data-dismiss="modal">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <h5 class="mb-4">
                <i class="fa fa-paperclip"></i> Upload Patient Picture
              </h5>

              <div class="input-group mb-3">
                <div class="custom-file">
                  <input
                    type="file"
                    class="custom-file-input"
                    v-on:change="attachPic"
                    accept="image/png, image/jpeg"
                  />
                  <label class="custom-file-label">Choose file</label>
                </div>
              </div>
            </div>

            <div class="form-group mt-2">
              <button class="btn btn-success btn-block" data-dismiss="modal">
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["patient"],
  data() {
    return {
      image_file: "",
      image_captured: "",
    };
  },
  methods: {
    attachPic(event) {
      console.log(event);
      this.image_file = event.target.files[0];
      this.uploadPicture();
      this.$emit("update");
    },

    uploadPicture() {
      this.isLoading = true;

      var formData = new FormData();
      formData.append("image_file", this.image_file);
      formData.append("image_captured", this.image_captured);
      formData.append("patient_id", this.patient.id);
      formData.append("first_name", this.patient.first_name);
      formData.append("last_name", this.patient.last_name);

      this.axios
        .post(`/staff/patient-image`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);
          this.$toastr.s("Image Updated Successfully", "Success!");
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },

    // updateParent(){
    //     this.$emit("update");
    // }
  },
};
</script>