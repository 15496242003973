<template>
  <div>
    <Header />
    <div class="content-body">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="form-row float-right">
              <div class="form-group mr-2">
                <label for="">Patient Card No.</label>
                <input
                  type="text"
                  @change="getRecords"
                  v-model="card_no"
                  list="patients"
                  class="form-control"
                  placeholder="Card Number"
                />

            
              </div>
              <div class="form-group mr-2">
                <label for="">From</label>
                <input
                  type="date"
                  v-model="from"
                  class="form-control input-rounded"
                  placeholder=""
                />
              </div>

              <div class="form-group">
                <label for="">To</label>
                <input
                  type="date"
                  @change="getRecords"
                  v-model="to"
                  class="form-control input-rounded"
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="card">
              <div class="card-header pt-4 pb-4">
                <p class="card-title h5"> {{ records.total }} Patient Records</p>
                <download-excel
              class="btn btn-success btn-sm"
              :data="records.data"
              :fields="json_fields"
              type="xls"
              :escapeCsv="false"
              :name="'to CSV'"
            >
            </download-excel>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-responsive-md">
                    <thead>
                      <tr>
                        <th><strong>#</strong></th>
                        <th><strong>Patient</strong></th>
                        <th><strong>Card Number</strong></th>
                        <th><strong>Date</strong></th>

                        <th><strong>Activity</strong></th>
                        <th><strong>Action</strong></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(record, index) in records.data"
                        v-bind:key="record.id"
                      >
                        <td>
                          <strong>{{ index + 1 }} </strong>
                        </td>
                        <td>{{ record.patient.full_name }}</td>
                        <td>{{ record.patient.card_no }}</td>
                        <td>
                          {{
                            record.created_at
                              | moment("MMMM Do YYYY, h:mm:ss a")
                          }}
                        </td>

                        <td>
                          <a
                            href="javascript:void()"
                            class="btn btn-primary btn-sm px-3 light ml-2"
                          >
                            {{ record.action.description }}
                          </a>
                        </td>
                        <td>
                          <router-link :to="`/record-${record.id}`">

                        
                          <button
                          class="btn btn-sm btn-outline-success">
                          view

                          </button>
                        </router-link>
                          <!-- <a
                            :href="`/record-${record.id}`"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Close"
                            ><i class="fa fa-eye text-success"></i
                          ></a> -->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="col-md-10">
                  <ul class="pagination pagination-gutter">
                    <li class="page-item page-indicator">
                      <a class="page-link" href="javascript:void()">
                        <i class="la la-angle-left"></i
                      ></a>
                    </li>
                    <li
                      :class="
                        records.current_page == link
                          ? 'page-item active'
                          : 'page-item'
                      "
                      v-for="link in records.last_page"
                      :key="link"
                    >
                      <a
                        class="page-link"
                        href="javascript:void()"
                        @click="getRecords(link)"
                        >{{ link }}
                      </a>
                    </li>

                    <li class="page-item page-indicator">
                      <a class="page-link" href="javascript:void()">
                        <i class="la la-angle-right"></i
                      ></a>
                    </li>
                  </ul>
                </div>
             
              </div>
            </div>
          </div>
        </div>
        <div class="vld-parent">
          <loading
            :active.sync="isLoading"
            loader="spinner"
            :can-cancel="true"
            :is-full-page="fullPage"
          ></loading>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import Header from "@/views/Header.vue";
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// import { reloadPage } from "@/functions/Settings";

export default {
  components: {
    Header,
    Loading,
  },
  data() {
    return {
      fullPage: true,
      card_no: "",
      records: "",
      patients: "",
      from: "",
      to: "",
      json_fields: {
        Patient: "patient.full_name",
        "Patient Card No": "patient.card_no",
        "Activity Carried Out": "action.description",
        "Staff": "staff.full_name",
        "Consultation Note": "details",
        Date: "created_at",
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
    };
  },
  beforeCreate() {
    // reloadPage();
  },
  methods: {
   
    getRecords(page) {
      // this.isLoading = true;
      this.axios
        .get(`/staff/facility-records/${this.$selected_facility.id}`, {
          params: {
            card_no: this.card_no,
            from: this.from,
            to: this.to,
            page: page,
          },
        })
        .then((response) => {
          this.records = response.data;
          this.isLoading = false;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.getRecords();
  },
};
</script>
  