<template>
  <div>
    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Category Name</label>
                  <input
                    type="text"
                    placeholder="..."
                    class="form-control"
                    v-model="form_data.name"
                  />
                </div>
              </div>

              <button class="btn btn-success btn-block" type="submit">
                Submit <i class="las la-paper-plane"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
      
      <script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Loading,
  },
  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      edit: false,
      patients: "",
      staffs: "",
      form_data: {
        name: "",
        case_category: "",
      },
      statuses: ["Active", "Inactive"],
      ownerships: ["Owned", "Contractual"],
    };
  },
  beforeMount() {
  },
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addDept();
      } else {
        this.editDept();
      }
    },
    addDept() {
      this.isLoading = true;

      this.axios
        .post("/staff/case-categories", {
          department_id: this.$route.params.id,
          name: this.form_data.name,
          case_category: this.form_data.name,
        })
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toastr.s("Category Created Successfully", "Success!");
          this.clearForm()
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");

          this.isLoading = false;
        });
    },
    updateDept() {
      //
    },

    getPatients() {
      this.isLoading = true;
      this.selected_facility = JSON.parse(
        localStorage.getItem("selected_facility")
      );

      this.axios
        .get(`/patients`)
        .then((response) => {
          this.patients = response.data;
          this.isLoading = false;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    clearForm(){
      this.form_data.name= ""
      this.form_datacase_category= ""
    }
  },
  created() {
    this.getPatients();
  },
};
</script>
      