import { connection } from "./jsstore_con";

export class StaffService {
  constructor() {
    this.tableName = "staffs";
  }

  getStaffs() {
    return connection.select({
      from: this.tableName,
    });
  }

  addStaff(staff) {
    return connection.insert({
      into: this.tableName,
      values: [staff],
      return: true,
    });
  }

  getStaffId(id) {
    return connection.select({
      from: this.tableName,
      where: {
        id: id
      },
    });
  }

  removeRecord(id) {
    return connection.remove({
      from: this.tableName,
      where: {
        id: id,
      },
    });
  }
  clearTable() {
    return connection.clear("staffs").then((response) => {
      console.log(response);
    });
  }
}
