<template>
  <div>
    <Header />

    <div class="content-body">
      <div class="col-md-8 offset-md-2">
        <div class="card">
          <div class="settings-form">
            <div class="card-header">
              <h4 class="text-success">Staff Details</h4>
            </div>
            <div class="card-body">
              <form @submit.prevent="UpdateStaff()">
                <div class="form-row">

                  <div class="form-group col-md-6">
                    <label for="inputPassword4">Title </label>
                    <v-select
                      v-model="staff.initials"
                      class="form-control"
                      label=""
                      :options="titles"
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <label>First Name</label>
                    <input
                      v-model="staff.first_name"
                      type="text"
                      placeholder="First Name"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label>Last Name</label>
                    <input
                      v-model="staff.last_name"
                      type="text"
                      placeholder="Last Name"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label>Middle Name</label>
                    <input
                      v-model="staff.middle_name"
                      type="text"
                      placeholder="Last Name"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label>Email</label>
                    <input
                      v-model="staff.email"
                      type="email"
                      placeholder="Email"
                      class="form-control"
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <label>Phone Number</label>
                    <input
                      v-model="staff.phone_no"
                      type="text"
                      placeholder="0904493930"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label>Date of Birth</label>
                    <input
                      v-model="staff.dob"
                      type="date"
                      placeholder=""
                      class="form-control"
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <label>Job Title  </label>
                    <select class="form-control" v-model="staff.job_title_id">
                      <option
                        v-for="role in roles"
                        :key="role.id"
                        :value="role.id"
                      >
                        {{ role.name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="inputPassword4">Select Department </label>
                    <v-select
                      v-model="staff.department_id"
                      class="form-control"
                      label="name"
                      :reduce="(name) => name.id"
                      :options="departments"
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <label>Password</label>
                    <input
                    required
                      v-model="staff.password"
                      type="password"
                      placeholder="Password"
                      class="form-control"
                    />
                  </div>
                </div>

                <button class="btn btn-success btn-block" type="submit">
                  Update Staff
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
</template>

<script>
import Header from "@/views/Header.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Header,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      staff: "",
      roles: "",
      titles: ["Mr.", "Ms.", "Mrs.", "Dr.", "Pharm."],
    };
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    UpdateStaff() {
      this.isLoading = true;
      this.axios
        .put(`/staff/edit-staff/${this.$route.params.id}`, this.staff)
        .then((response) => {
          this.$toastr.s("Staff Updated Successfully", "Success!");
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.$toastr.e("Not Updated", "Error!");
        });
    },
    getStaff() {
      this.axios
        .get(`/staff/profile`,{
         params:{
          scope: `id:${this.$route.params.id}`,
         }
        })
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.staff = response.data.data.data[0];
        })
        .catch((error) => {
          console.log(error.response);
          this.isLoading = false;
        });
    },

    getRoles() {
      this.isLoading = false;

      this.axios
      .get(`/staff/all-job-titles/${this.$selected_facility.id}`)
        .then((response) => {
          this.roles = response.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    getDepts() {
      this.isLoading = false;
      this.axios
        .get(`/staff/departments`)
        .then((response) => {
          this.departments = response.data.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.$toastr.e("Error Querying Department", "Error!");
        });
    },
  },
  created() {
    this.getStaff();
    this.getRoles();
    this.getDepts();
  },
};
</script>
