<template>
  <div>
    <!-- <div class="content-body"> -->
    <!-- <div class="container-fluid"> -->

    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <!-- <div class="card-header">
                  <h6 class="text-success">Create roles for Staffs</h6>
                </div> -->
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Name of Vaccine </label>
                  <input
                    type="text"
                    placeholder="Small Pox, OPV etc"
                    class="form-control"
                    v-model="form_data.name"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Brand </label>
                  <input
                    type="text"
                    placeholder="Brand Name if applicable"
                    class="form-control"
                    v-model="form_data.brand"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Dose</label>
                  <input
                    type="number"
                    placeholder="5"
                    class="form-control"
                    v-model="form_data.dose"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label> Description</label>
                  <textarea
                    class="form-control"
                    placeholder="Type your message..."
                    rows="4"
                    v-model="form_data.description"
                  ></textarea>
                </div>
              </div>

              <button class="btn btn-success btn-block" type="submit">
                Submit <i class="las la-paper-plane"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
  
  <script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Loading,
  },
  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      edit: false,
      form_data: {
        id: "",
        name: "",
        brand: "",
        dose: "",
        description: "",
      },
    };
  },
  beforeMount() {},
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addVaccine();
      } else {
        this.updateVaccine();
      }
    },
    addVaccine() {
      this.isLoading = true;

      this.axios
        .post("/staff/vaccinations", this.form_data)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toastr.s("Vaccine Added Successfully", "Success!");
          this.clearForm();
          this.$emit("update");
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");

          this.isLoading = false;
        });
    },
    clearForm() {
      this.form_data.name = "";
      this.form_data.brand = "";
      this.form_data.dose = "";
      this.form_data.description = "";
    },
    updateVaccine() {
      this.isLoading = true;
      this.axios
        .put(`/staff/vaccinations/${this.form_data.id}`, this.form_data)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.edit = false;
          this.$toasted.success("Updated Successfully!", {
            position: "top-center",
            duration: 3000,
          });
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.$toastr.e("not successful", "Error!");
        });
    },

    setValue(value) {
      this.edit = true;
      this.form_data = value;
      console.log(value);
    },
  },
  created() {},
};
</script>
  