<template>
  <div>
    <Header />

    <div class="content-body">
      <div class="container-fluid">
        <div class="form-head d-flex mb-sm-4 mb-3">
          <div class="mr-auto">
            <h2 class="text-black font-w600">Receive Inventory Manager</h2>
            <p class="mb-0">Receive your new inventories here.</p>
          </div>

          <div class="dropdown ml-auto mr-2">
            <a
              href="#"
              class="btn btn-success light sharp"
              data-toggle="dropdown"
              aria-expanded="true"
            >
              <li class="las la-ellipsis-h scale5"></li>
            </a>
            <ul class="dropdown-menu dropdown-menu-right">
              <li class="dropdown-item">
                <span v-b-toggle.sidebar-right>
                  <a
                    href="javascript:void()"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Add"
                    ><i class="las la-plus color-muted text-success mr-2"></i>
                  </a>
                  Add New
                </span>
              </li>

              <li class="dropdown-item">
                <a
                  href="javascript:void()"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Export"
                  ><i
                    class="las la-file-export color-muted text-success mr-2"
                  ></i>
                </a>
                <span class="text-secondary"> Export to CSV </span>
              </li>
            </ul>
          </div>
        </div>

        <b-sidebar
          id="sidebar-right"
          title="Receive Inventory Form"
          :visible="showSidebar"
          right
          shadow
          width="500px"
        >
          <div class="px-3 py-2">
            <PlaceOrder ref="PlaceOrder" @update="onUpdate($event)" />
          </div>
        </b-sidebar>

        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Inventories</h4>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-responsive-md">
                    <thead>
                      <tr>
                        <th class="width80"><strong>#</strong></th>
                        <th><strong>Product</strong></th>
                        <th><strong>Supplier</strong></th>
                        <th><strong>Amount</strong></th>
                        <th><strong>Placed On</strong></th>
                        <th><strong>Action</strong></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(order, index) in orders.data" :key="order.id">
                        <td>
                          <strong> {{ index + 1 }} </strong>
                        </td>
                        <td>{{ order.product.name }}</td>
                        <td>{{ order.supplier.supplier_name }}</td>
                        <td>
                          <i class="mdi mdi-currency-ngn"></i>
                          {{ order.amount | numeral(0, 0) }}
                        </td>
                        <td>
                          {{ order.date | moment("dddd, MMMM Do YYYY") }}
                        </td>
                        <td>
                          <span>
                            <a
                              href="javascript:void()"
                              class="mr-4"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Edit"
                              v-b-toggle.sidebar-right
                              @click="startUpdate(order)"
                              ><i
                                class="fa fa-pencil color-muted text-success"
                              ></i>
                            </a>
                            <a
                              href="javascript:void()"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Close"
                              @click="deleteOrder(order)"
                              ><i
                                class="fa fa-trash color-danger text-success"
                              ></i
                            ></a>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="vld-parent">
          <loading
            :active.sync="isLoading"
            loader="spinner"
            :can-cancel="true"
            :is-full-page="fullPage"
          ></loading>
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
import Header from "@/views/Header.vue";
import PlaceOrder from "@/views/inventories/PlaceOrder.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin
import { reloadPage } from "@/functions/Settings";


export default {
  components: {
    Header,
    Loading,
    PlaceOrder,
  },
  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      showSidebar: false,
      edit: false,
      orders: "",
    };
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  beforeCreate() {
     reloadPage();
  },
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addDept();
      } else {
        this.editDept();
      }
    },

    startUpdate(order) {
      // this.showForm  = true
      this.$refs.PlaceOrder.setValue(order);
      console.log(order);
    },

    getOrders() {
      this.isLoading = true;

      this.axios
        .get(`/staff/orders`)
        .then((response) => {
          this.orders = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.$toastr.e("error on querying", "Error!");
        });
    },
    deleteOrder(order) {
      if (confirm("Are you Sure you want to delete?")) {
        this.isLoading = true;
        this.axios
          .delete(`/staff/orders/${order.id}`)
          .then((response) => {
            console.log(response);
            this.isLoading = false;
            this.getOrders();
            this.$toastr.s("Deleted Successfully", "Success!");
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
          });
      }
    },
    onUpdate() {
      this.getOrders();
      this.showSidebar = !this.showSidebar;
    },
  },
  created() {
    this.getOrders();
  },
};
</script>
    