<template>
    <div class="h-100" style="margin-top: 70px">
      <div class="text-center mb-3">
        <a href="/"
          ><img
            src="images/logos/SmartMedicare.png"
            class="img-fluid"
            alt=""
            width="400px"
        /></a>
      </div>
      <div class="authincation h-100">
        <div class="container h-100">
          <div class="row justify-content-center h-100 align-items-center">
            <div class="col-md-5 col-sm-6 col-xs-6">
              <div class="authincation-content">
                <div class="row no-gutters">
                  <div class="col-xl-12">
                    <div class="auth-form">
                      <!-- <div class="text-center mb-3">
                        <a href="/"
                          ><img src="images/logos/SmartMedicare.png" alt="" width="400px"
                        /></a>
                      </div> -->
                      <LoaderComponent v-show="isLoading" />
  
                      <h4 class="text-center mb-4 text-white">
                        Sign in your account
                      </h4>
                      <form @submit.prevent="loginUser">
                        <div class="form-group">
                          <label class="mb-1 text-white"
                            ><strong>Email</strong></label
                          >
                          <input
                            type="email"
                            class="form-control"
                            placeholder="hello@example.com"
                            v-model="auth.email"
                          />
                        </div>
                        <div class="form-group">
                          <label class="mb-1 text-white"
                            ><strong>Password</strong></label
                          >
                          <input
                            type="password"
                            class="form-control"
                            placeholder="Password"
                            v-model="auth.password"
                          />
                        </div>
                        <div
                          class="form-row d-flex justify-content-between mt-4 mb-2"
                        >
                          <div class="form-group">
                            <div
                              class="custom-control custom-checkbox ml-1 text-white"
                            >
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                id="basic_checkbox_1"
                              />
                              <label
                                class="custom-control-label"
                                for="basic_checkbox_1"
                                >Remember my preference</label
                              >
                            </div>
                          </div>
                          <div class="form-group">
                            <a class="text-white" href="page-forgot-password.html"
                              >Forgot Password?</a
                            >
                          </div>
                        </div>
                        <div class="text-center">
                          <button
                            type="submit"
                            class="btn bg-white text-success btn-block"
                          >
                            Sign Me In
                          </button>
                        </div>
                      </form>
                      <div class="new-account mt-3">
                        <p class="text-white">
                          Don't have an account?
                          <a class="text-white" href="#">Sign up</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <div class="footer">
              <!-- <div class="col-md-8 offset-md-2"> -->
              <div class="copyright">
                <p>
                  Copyright © Designed &amp; Developed by
                  <a href="https://uniccongroup.com/" target="_blank">Uniccon</a>
                  2022
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import LoaderComponent from "@/views/LoaderComponent.vue";
  
  export default {
    components: {
      LoaderComponent,
    },
    data() {
      return {
        auth: {
          password: "",
          email: "",
        },
        response: "",
        isLoading: false,
        auth_user: "",
        user: null,
      };
    },
    beforeMount() {
      this.user = JSON.parse(localStorage.getItem("user"));
    },
    methods: {

      loginUser() {
        this.isLoading = true;
        this.axios
          .post("/admin/auth/login", this.auth)
  
          .then((response) => {
            console.log(response);
            this.isLoading = false;
            let token = response.data.data.token;
            localStorage.setItem("user_admin", JSON.stringify(response.data));
            if (token) {
              localStorage.setItem("jwt", token);
              this.$router.push("/admin-dashboard");
  
           
            }
          })
          .catch((error) => {
            console.log(error.response);
            this.isLoading = false;
            this.$toasted.error("Username or Password Incorrect", {
              position: "top-center",
              duration: 3000,
            });
          });
      },
    },
    created() {},
  };
  </script>
  