<template>
  <div>
    <Header ref="Header" />

    <div class="content-body">
      <!-- row -->

      <div class="container-fluid">
        <div
          class="form-head page-titles d-flex align-items-center mb-sm-4 mb-3"
        >
          <div class="mr-auto">
            <h2 class="text-black font-w600">Patient Details</h2>
            <ol class="breadcrumb">
              <li class="breadcrumb-item active">
                <router-link to="/patients/all">Patients </router-link>
              </li>
              <li class="breadcrumb-item">
                <a href="javascript:void(0)">#P-{{ patient.card_no }}</a>
              </li>
            </ol>
          </div>
          <div class="d-flex">
            <button class="btn btn-success mx-2" v-b-toggle.sidebar-appointment>
              <i class="las la-calendar"></i> Book Appointment
            </button>
            <button class="btn btn-success mx-2" v-b-toggle.sidebar-right>
              <i class="lab la-accessible-icon"></i> New Visit
            </button>
            
            <button class="btn btn-success mx-2" v-b-toggle.sidebar-bill>
              <i class="las la-credit-card"></i> Bill Patient
            </button>

            <button class="btn btn-success mx-2" @click="refreshQuery">
              <i class="las la-sync-alt"></i>
            </button>
            <div class="dropdown ml-auto mr-2">
              <a
                href="javascript:void(0)"
                class="btn btn-outline-dark light sharp"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <!-- Action -->
                <!-- <i class="las la-angle-down ml-2 scale5"></i> -->
                <li class="las la-ellipsis-h scale5"></li>
              </a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" :href="`/add-record-${patient.id}`"
                  ><i class="las la-laptop-medical mr-2"></i> Add Record
                </a>

                <a
                  class="dropdown-item"
                  href="javascript:void()"
                  data-toggle="modal"
                  data-target="#uploadPhoto"
                  ><i class="las la-portrait mr-2"></i> Upload Picture
                </a>

                <a
                  class="dropdown-item"
                  href="javascript:void()"
                  v-b-toggle.sidebar-hospital
                  ><i class="las la-procedures mr-2"></i>Hospitalize Patient
                </a>

                <a
                  class="dropdown-item"
                  href="javascript:void()"
                  v-b-toggle.sidebar-lab
                  ><i class="las la-vials mr-2"></i> New Laboratory Test
                </a>

                <a
                  class="dropdown-item"
                  href="javascript:void()"
                  v-b-toggle.sidebar-condition
                  ><i class="las la-vials mr-2"></i> Add Health Condition
                  (Diagnosis)
                </a>

                <router-link
                  class="dropdown-item"
                  :to="`/edit-patient-${patient.id}`"
                  ><i class="la la-edit mr-2"></i> Edit Patient
                </router-link>

                <a
                  class="dropdown-item"
                  href="javascript:void()"
                  v-b-toggle.sidebar-kin
                  ><i class="la la-users-cog mr-2"></i>
                  Next of Kin
                </a>

                <a
                  class="dropdown-item"
                  href="javascript:void()"
                  v-b-toggle.sidebar-vitals
                  ><i class="las la-heartbeat mr-2"></i>
                  Add Vitals
                </a>

                <a
                  class="dropdown-item"
                  href="javascript:void()"
                  v-b-toggle.sidebar-surgery
                  ><i class="las la-user-nurse mr-2"></i>
                  Add Surgery
                </a>

                <a
                  class="dropdown-item"
                  href="javascript:void()"
                  v-b-toggle.sidebar-ivf
                  ><i class="las la-user-nurse mr-2"></i>
                  Add In vitro fertilization
                </a>
              </div>
            </div>
            <!-- <a
              href="edit-patient"
              class="btn btn-outline-success"
              data-toggle="modal"
              data-target="#addOrderModal"
              >Add Record <i class="la la-folder-open"></i>
            </a> -->
          </div>

          
        </div>

        <!-- Add Record -->
        <div class="modal fade" id="addOrderModal">
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Add Record</h5>
                <button type="button" class="close" data-dismiss="modal">
                  <span>&times;</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <b-sidebar
          id="sidebar-right"
          width="600px"
          title="Create New Visit"
          right
          shadow
        >
          <div class="px-3 py-2">
            <form @submit.prevent="addVisit">
              <div class="form-group">
                <label class="text-black font-w500"
                  >Select Purpose of Visit
                </label>
                <select class="form-control" v-model="Visit.action_id">
                  <option
                    v-for="activity in activities"
                    v-bind:key="activity.id"
                    :value="activity.id"
                  >
                    {{ activity.description }}
                  </option>
                </select>
              </div>

              <a
                class="btn btn-outline-success my-3"
                @click="showVitals = !showVitals"
                >Add Vitals <i class="las la-heartbeat"></i>
              </a>

              <div class="row" v-show="showVitals">
                <div class="form-group col-md-4">
                  <label>Blood Pressure (MM/HG) </label>
                  <input
                    type="text"
                    placeholder=""
                    class="form-control"
                    v-model="vital.blood_pressure"
                  />
                </div>

                <div class="form-group col-md-4">
                  <label>Weight (KG)</label>
                  <input
                    type="text"
                    placeholder=""
                    class="form-control"
                    v-model="vital.weight"
                  />
                </div>

                <div class="form-group col-md-4">
                  <label>Temperature (Celcius) </label>
                  <input
                    type="text"
                    placeholder=""
                    class="form-control"
                    v-model="vital.temperature"
                  />
                </div>

                <div class="form-group col-md-4">
                  <label>Height (FT) </label>
                  <input
                    type="text"
                    placeholder=""
                    class="form-control"
                    v-model="vital.height"
                  />
                </div>

                <div class="form-group col-md-4">
                  <label>Pulse (Bpm) </label>
                  <input
                    type="text"
                    placeholder=""
                    class="form-control"
                    v-model="vital.pulse"
                  />
                </div>

              </div>
              <div class="form-group">
                <button type="submit" class="btn btn-success btn-block">
                  Submit <i class="la la-save"></i>
                </button>
              </div>
            </form>
          </div>
        </b-sidebar>

        <b-sidebar
          id="sidebar-hospital"
          title="New In Patient (Hospitalization)"
          right
          shadow
          width="600px"
        >
          <div class="px-3 py-2">
            <AddHospitalization />
          </div>
        </b-sidebar>

        <b-sidebar
          id="sidebar-bill"
          title="Bill Patient"
          right
          shadow
          width="650px"
        >
          <div class="px-3 py-2">
            <AddBill :patient="patient" />
          </div>
        </b-sidebar>

        <b-sidebar
          id="sidebar-lab"
          title="New Laboratory Test"
          right
          shadow
          width="600px"
        >
          <div class="px-3 py-2">
            <AddLaboratoryTest :patient="patient" />
          </div>
        </b-sidebar>

        <b-sidebar
          id="sidebar-kin"
          title="Manage Next of Kin"
          right
          shadow
          width="600px"
        >
          <div class="px-3 py-2">
            <NextOfKin />
          </div>
        </b-sidebar>

        <b-sidebar
          id="sidebar-appointment"
          title="Make an Appointment"
          right
          shadow
          width="600px"
        >
          <div class="px-3 py-2">
            <MakeAppointment :patient="patient" />
          </div>
        </b-sidebar>

        <b-sidebar
          id="sidebar-condition"
          title="Health Condition Form"
          right
          shadow
          width="600px"
        >
          <div class="px-3 py-2">
            <AddHealthCondition :patient="patient" />
          </div>
        </b-sidebar>

        <b-sidebar
          id="sidebar-vitals"
          title="Vitals"
          right
          shadow
          width="600px"
        >
          <div class="px-3 py-2">
            <AddVitals :patient="patient" />
          </div>
        </b-sidebar>

        <b-sidebar
          id="sidebar-surgery"
          title="Surgery"
          right
          shadow
          width="600px"
        >
          <div class="px-3 py-2">
            <AddSurgery />
          </div>
        </b-sidebar>

        <b-sidebar
          id="sidebar-ivf"
          title="AddIVF"
          right
          shadow
          width="600px"
        >
          <div class="px-3 py-2">
            <AddIVF />
          </div>
        </b-sidebar>

        <UploadPicture :patient="patient" @update="getPatient($event)" />

        <div class="row">
          <div class="col-xl-9 col-xl-9">
            <div class="row">
              <div class="col-xl-12">
                <div class="card details-card">
                  <img
                    src="/images/cover.png"
                    alt=""
                    width="1160px"
                    height="400px"
                    class="bg-img"
                  />
                  <div class="card-body">
                    <div class="d-sm-flex mb-3">
                      <div class="img-card mb-sm-0 mb-3">
                        <img
                        id="myImg"
                        @click="zoomImage"
                          :src="`${image_url}${patient.image}`"
                          alt=""
                          v-if="patient.image != null"
                        />
                        <img src="/unknown_user.png" alt="" v-else />
                        <div
                          class="info d-flex align-items-center p-md-3 p-2 bg-success"
                        >
                          <div>
                            <p class="fs-14 text-white op5 mb-1">
                              {{ patient.dob | moment("from", "now", true) }}
                              old
                            </p>
                            <span class="fs-18 text-white">
                              {{ patient.gender }}
                            </span>
                          </div>
                        </div>
                      </div>

                      <!-- Image Modal -->
                      <div id="myModal" class="modal">
   <img class="modal-content" id="img01">
<div id="caption"></div>
</div>
                      <!-- End of Image Modal -->
                      <div class="card-info d-flex align-items-start">
                        <div class="mr-auto pr-3">
                          <h2 class="font-w600 mb-2 text-black">
                            {{ patient.full_name }}
                          </h2>
                          <p class="mb-2">
                            <i class="las la-address-card"></i>
                            {{ patient.card_no }}
                          </p>
                          <span class="date">
                            <i class="las la-clock"></i>
                            {{
                              patient.created_at | moment("from", "now")
                            }}</span
                          >
                        </div>
                        <!-- <span class="mr-ico bg-success">
                          <i class="las la-mars"></i>
                        </span> -->
                      </div>
                    </div>
                    <!-- <h4 class="fs-20 text-black font-w600">
                      Patient Summary
                    </h4>
                    <p>
                     No summary submitted yet.
                    </p> -->
                  </div>
                </div>
              </div>

              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="card">
                      <div class="card-body">
                        <div class="media align-items-center">
                          <i class="las la-phone fs-30 text-success mr-3"></i>
                          <div class="media-body">
                            <span class="d-block mb-1">Phone</span>
                            <p class="fs-18 mb-0 text-black">
                              {{ patient.phone_no }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="card">
                      <div class="card-body">
                        <div class="media align-items-center">
                          <i
                            class="las la-campground fs-30 text-success mr-3"
                          ></i>
                          <div class="media-body">
                            <span class="d-block mb-1">Cateogory</span>
                            <p class="fs-18 mb-0 text-black">
                              {{ patient.reg_category }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="card">
                      <div class="card-body">
                        <div class="media align-items-center">
                          <i class="las la-vial fs-30 text-success mr-3"></i>

                          <div class="media-body">
                            <span class="d-block mb-1">Blood Group</span>
                            <p class="fs-18 mb-0 text-black">
                              {{ patient.blood_group }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <router-view></router-view>

              <div class="col-lg-12">
                <div class="card"></div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-xl-3">
            <div class="row">
              <div class="col-xl-12 col-xxl-12 col-lg-12">
                <div class="card" v-show="false">
                  <div class="card-header border-0 pb-0">
                    <h4 class="fs-20 text-black mb-0">Assigned Doctor</h4>
                    <div class="dropdown">
                      <div
                        class="btn-link"
                        role="button"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11Z"
                            stroke="#2E2E2E"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18Z"
                            stroke="#2E2E2E"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4Z"
                            stroke="#2E2E2E"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" href="#">Change Doctor</a>
                        <a class="dropdown-item" href="#">Make Appointment</a>
                        <a class="dropdown-item" href="#">View Doctor</a>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="media mb-4 align-items-center">
                      <img
                        src="/images/users/12.jpg"
                        alt=""
                        width="85"
                        class="mr-3"
                      />
                      <div class="media-body">
                        <h3 class="fs-18 font-w600 mb-1">
                          <a href="javascript:void(0)" class="text-black"
                            >Dr. Okonkwo Ikemefuna</a
                          >
                        </h3>
                        <span class="fs-14">Dentist</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <a
                          href="javascript:void(0)"
                          class="btn btn-outline-dark mb-2 btn-sm d-block"
                          >Unassign</a
                        >
                      </div>
                      <div class="col-6">
                        <a
                          href="javascript:void(0)"
                          class="btn btn-success light mb-2 btn-sm d-block"
                          >Check</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <!-- add vitals -->

              <div class="col-xl-12 col-xl-12 col-lg-12">
                <div class="card">
                  <div class="card-header border-0 pb-0">
                    <div>
                      <h4 class="fs-20 text-black mb-1">Vitals: </h4>
                    </div>
                  </div>


                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="mt-4 col-xl-12 col-xxl-12 col-sm-6">

                        <div class="d-flex align-items-center mb-3">
                          <span class="fs-12 col-10 p-0 text-black">
                            <router-link
                              :to="`/patient-${patient.id}`"
                              class="text-dark"
                            >
                            Blood Pressure
                            </router-link>
                          </span>
                          <div class="">
                            <a
                              href="javascript:void()"
                              class="badge badge-circle badge-outline-primary text-black"
                            >
                            {{ patient.latest_vitals.blood_pressure }}
                            </a>
                          </div>
                        </div>

                        <div class="d-flex align-items-center mb-3">
                          <span class="fs-12 col-10 p-0 text-black">
                            <router-link
                              :to="`/patient-${patient.id}`"
                              class="text-dark"
                            >
                            Weight (KG)
                            </router-link>
                          </span>
                          <div class="">
                            <a
                              href="javascript:void()"
                              class="badge badge-circle badge-outline-primary text-black"
                            >
                            {{ patient.latest_vitals.weight }}
                            </a>
                          </div>
                        </div>

                        <div class="d-flex align-items-center mb-3">
                          <span class="fs-12 col-10 p-0 text-black">
                            <router-link
                              :to="`/patient-${patient.id}/labs`"
                              class="text-dark"
                            >
                            Temperature (Celcius)
                            </router-link>
                          </span>
                          <div class="">
                            <a
                              href="javascript:void()"
                              class="badge badge-circle badge-outline-primary text-black"
                            >
                            {{ patient.latest_vitals.temperature }}
                            </a>
                          </div>
                        </div>

                        <div class="d-flex align-items-center mb-3">
                          <span class="fs-12 col-10 p-0 text-black">
                            <router-link
                              :to="`/patient-${patient.id}/appointments`"
                              class="text-dark"
                            >
                            Height (FT)
                            </router-link>
                          </span>
                          <div class="">
                            <a
                              href="javascript:void()"
                              class="badge badge-circle badge-outline-primary text-black"
                            >
                              {{ metrics.appointments_count }}
                            </a>
                          </div>
                        </div>

                        <div class="d-flex align-items-center mb-3">
                          <span class="fs-12 col-10 p-0 text-black">
                            <router-link
                              :to="`/patient-${patient.id}/hospitalizations`"
                              class="text-dark"
                            >
                            Pulse (Bpm)
                            </router-link>
                          </span>
                          <div class="">
                            <a
                              href="javascript:void()"
                              class="badge badge-circle badge-outline-primary text-black"
                            >
                              {{ metrics.hospitalizations_count }}
                            </a>
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              <!-- end vitals -->


              <div class="col-xl-12 col-xl-12 col-lg-12">
                <div class="card">
                  <div class="card-header border-0 pb-0">
                    <div>
                      <h4 class="fs-20 text-black mb-1">Patient EMR</h4>
                      <span class="fs-12"
                        >Comprehensive Patient Medical Data</span
                      >
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row align-items-center">
                      <!-- <div class="col-xl-12 col-xxl-6 col-sm-6">
                        <div id="pieChart"></div>
                      </div> -->
                      <div class="mt-4 col-xl-12 col-xxl-12 col-sm-6">
                        <div class="d-flex align-items-center mb-3">
                          <span class="fs-12 col-10 p-0 text-black">
                            <i class="las la-folder-open la-2x mr-2"></i>
                            <router-link
                              :to="`/patient-${patient.id}`"
                              class="text-dark"
                            >
                              Health Records
                            </router-link>
                          </span>
                          <div class="">
                            <a
                              href="javascript:void()"
                              class="badge badge-circle badge-outline-primary text-black"
                            >
                              {{ metrics.records_count }}
                            </a>
                          </div>
                        </div>

                        <div class="d-flex align-items-center mb-3">
                          <span class="fs-12 col-10 p-0 text-black">
                            <i class="las la-vials la-2x mr-2"></i>
                            <router-link
                              :to="`/patient-${patient.id}/labs`"
                              class="text-dark"
                            >
                              Lab Tests
                            </router-link>
                          </span>
                          <div class="">
                            <a
                              href="javascript:void()"
                              class="badge badge-circle badge-outline-primary text-black"
                            >
                              {{ metrics.labtests_count }}
                            </a>
                          </div>
                        </div>

                        <div class="d-flex align-items-center mb-3">
                          <span class="fs-12 col-10 p-0 text-black">
                            <i class="las la-calendar la-2x mr-2"></i>
                            <router-link
                              :to="`/patient-${patient.id}/appointments`"
                              class="text-dark"
                            >
                              Appointments
                            </router-link>
                          </span>
                          <div class="">
                            <a
                              href="javascript:void()"
                              class="badge badge-circle badge-outline-primary text-black"
                            >
                              {{ metrics.appointments_count }}
                            </a>
                          </div>
                        </div>

                        <div class="d-flex align-items-center mb-3">
                          <span class="fs-12 col-10 p-0 text-black">
                            <i class="las la-procedures la-2x mr-2"></i>
                            <router-link
                              :to="`/patient-${patient.id}/hospitalizations`"
                              class="text-dark"
                            >
                              Hospitalizations
                            </router-link>
                          </span>
                          <div class="">
                            <a
                              href="javascript:void()"
                              class="badge badge-circle badge-outline-primary text-black"
                            >
                              {{ metrics.hospitalizations_count }}
                            </a>
                          </div>
                        </div>

                        <div class="d-flex align-items-center mb-3">
                          <span class="fs-12 col-10 p-0 text-black">
                            <i class="las la-stethoscope la-2x mr-2"></i>
                            <router-link
                              :to="`/patient-${patient.id}/health-conditions`"
                              class="text-dark"
                            >
                              Health Conditions
                            </router-link>
                          </span>
                          <div class="">
                            <a
                              href="javascript:void()"
                              class="badge badge-circle badge-outline-primary text-black"
                            >
                              {{ metrics.conditions_count }}
                            </a>
                          </div>
                        </div>

                        <div class="d-flex align-items-center mb-3">
                          <span class="fs-12 col-10 p-0 text-black">
                            <i class="las la-medkit la-2x mr-2"></i>

                            Allergies
                          </span>
                          <div class="">
                            <a
                              href="javascript:void()"
                              class="badge badge-circle badge-outline-primary text-black"
                            >
                              {{ metrics.allergies_count }}
                            </a>
                          </div>
                        </div>

                        <div class="d-flex align-items-center mb-3">
                          <span class="fs-12 col-10 p-0 text-black">
                            <i class="las la-user-md la-2x mr-2"></i>

                            <router-link
                              :to="`/patient-${patient.id}/surgeries`"
                            >
                              <span class="text-dark"> Surgeries </span>
                            </router-link>
                          </span>
                          <div class="">
                            <a
                              href="javascript:void()"
                              class="badge badge-circle badge-outline-primary text-black"
                            >
                              {{ metrics.surgeries_count }}
                            </a>
                          </div>
                        </div>

                        <div class="d-flex align-items-center mb-3">
                          <span class="fs-12 col-10 p-0 text-black">
                            <i class="las la-heartbeat la-2x mr-2"></i>

                            <router-link :to="`/patient-${patient.id}/vitals`">
                              <span class="text-dark"> Vitals </span>
                            </router-link>
                          </span>
                          <div class="">
                            <a
                              href="javascript:void()"
                              class="badge badge-circle badge-outline-primary text-black"
                            >
                              {{ metrics.vitals_count }}
                            </a>
                          </div>
                        </div>

                        <div class="d-flex align-items-center mb-3">
                          <span class="fs-12 col-10 p-0 text-black">
                            <i class="la la-users-cog la-2x mr-2"></i>
                            <router-link
                              :to="`/patient-${patient.id}/nextofkins`"
                            >
                              <span class="text-dark">Next of Kins </span>
                            </router-link>
                          </span>
                          <div class="">
                            <a
                              href="javascript:void()"
                              class="badge badge-circle badge-outline-primary text-black"
                            >
                              {{ metrics.nextofkins_count }}
                            </a>
                          </div>
                        </div>

                        <div class="d-flex align-items-center mb-3">
                          <span class="fs-12 col-10 p-0 text-black">
                            <i class="las la-share-alt la-2x mr-2"></i>

                            Social History
                          </span>
                          <div class="">
                            <a
                              href="javascript:void()"
                              class="badge badge-circle badge-outline-primary text-black"
                            >
                              0</a
                            >
                          </div>
                        </div>

                        <div class="d-flex align-items-center mb-3">
                          <span class="fs-12 col-10 p-0 text-black">
                            <i class="las la-user-friends la-2x mr-2"></i>
                            <router-link
                              :to="`/patient-${patient.id}/medical-histories`"
                            >
                              <span class="text-dark">
                                Family Medical History
                              </span>
                            </router-link>
                          </span>
                          <div class="">
                            <a
                              href="javascript:void()"
                              class="badge badge-circle badge-outline-primary text-black"
                            >
                              0</a
                            >
                          </div>
                        </div>

                        <div class="d-flex align-items-center mb-3">
                          <span class="fs-12 col-10 p-0 text-black">
                            <i class="las la-syringe la-2x mr-2"></i>
                            <router-link :to="`/vaccinate-patients`">
                              <span class="text-dark"> Vaccinations </span>
                            </router-link>
                          </span>
                          <div class="">
                            <a
                              href="javascript:void()"
                              class="badge badge-circle badge-outline-primary text-black"
                            >
                              0</a
                            >
                          </div>
                        </div>

                        <div class="d-flex align-items-center mb-3">
                          <span class="fs-12 col-10 p-0 text-black">
                            <i class="las la-diagnoses la-2x mr-2"></i>
                            <router-link
                              :to="`/patient-${patient.id}/contraindications`"
                            >
                              <span class="text-dark"> Contraindications </span>
                            </router-link>
                          </span>
                          <div class="">
                            <a
                              href="javascript:void()"
                              class="badge badge-circle badge-outline-primary text-black"
                            >
                              0</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-12 col-xl-12 col-lg-12">
                <div class="card" v-show="false">
                  <div class="card-header border-0 pb-0">
                    <div>
                      <h4 class="fs-20 text-black mb-1">Patient Statistic</h4>
                      <span class="fs-12">Realtime patient monitoring</span>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row align-items-center">
                      <!-- <div class="col-xl-12 col-xxl-6 col-sm-6">
                        <div id="pieChart"></div>
                      </div> -->
                      <div class="mt-4 col-xl-12 col-xxl-12 col-sm-6">
                        <div class="d-flex mb-3 align-items-center">
                          <span class="fs-12 col-6 p-0 text-black">
                            <svg
                              class="mr-2"
                              width="19"
                              height="19"
                              viewBox="0 0 19 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect width="19" height="19" fill="#5F74BF" />
                            </svg>
                            Heart rate Monitor
                          </span>
                          <div class="progress rounded-0 col-6 p-0">
                            <div
                              class="progress-bar rounded-0 progress-animated"
                              style="
                                width: 80%;
                                height: 6px;
                                background: #5f74bf;
                              "
                              role="progressbar"
                            >
                              <span class="sr-only">60% Complete</span>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex mb-3 align-items-center">
                          <span class="fs-12 col-6 p-0 text-black">
                            <svg
                              class="mr-2"
                              width="19"
                              height="19"
                              viewBox="0 0 19 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect width="19" height="19" fill="#FFD439" />
                            </svg>
                            Oxymeter
                          </span>
                          <div class="progress rounded-0 col-6 p-0">
                            <div
                              class="progress-bar rounded-0 progress-animated"
                              style="
                                width: 40%;
                                height: 6px;
                                background: #ffd439;
                              "
                              role="progressbar"
                            >
                              <span class="sr-only">60% Complete</span>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex mb-3 align-items-center">
                          <span class="fs-12 col-6 p-0 text-black">
                            <svg
                              class="mr-2"
                              width="19"
                              height="19"
                              viewBox="0 0 19 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect width="19" height="19" fill="#FF6E5A" />
                            </svg>
                            Heart Beat
                          </span>
                          <div class="progress rounded-0 col-6 p-0">
                            <div
                              class="progress-bar rounded-0 progress-animated"
                              style="
                                width: 90%;
                                height: 6px;
                                background: #ff6e5a;
                              "
                              role="progressbar"
                            >
                              <span class="sr-only">60% Complete</span>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex align-items-center">
                          <span class="fs-12 col-6 p-0 text-black">
                            <svg
                              class="mr-2"
                              width="19"
                              height="19"
                              viewBox="0 0 19 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect width="19" height="19" fill="#5FBF91" />
                            </svg>
                            Colestrol
                          </span>
                          <div class="progress rounded-0 col-6 p-0">
                            <div
                              class="progress-bar rounded-0 progress-animated"
                              style="
                                width: 80%;
                                height: 6px;
                                background: #5fbf91;
                              "
                              role="progressbar"
                            >
                              <span class="sr-only">60% Complete</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
</template>

<script>
// const my_url  =

// import { reloadPage } from "@/functions/Settings";
import Header from "@/views/Header.vue";
import AddBill from "@/views/finances/AddBill.vue";
import AddHospitalization from "@/views/records/AddHospitalization.vue";
import AddLaboratoryTest from "@/views/patients/AddLaboratoryTest.vue";
import AddSurgery from "@/views/patients/components/AddSurgery.vue";
import AddIVF from "@/views/patients/components/AddIVF.vue";
import NextOfKin from "@/views/patients/NextOfKin.vue";
import MakeAppointment from "@/views/patients/MakeAppointment.vue";
import AddHealthCondition from "@/views/patients/components/AddHealthCondition.vue";
import UploadPicture from "@/views/patients/components/UploadPicture.vue";
import AddVitals from "@/views/patients/components/AddVitals.vue";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

import { PatientService } from "@/jsstore/patients";

export default {
  components: {
    Header,
    Loading,
    AddBill,
    AddHospitalization,
    AddLaboratoryTest,
    AddSurgery,
    AddIVF,
    NextOfKin,
    MakeAppointment,
    AddHealthCondition,
    UploadPicture,
    AddVitals,
  },
  data() {
    return {
      jsstore: new PatientService(),
      image_url: "https://apis.smartmedicare.smartfocuss.com/patients/",
      // image_url:
      //   this.$app_url == null
      //     ? "https://apis.smartmedicare.smartfocuss.com/api"
      //     : this.$app_url.substring(0, this.$app_url.length - 3),
      user: null,
      isLoading: false,
      fullPage: true,
      showVitals: false,
      metrics: "",
      activities: "",
      patient: "",
      records: "",
      files: "",
      vital: {
        blood_pressure: "",
        weight: "",
        temperature: "",
        height: "",
        pulse: "",
      },
      record: {
        PatientId: "",
        Title: "",
        Details: "",
        Brief: "",
        Lab: "",
        Action: "",
      },
      Visit: {
        Purpose: "",
      },
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        dictDefaultMessage: "<i class='fa fa-cloud-upload'></i>UPLOAD FILES",
        headers: { "My-Awesome-Header": "header value" },
      },
    };
  },
  beforeCreate() {
    // reloadPage();
  },
  beforeMount() {
    // this.user = JSON.parse(localStorage.getItem("user"));
  },
  mounted(){
   
  },
  methods: {
    toggler() {
      this.$refs.Header.TestChild();
    },
    getPatient() {
      if (this.$store.state.mode == true) {
        this.getPatientOnline();
      } else {
        this.getPatientOffline();
      }
    },
    getPatientOnline() {
      this.axios
        .get(`/staff/patients`, {
          params: {
            scope: `id:${this.$route.params.id}`,
          },
        })
        .then((response) => {
          this.patient = response.data.data.data[0];
          console.log(response);
          this.assignPatient(this.patient);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async getPatientOffline() {
      let query = await this.jsstore.getPatientByCard(
        this.$route.params.card_no
      );
      this.patient = query[0];
      console.log(this.patient + "jsstore");
    },
    sendingEvent(file, xhr, formData) {
      formData.append("paramName", "some value or other");
      this.files = file;
    },

    getPatientMetrics() {
      this.axios
        .get(`/staff/patient-metrics/${this.$route.params.id}`)
        .then((response) => {
          this.metrics = response.data;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getActivities() {
      this.axios
        .get(`/staff/all-actions/${this.$selected_facility.id}`)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.activities = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    addVisit() {
      this.isLoading = true;
      this.axios
        .post(`/staff/visits`, {
          facility_id: this.$selected_facility.id,
          patient_id: this.patient.id,
          purpose: "see action",
          action_id: this.Visit.action_id,
        })
        .then((response) => {
          console.log(response);
          this.$toastr.s("Visit Created Successfully", "Success!");
          this.addVitals();
          this.Visit.action_id = "";
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },

    addVitals() {
      this.axios
        .post(`/staff/vitals`, {
          patient_id: this.patient.id,
          staff_id: this.$user.id,
          blood_pressure: this.vital.blood_pressure,
          weight: this.vital.weight,
          temperature: this.vital.temperature,
          height: this.vital.height,
          pulse: this.vital.pulse,
        })
        .then((response) => {
          console.log(response);

          this.showVitals = false;
          this.vital.blood_pressure = "";
          this.vital.weight = "";
          this.vital.temperature = "";
          this.vital.height = "";
          this.vital.pulse = "";
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    assignPatient(patient) {
      this.axios
        .post(`/staff/assign/patient`, {
          card_no: patient.card_no,
          patient_id: this.$route.params.id,
        })
        .then((response) => {
          console.log(response);
          this.updatePatientDetails(patient);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    updatePatientDetails(patient) {
      this.axios
        .get(`/staff/patients`, {
          params: {
            scope: `card_no:${patient.card_no}`,
          },
        })
        .then((response) => {
          this.patient = response.data.data.data[0];
          console.log(JSON.stringify(response) + "tillWS");
        })
        .catch((error) => {
          console.error(error);
        });
    },
    refreshQuery() {
      this.getPatient();
      this.getActivities();
      this.getPatientMetrics();
    },

    zoomImage(){
      var modal = document.getElementById('myModal');

// Get the image and insert it inside the modal - use its "alt" text as a caption
var img = document.getElementById('myImg');
var modalImg = document.getElementById("img01");
var captionText = document.getElementById("caption");
img.onclick = function(){
    modal.style.display = "block";
    modalImg.src = this.src;
    modalImg.alt = this.alt;
    captionText.innerHTML = this.alt;
}


modal.onclick = function() {
  document.getElementById("img01").className += " out";
    setTimeout(function() {
       modal.style.display = "none";
       document.getElementById("img01").className = "modal-content";
     }, 400);
    
 }    
    }
  },
  created() {
    this.getPatient();
    this.getActivities();
    this.getPatientMetrics();
  },
};
</script>

<style scoped>
#myImg {
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    display: block;
    margin-left: auto;
    margin-right: auto
}
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 99; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}

.modal-content {
    margin: auto;
    display: block;
    width: 75%;
    /* //max-width: 75%; */
}
#caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px;
}
@-webkit-keyframes zoom {
    from {-webkit-transform:scale(1)}
    to {-webkit-transform:scale(2)}
}

@keyframes zoom {
    from {transform:scale(0.4)}
    to {transform:scale(1)}
}
@-webkit-keyframes zoom-out {
    from {transform:scale(1)}
    to {transform:scale(0)}
}
@keyframes zoom-out {
    from {transform:scale(1)}
    to {transform:scale(0)}
}
.modal-content, #caption {
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
}
.out {
  animation-name: zoom-out;
  animation-duration: 0.6s;
}
@media only screen and (max-width: 700px){
    .modal-content {
        width: 100%;
    }
}
</style>
