<template>
  <div>
    <Header />
    <div class="content-body">
      <!-- row -->
      <div class="container-fluid">
        <div
          class="form-head d-flex page-titles mb-sm-4 mb-3 align-items-center"
        >
          <div class="mr-auto">
            <h2 class="text-black font-w600">Staff Page</h2>
            <ol class="breadcrumb">
              <li class="breadcrumb-item active">
                <a href="javascript:void(0)">
                  {{ staff.initials }} {{ staff.first_name }}
                  {{ staff.last_name }}
                </a>
              </li>
              <!-- <li class="breadcrumb-item">
                <a href="javascript:void(0)">{{ staff.first_name }} </a>
              </li> -->
            </ol>
          </div>
          <a href="#" class="btn btn-outline-success mr-3"
            ><i class="las la-plus"></i> Assign Patient</a
          >
          <a href="#" class="btn btn-outline-danger"
          @click="deleteStaff"
            ><i class="las la-user-times"></i> Delete Staff</a
          >
        </div>
        <div class="row">
          <div class="col-xl-9 col-xxl-12">
            <div class="row">
              <div class="col-xl-12">
                <div class="card doctor-details-card">
                  <div class="bg-img-bx">
                    <img
                      src="/images/cover.png"
                      alt=""
                      class="bg-img"
                      height="400px"
                    />
                    <a href="doctor.html" class="btn btn-success"
                      ><i class="las la-stethoscope mr-3 scale5"></i
                      >{{ staff.job_title.name }}
                    </a>
                  </div>
                  <div class="card-body">
                    <div class="d-sm-flex d-block mb-3">
                      <div class="img-card mb-sm-0 mb-3">
                        <img src="/unknown_user.png" alt="" />
                      </div>
                      <div class="card-info d-flex align-items-start">
                        <div class="mr-auto pr-3">
                          <h2 class="font-w600 mb-sm-2 mb-1 text-black">
                            {{ staff.initials }}
                            {{ staff.first_name }} {{ staff.last_name }}
                          </h2>
                          <p class="mb-sm-2 mb-1">
                            #Staff ID - {{ staff.staff_no }}
                          </p>
                          <span class="date">
                            <i class="las la-clock"></i>
                            {{ staff.created_at | moment("from", "now") }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <!-- <h4 class="fs-20 text-black font-w600">Biography</h4> -->
                  </div>
                </div>
              </div>
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-header border-0 pb-0">
                    <div>
                      <h4 class="fs-20 text-black mb-1">Staff Profile</h4>
                      <span class="fs-12">Staff information</span>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col-lg-5 mb-lg-0 mb-3">
                        <div class="d-flex mb-3 align-items-center">
                          <span class="fs-12 col-6 p-0 text-black">
                            <i class="las la-envelope la-2x mr-2"></i>
                          </span>

                          <div class="col-6">
                            {{ staff.email }}
                          </div>
                        </div>

                        <div class="d-flex mb-3 align-items-center">
                          <span class="fs-12 col-6 p-0 text-black">
                            <i class="las la-phone la-2x mr-2"></i>
                          </span>

                          <div class="col-6">
                            {{ staff.phone_no }}
                          </div>
                        </div>

                        <div class="d-flex mb-3 align-items-center">
                          <span class="fs-12 col-6 p-0 text-black">
                            <i class="las la-address-card la-2x mr-2"></i>
                          </span>

                          <div class="col-6">
                            {{ staff.staff_no }}
                          </div>
                        </div>

                        <div class="d-flex mb-3 align-items-center">
                          <span class="fs-12 col-6 p-0 text-black">
                            <i class="las la-folder la-2x mr-2"></i>
                          </span>

                          <div class="col-6">Department</div>
                        </div>
                      </div>
                      <div class="col-lg-7">
                        <div class="row align-items-center">
                          <div class="col-lg-6 col-sm-6 mb-sm-0 mb-3">
                            <div id="pieChart"></div>
                          </div>
                          <div class="col-lg-6 col-sm-6">
                            <div id="pieChart2"></div>
                            <div class="d-flex align-items-center">
                              <svg
                                class="mr-3"
                                width="25"
                                height="26"
                                viewBox="0 0 25 26"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  width="3.54545"
                                  height="26"
                                  rx="1.77273"
                                  transform="matrix(-1 0 0 1 24.8184 0)"
                                  fill="#757575"
                                />
                                <rect
                                  width="3.54545"
                                  height="18.9091"
                                  rx="1.77273"
                                  transform="matrix(-1 0 0 1 17.7275 7.09091)"
                                  fill="#757575"
                                />
                                <rect
                                  width="3.54545"
                                  height="8.27273"
                                  rx="1.77273"
                                  transform="matrix(-1 0 0 1 10.6367 17.7273)"
                                  fill="#757575"
                                />
                                <rect
                                  width="4"
                                  height="16"
                                  rx="2"
                                  transform="matrix(-1 0 0 1 4 10)"
                                  fill="#757575"
                                />
                              </svg>
                              <div>
                                <p class="fs-12 mb-1">Total Patients</p>
                                <span class="fs-22 text-black font-w600"
                                  >0</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-xxl-12">
            <div class="row" v-show="false">
              <div class="col-xl-12 col-xxl-8">
                <div class="card appointment-schedule">
                  <div class="card-header border-0 pb-0">
                    <h4 class="fs-20 text-black mb-1">Appointment</h4>
                  </div>
                  <div class="card-body pt-2">
                    <div class="row">
                      <div
                        class="appointment-calender col-xl-12 col-xxl-6 col-md-6 col-sm-7 mb-md-4 mb-0"
                      >
                        <input
                          type="text"
                          class="form-control d-none"
                          id="datetimepicker1"
                        />
                      </div>
                      <div
                        class="height380 col-xl-12 col-xxl-6 col-md-6 col-sm-5 dz-scroll"
                        id="appointmentSchedule"
                      >
                        <div
                          class="d-flex pb-3 border-bottom mb-3 align-items-end"
                        >
                          <div class="mr-auto">
                            <p class="text-black font-w600 mb-2">
                              Wednesday, June 3th
                            </p>
                            <ul>
                              <li>
                                <i class="las la-clock"></i>09:00 - 10:30 AM
                              </li>
                              <li><i class="las la-user"></i>Dr. Samantha</li>
                            </ul>
                          </div>
                          <a
                            href="javascript:void(0)"
                            class="text-success mr-3 mb-2"
                          >
                            <i class="las la-check-circle scale5"></i>
                          </a>
                          <a href="javascript:void(0)" class="text-danger mb-2">
                            <i class="las la-times-circle scale5"></i>
                          </a>
                        </div>
                        <div
                          class="d-flex pb-3 border-bottom mb-3 align-items-end"
                        >
                          <div class="mr-auto">
                            <p class="text-black font-w600 mb-2">
                              Tuesday, June 16th
                            </p>
                            <ul>
                              <li>
                                <i class="las la-clock"></i>09:00 - 10:30 AM
                              </li>
                              <li><i class="las la-user"></i>Dr. Samantha</li>
                            </ul>
                          </div>
                          <a
                            href="javascript:void(0)"
                            class="text-success mr-3 mb-2"
                          >
                            <i class="las la-check-circle scale5"></i>
                          </a>
                          <a href="javascript:void(0)" class="text-danger mb-2">
                            <i class="las la-times-circle scale5"></i>
                          </a>
                        </div>
                        <div
                          class="d-flex pb-3 border-bottom mb-3 align-items-end"
                        >
                          <div class="mr-auto">
                            <p class="text-black font-w600 mb-2">
                              Saturday, June 27th
                            </p>
                            <ul>
                              <li>
                                <i class="las la-clock"></i>09:00 - 10:30 AM
                              </li>
                              <li><i class="las la-user"></i>Dr. Samantha</li>
                            </ul>
                          </div>
                          <a
                            href="javascript:void(0)"
                            class="text-success mr-3 mb-2"
                          >
                            <i class="las la-check-circle scale5"></i>
                          </a>
                          <a href="javascript:void(0)" class="text-danger mb-2">
                            <i class="las la-times-circle scale5"></i>
                          </a>
                        </div>
                        <div
                          class="d-flex pb-3 border-bottom mb-3 align-items-end"
                        >
                          <div class="mr-auto">
                            <p class="text-black font-w600 mb-2">
                              Wednesday, June 3th
                            </p>
                            <ul>
                              <li>
                                <i class="las la-clock"></i>09:00 - 10:30 AM
                              </li>
                              <li><i class="las la-user"></i>Dr. Samantha</li>
                            </ul>
                          </div>
                          <a
                            href="javascript:void(0)"
                            class="text-success mr-3 mb-2"
                          >
                            <i class="las la-check-circle scale5"></i>
                          </a>
                          <a href="javascript:void(0)" class="text-danger mb-2">
                            <i class="las la-times-circle scale5"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-12 col-xxl-4">
                <div class="card patient-list">
                  <div class="card-header border-0 pb-0">
                    <h4 class="fs-20 text-black mb-0">Patient List</h4>
                    <div class="dropdown">
                      <div
                        class="btn-link"
                        role="button"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11Z"
                            stroke="#2E2E2E"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18Z"
                            stroke="#2E2E2E"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4Z"
                            stroke="#2E2E2E"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" href="#">Accept Patient</a>
                        <a class="dropdown-item" href="#">Reject Order</a>
                        <a class="dropdown-item" href="#">View Details</a>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="d-flex mb-4">
                      <img src="/images/users/7.jpg" alt="" class="mr-3" />
                      <div>
                        <h5 class="mb-1">
                          <a href="patient.html" class="fs-18 text-black"
                            >Samuel Hawkins</a
                          >
                        </h5>
                        <span class="fs-14 text-success font-w600"
                          >Cold & Flue</span
                        >
                      </div>
                    </div>
                    <div class="d-flex mb-4">
                      <img src="/images/users/8.jpg" alt="" class="mr-3" />
                      <div>
                        <h5 class="mb-1">
                          <a href="patient.html" class="fs-18 text-black"
                            >Debora Saragih</a
                          >
                        </h5>
                        <span class="fs-14 text-success font-w600"
                          >Dental Care</span
                        >
                      </div>
                    </div>
                    <div class="d-flex mb-4">
                      <img src="/images/users/9.jpg" alt="" class="mr-3" />
                      <div>
                        <h5 class="mb-1">
                          <a href="patient.html" class="fs-18 text-black"
                            >Richard Kean</a
                          >
                        </h5>
                        <span class="fs-14 text-success font-w600"
                          >Physical Problem</span
                        >
                      </div>
                    </div>
                    <div class="d-flex">
                      <img src="/images/users/10.jpg" alt="" class="mr-3" />
                      <div>
                        <h5 class="mb-1">
                          <a href="patient.html" class="fs-18 text-black"
                            >Kevin Ash</a
                          >
                        </h5>
                        <span class="fs-14 text-success font-w600"
                          >Dental Care</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="card-footer pt-0 border-0">
                    <a
                      href="patient.html"
                      class="btn d-block btn-outline-success"
                      >+Assign New Patient</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/views/Header.vue";

export default {
  components: {
    Header,
  },
  data() {
    return {
      user: null,
      staff: "",
    };
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    getStaff() {
      this.axios
        .get(`/staff/profile`, {
          params: {
            scope: `id:${this.$route.params.id}`,
          },
        })
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.staff = response.data.data.data[0];
        })
        .catch((error) => {
          console.log(error.response);
          this.isLoading = false;
        });
    },
    deleteStaff() {
      if (confirm("Are you Sure you want to delete?")) {
        this.isLoading = true;
        this.axios
          .delete(`/staff/profile/${this.staff.id}`)
          .then((response) => {
            console.log(response);
            this.isLoading = false;
            this.$router.push('/all-staffs');
            this.isLoading = false;
            this.$toasted.success("Deleted Successfully!", {
              position: "top-center",
              duration: 3000,
            });
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
          });
      }
    },
  },
  created() {
    this.getStaff();
  },
};
</script>

