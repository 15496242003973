  
  import packageJSON from "../../package.json";

  packageJSON

  async function reloadPage() {
   

  if (localStorage.getItem("reloaded")) {
    localStorage.removeItem("reloaded");
  } else {
    localStorage.setItem("reloaded", "1");
    location.reload();
  }

}

 async function getAppVersion() {

  // console.log(packageJSON + 'load')

 return packageJSON
//  return 'packageJSON';

 }
export { reloadPage, getAppVersion };
