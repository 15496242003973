<template>
  <div>
    <Header />

    <div class="content-body">
      <div class="container-fluid">
        <div class="form-head d-flex mb-sm-4 mb-3">
          <div class="mr-auto">
            <h2 class="text-black font-w600">{{ role.name }} Activities  </h2>
            <p class="mb-0">Manage activities for {{ role.name }} here</p>
          </div>
          <div>
            <a
              href="javascript:void(0)"
              class="btn btn-success mr-3"
              @click="showForm = !showForm"
              ><i class="las la-plus scale5 mr-3"></i> Add Activity</a
            >
          </div>
        </div>

        <AddActivity v-show="showForm" ref="AddActivity" @update="onUpdate($event)" />

        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Activity</h4>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-responsive-md">
                    <thead>
                      <tr>
                        <th class="width80"><strong>#</strong></th>
                        <th><strong>Description</strong></th>
                        <th><strong>Created</strong></th>
                        <th><strong>Action</strong></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(activity, index) in activities.data"
                        :key="activity.id"
                      >
                        <td>
                          <strong> {{ index + 1 }} </strong>
                        </td>
                        <td>  {{ activity.description }}</td>
                        <td>
                          {{ activity.created_at | moment("from", "now") }}
                        </td>

                        <td>
                          <span>
                            <a
                              href="javascript:void()"
                              class="mr-4"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Edit"
                              @click="startUpdate(activity)"
                              ><i
                                class="fa fa-pencil color-muted text-success"
                              ></i>
                            </a>
                            <a
                              href="javascript:void()"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Close"
                              @click="deleteActivity(activity)"
                              ><i
                                class="fa fa-close color-danger text-success"
                              ></i
                            ></a>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="vld-parent">
          <loading
            :active.sync="isLoading"
            loader="spinner"
            :can-cancel="true"
            :is-full-page="fullPage"
          ></loading>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/views/Header.vue";
import AddActivity from "@/views/activities/AddActivity.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Header,
    Loading,
    AddActivity,
  },
  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      showForm: false,
      edit: false,
      activities: "",
      role: "",
    };
  },
  beforeMount() {},
  methods: {
   
    getRole() {
      this.isLoading = true;
      this.axios
      

        .get("/staff/job-titles/"+ this.$route.params.id)
        .then((response) => {
          console.log(response);
          this.role = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Querying!");
          this.isLoading = false;
        });
    },
    getActivities() {
      this.isLoading = true;
      this.axios
        .get(`/staff/actions`, {
          params:{
            'scope' : `job_title_id:${this.$route.params.id}`,

          }
        })
        .then((response) => {
          this.activities = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    deleteActivity(activity) {
      if (confirm("Are you Sure you want to delete?")) {
        this.isLoading = true;
        this.axios
          .delete(`/staff/actions/${activity.id}`)
          .then((response) => {
            console.log(response);
            this.$toastr.s("Deleted Successfully", "Success!");
            this.isLoading = false;
            this.getActivities();
            this.isLoading = false;
           
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
          });
      }
    },
    startUpdate(activity){
      this.showForm = true
      this.$refs.AddActivity.setValue(activity);


    },
    onUpdate() {
      this.getActivities();
      this.showForm = !this.showForm;
    },
  },
  created() {
    this.getRole();
    this.getActivities();
  },
};
</script>
