<template>
  <div>
    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Category Name</label>
                  <input
                    type="text"
                    placeholder="name"
                    class="form-control"
                    v-model="form_data.Title"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label> Description</label>
                  <textarea
                    class="form-control"
                    placeholder="Type your message..."
                    rows="5"
                    v-model="form_data.Description"
                  ></textarea>
                </div>
              </div>

              <div class="col-md-12">
                <button class="btn btn-success btn-block" type="submit">
                  Submit <i class="las la-paper-plane"></i>
                </button>
              </div>

              <hr class="mt-4 mb-4" />

            </form>

            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header">
                    <h4 class="card-title">Categories</h4>
                  </div>
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-responsive-md">
                        <thead>
                          <tr>
                            <th><strong>#</strong></th>
                            <th><strong>Title</strong></th>
                            <th><strong>Action</strong></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(bill, index) in categories.data"
                            :key="bill.Id"
                          >
                            <td>
                              <strong> {{ index + 1 }} </strong>
                            </td>
                            <td>{{ bill }}</td>
                            <td>
                              <span>
                                <a
                                  href="javascript:void()"
                                  class="mr-4"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Edit"
                                  @click="updateRole(role)"
                                  ><i
                                    class="
                                      fa fa-pencil
                                      color-muted
                                      text-success
                                    "
                                  ></i>
                                </a>
                                <a
                                  href="javascript:void()"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Close"
                                  @click="deleteRole(role)"
                                  ><i
                                    class="
                                      fa fa-close
                                      color-danger
                                      text-success
                                    "
                                  ></i
                                ></a>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
    
    <script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Loading,
  },
  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      edit: false,
      categories: "",
      services: "",
      form_data: {
        Title: "",
        Description: "",
        
      },
    };
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.selected_facility = JSON.parse(
      localStorage.getItem("selected_facility")
    );
  },
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addDept();
      } else {
        this.editDept();
      }
    },
    addDept() {
      this.isLoading = true;
      var formData = new FormData();
      formData.append("Title", this.form_data.Title);
      formData.append("Amount", this.form_data.Amount);
      formData.append("Description", this.form_data.Description);

      this.axios
        .post("/services", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toastr.s("Invoice Created Successfully", "Success!");
          // this.getDepts();
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");

          this.isLoading = false;
        });
    },
    updateDept() {
      //
    },

    getCategories() {
      this.isLoading = true;
      this.selected_facility = JSON.parse(
        localStorage.getItem("selected_facility")
      );

      this.axios
        .get(`/categories`)
        .then((response) => {
          this.patients = response.data;
          this.isLoading = false;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },

  
  },
  created() {
    this.getCategories();
  },
};
</script>
    