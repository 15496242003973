<template>
  <div>
    <Header />

    <div class="content-body">
      <div class="container-fluid">
        <div class="form-head d-flex mb-sm-4 mb-3">
          <div class="mr-auto">
            <h2 class="text-black font-w600">{{ $route.params.type }} Manager</h2>
            <p class="mb-0">
              Create {{ $route.params.type }} and attach a price for proper billing.
            </p>
          </div>

          <div class="dropdown ml-auto mr-2">
            <a
              href="#"
              class="btn btn-success light sharp"
              data-toggle="dropdown"
              aria-expanded="true"
            >
              <li class="las la-ellipsis-h scale5"></li>
            </a>
            <ul class="dropdown-menu dropdown-menu-right">
              <li class="dropdown-item">
                <span v-b-toggle.sidebar-right>
                  <a
                    href="javascript:void()"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Add"
                    ><i class="las la-plus color-muted text-success mr-2"></i>
                  </a>
                  New {{ $route.params.type }}
                </span>
              </li>

              <li class="dropdown-item">
                <a
                  href="javascript:void()"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Export"
                  ><i
                    class="las la-file-export color-muted text-success mr-2"
                  ></i>
                </a>
                <span class="text-secondary cursor"> Export to CSV </span>
              </li>
            </ul>
          </div>
        </div>

        <b-sidebar
          id="sidebar-right"
          :title="`Add ${$route.params.type}`"
          :visible="showSidebar"
          right
          shadow
          width="500px"
        >
          <div class="px-3 py-2">
            <AddService ref="AddService" @update="onUpdate($event)" />
          </div>
        </b-sidebar>

        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">{{ $route.params.type }}</h4>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-responsive-md">
                    <thead>
                      <tr>
                        <th class="width80"><strong>#</strong></th>
                        <th><strong>Title</strong></th>
                        <th><strong>Description</strong></th>
                        <th><strong>Amount</strong></th>
                        <th><strong>Created At</strong></th>
                        <th><strong>Action</strong></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(service, index) in services.data"
                        :key="service.id"
                      >
                        <td>
                          <strong> {{ index + 1 }} </strong>
                        </td>
                        <td>{{ service.name }}</td>
                        <td>{{ service.description }}</td>
                        <td>
                          <i class="mdi mdi-currency-ngn"></i>
                          {{ service.amount | numeral(0, 0) }}
                        </td>
                        <td>
                          {{ service.created_at | moment("dddd, MMMM Do YYYY") }}
                        </td>
                        <td>
                          <span>
                            <a
                              href="javascript:void()"
                              class="mr-4"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Edit"
                              v-b-toggle.sidebar-right
                              @click="startUpdate(service)"
                              ><i
                                class="fa fa-pencil color-muted text-success"
                              ></i>
                            </a>
                            <a
                              href="javascript:void()"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Close"
                              @click="deleteService(service)"
                              ><i
                                class="fa fa-trash color-danger text-success"
                              ></i
                            ></a>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <nav>
              <ul class="pagination pagination-gutter">
                <li class="page-item page-indicator">
                  <a class="page-link" href="javascript:void()">
                    <i class="la la-angle-left"></i
                  ></a>
                </li>
                <li
                  :class="
                    services.current_page == link
                      ? 'page-item active'
                      : 'page-item'
                  "
                  v-for="link in services.last_page"
                  :key="link"
                >
                  <a
                    class="page-link"
                    href="javascript:void()"
                    @click="getServices(link)"
                    >{{ link }}
                  </a>
                </li>

                <li class="page-item page-indicator">
                  <a class="page-link" href="javascript:void()">
                    <i class="la la-angle-right"></i
                  ></a>
                </li>
              </ul>
            </nav>
              </div>
            </div>
          </div>
        </div>

        <div class="vld-parent">
          <loading
            :active.sync="isLoading"
            loader="spinner"
            :can-cancel="true"
            :is-full-page="fullPage"
          ></loading>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Header from "@/views/Header.vue";
import AddService from "@/views/services/AddService.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin
// import { reloadPage } from "@/functions/Settings";

export default {
  components: {
    Header,
    Loading,
    AddService,
  },
  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      showSidebar: false,
      edit: false,
      services: "",
    };
  },
  beforeCreate() {
    //  reloadPage();
    
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addDept();
      } else {
        this.editDept();
      }
    },

    startUpdate(service) {
      this.$refs.AddService.setValue(service);
      console.log(service);
    },

    getServices(page){
      if (this.$route.params.type == 'Services') {
        this.getAllServices(page)
      } else {
        this.getDrugs(page)
        
      }
    },
    getAllServices(page) {
      this.isLoading = true;
      this.axios
        .get(`/staff/services-filter/0`,{
          params:{
            page: page
          }
        })
        .then((response) => {
          this.services = response.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.$toastr.e("error on querying", "Error!");
        });
    },

    getDrugs(page) {
      this.isLoading = true;
      this.axios
        .get(`/staff/services-filter/1`,{
          params:{
            page: page
          }
        })
        .then((response) => {
          this.services = response.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.$toastr.e("error on querying", "Error!");
        });
    },

    deleteService(service) {
      if (confirm("Are you Sure you want to delete?")) {
        this.isLoading = true;
        this.axios
          .delete(`/staff/services/${service.id}`)
          .then((response) => {
            console.log(response);
            this.isLoading = false;
            this.getServices();
            this.$toastr.s("Deleted Successfully", "Success!");
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
          });
      }
    },
    onUpdate() {
      this.getServices();
      this.showSidebar = !this.showSidebar;
    },
  },
  created() {
    this.getServices();
  },
};
</script>
  