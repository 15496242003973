<template>
  <div>
    <form>
      <div class="form-group">
        <v-select
          v-model="optical.name"
          class="form-control"
          :options="special_prescriptions"
        />
      </div>

      <div class="col-md-12" v-if="optical.name == 'Optical Prescription'">
        <div class="table-responsive">
          <table class="table table-responsive-md">
            <thead>
              <tr>
                <th class="width80"><strong>RX</strong></th>
                <th><strong>Sphere</strong></th>
                <th><strong>Cylinder</strong></th>
                <th><strong>Axis</strong></th>
                <th><strong>Prism</strong></th>
                <th><strong>ADD</strong></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="optical in optical.table_data" v-bind:key="optical.RX">
                <td>
                  <strong>{{ optical.RX }} </strong>
                </td>
                <td>
                  <input
                    type="text"
                    v-model="optical.Sphere"
                    class="form-control"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    v-model="optical.Cylinder"
                    class="form-control"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    v-model="optical.Axis"
                    class="form-control"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    v-model="optical.Prism"
                    class="form-control"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    v-model="optical.ADD"
                    class="form-control"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="form-row">
          <div class="col-md-6">
            <label for="">Expiration</label>
            <input
              type="date"
              class="form-control"
              v-model="optical.expiration_date"
              id=""
            />
          </div>

          <div class="col-md-6">
            <label for="">Pupil Distance</label>
            <input
              type="text"
              class="form-control"
              v-model="optical.pupil_distance"
              id=""
            />
          </div>
        </div>

        <div class="col-md-12">
          <b-form-group label="Others:" v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
              id="others"
              v-model="optical.others"
              :options="others"
              :aria-describedby="ariaDescribedby"
              name="flavour-1"
              class="mb-3"
            ></b-form-checkbox-group>
          </b-form-group>
        </div>
      </div>

      <div class="" v-if="optical.name == 'Ophthalmic Notes'">
        <div class="form-row">
          <div class="col-md-6">
            <label for="">PC</label>

            <wysiwyg v-model="optical.ophthalmic.pc" />
          </div>

          <div class="col-md-6">
            <label for="">HPC</label>
            <wysiwyg v-model="optical.ophthalmic.hpc" />
          </div>
          <div class="col-md-6">
            <label for="">POH</label>
            <wysiwyg v-model="optical.ophthalmic.poh" />
          </div>
          <div class="col-md-6">
            <label for="">PMH</label>
            <wysiwyg v-model="optical.ophthalmic.pmh" />
          </div>
          <div class="col-md-6">
            <label for="">Drug HX</label>

            <wysiwyg v-model="optical.ophthalmic.drug_hx" />
          </div>
          <div class="col-md-6">
            <label for="">FSH</label>
            <wysiwyg v-model="optical.ophthalmic.fsh" />
          </div>

          <div class="col-md-12">
            <label for="">Others</label>
            <wysiwyg v-model="optical.ophthalmic.others" />
          </div>

          <div class="col-md-12 my-3">
            <h5>Examination</h5>
          </div>

          <div class="col-md-5 mb-2">
            <wysiwyg v-model="optical.examination.left_va" />
          </div>

          <div class="col-md-2">
            <p class="text-center">VA</p>
          </div>

          <div class="col-md-5 mb-2">
            <wysiwyg v-model="optical.examination.right_va" />
          </div>

          <div class="col-md-5 mb-2">
            <wysiwyg v-model="optical.examination.left_iop" />
          </div>

          <div class="col-md-2 mb-2">
            <p class="text-center">IOP</p>
          </div>

          <div class="col-md-5 mb-2">
            <wysiwyg v-model="optical.examination.right_iop" />
          </div>

          <div class="col-md-5 mb-2">
            <wysiwyg v-model="optical.examination.left_lid" />
          </div>

          <div class="col-md-2 mb-2">
            <p class="text-center">Lids</p>
          </div>

          <div class="col-md-5 mb-2">
            <wysiwyg v-model="optical.examination.right_lid" />
          </div>

          <div class="col-md-5 mb-2">
            <wysiwyg v-model="optical.examination.left_conj" />
          </div>

          <div class="col-md-2 mb-2">
            <p class="text-center">Conj</p>
          </div>

          <div class="col-md-5 mb-2">
            <wysiwyg v-model="optical.examination.right_conj" />
          </div>

          <div class="col-md-5 mb-2">
            <wysiwyg v-model="optical.examination.left_cornea" />
          </div>

          <div class="col-md-2 mb-2">
            <p class="text-center">Cornea</p>
          </div>

          <div class="col-md-5 mb-2">
            <wysiwyg v-model="optical.examination.right_cornea" />
          </div>

          <div class="col-md-5 mb-2">
            <wysiwyg v-model="optical.examination.left_ac" />
          </div>

          <div class="col-md-2 mb-2">
            <p class="text-center">AC</p>
          </div>

          <div class="col-md-5 mb-2">
            <wysiwyg v-model="optical.examination.right_ac" />
          </div>

          <div class="col-md-5 mb-2">
            <wysiwyg v-model="optical.examination.left_pupils" />
          </div>

          <div class="col-md-2 mb-2">
            <p class="text-center">Pupils</p>
          </div>

          <div class="col-md-5 mb-2">
            <wysiwyg v-model="optical.examination.right_pupils" />
          </div>

          <div class="col-md-5 mb-2">
            <wysiwyg v-model="optical.examination.left_lens" />
          </div>

          <div class="col-md-2 mb-2">
            <p class="text-center">Lens</p>
          </div>

          <div class="col-md-5 mb-2">
            <wysiwyg v-model="optical.examination.right_lens" />
          </div>

          <div class="col-md-5 mb-2">
            <wysiwyg v-model="optical.examination.left_fundus" />
          </div>

          <div class="col-md-2 mb-2">
            <p class="text-center">Fundus</p>
          </div>

          <div class="col-md-5 mb-2">
            <wysiwyg v-model="optical.examination.right_fundus" />
          </div>
        </div>

        <div class="col-md-8 offset-md-2 mb-2">
          <label for="">Diagnosis</label>
          <input type="text" class="form-control" v-model="optical.examination.diagnosis">
          </div>
      </div>

      <div class="form-group mt-4">
        <a
          class="btn btn-success btn-block"
          data-dismiss="modal"
          @click="updateParent"
        >
          Continue
        </a>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      special_prescriptions: ["Ophthalmic Notes", "Optical Prescription"],
      others: [
        "Progressive",
        "Ultrathin",
        "Tint",
        "Transition",
        "Invisible BF",
        "D-Top BF",
        "Fused BF",
        "BLue cut",
        "Reading/Computer",
        "Distance (SV)",
      ],
      optical: {
        name: "",
        others: [],
        expiration_date: "",
        pupil_distance: "",
        table_data: [
          {
            RX: "O.D",
            Sphere: "",
            Cylinder: "",
            Axis: "",
            Prism: "",
            ADD: "",
          },
          {
            RX: "O.S",
            Sphere: "",
            Cylinder: "",
            Axis: "",
            Prism: "",
            ADD: "",
          },
        ],
        ophthalmic: {
          pc: "",
          hpc: "",
          poh: "",
          pmh: "",
          drug_hx: "",
          fsh: "",
          others: "",
        },
        examination: {
          left_iop: "",
          right_iop: "",
          left_va: "",
          right_va: "",
          left_lids: "",
          right_lids: "",
          left_conj: "",
          right_conj: "",
          left_cornea: "",
          right_cornea: "",
          right_ac: "",
          left_ac: "",
          left_pupils: "",
          right_pupils: "",
          left_lens: "",
          right_lens: "",
          left_fundus: "",
          right_fundus: "",
          diagnosis: "",
        },
      },
    };
  },

  methods: {
    updateParent() {
      this.$emit("updateParent", this.optical);
    },
  },
  created(){
    this.updateParent()
  }
};
</script>
