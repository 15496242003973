<template>
  <!-- Chart Card -->
  <div class="card">
    <div class="card-body">
      <apexchart
        type="bar"
        height="300"
        :options="options"
        :series="series"
      ></apexchart>
    </div>
  </div>

  <!-- End of Chart -->
</template>

<script>
export default {
  data() {
    return {
      series: [
        {
          name: "Remaining Quantity",
          data: [39, 80, 37, 40, 20, 67, 16, 19, 45, 111, 44, 5],
        },
      ],
      options: {
        chart: {
          id: "pharm-qty",
        },
        xaxis: {
          categories: [
            "Syringes",
            "Condoms",
            "Parcetamol",
            "Chloroquin",
            "Amoxylin",
            "Hand Gloves",
            "Face Mask",
            "Amaldopin",
            "Drug1",
            "Drug2",
            "Drug3",
            "Drug4",
          ],
        },

        colors: ["#097969"],
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: 0,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        title: {
          text: "Low in Stock (Pharmacy)",
          floating: true,
          margin: 10,
          offsetY: 5,
          align: "center",
          style: {
            color: "#444",
          },
        },
      },
    };
  },
};
</script>