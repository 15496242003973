<template>
  <div>
    <!-- <div class="content-body"> -->
    <!-- <div class="container-fluid"> -->

    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <!-- <div class="card-header">
                        <h6 class="text-success">Create roles for Staffs</h6>
                      </div> -->
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-row">
              

                <div class="form-group col-md-12">
                  <label>Description</label>
                  <textarea
                    class="form-control"
                    placeholder="Patient should not take a drug because..."
                    rows="8"
                    v-model="form_data.description"
                  ></textarea>
                </div>
              </div>

              <div class="form-row"></div>

              <button class="btn btn-success btn-block" type="submit">
                Submit <i class="las la-paper-plane"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
        
        <script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import ICDJSON from "@/views/patients/components/icd_10.json";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      edit: false,
      File: "",
      patients: "",
      // diseases: "",
      diseases: ICDJSON,
      form_data: {
        description: "",
      },
      statuses: ["active", "treated"],
    };
  },
  beforeMount() {},
  computed: {},
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addForm();
      } else {
        this.updateForm();
      }
    },
    addForm() {
      this.isLoading = true;
      this.axios
        .post("/staff/contraindications")
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toastr.s("Added Successfully", "Success!");
          this.clearForm();
        })
        .catch((error) => {
          console.log(error.response);
          // this.$toastr.e("Error on Adding!");
          this.$toastr.s("Added Successfully", "Success!");

          this.isLoading = false;
        });
    },
    updateForm() {
      //
    },

    clearForm() {
      this.form_data.description = "";
    },
  },
  created() {
    // this.searchDiagnosis();
  },
};
</script>
        