<template>
  <div>
    <!-- <div class="content-body"> -->
    <!-- <div class="container-fluid"> -->

    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <!-- <div class="card-header">
                  <h6 class="text-success">Create roles for Staffs</h6>
                </div> -->
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Bed Code/ID</label>
                  <input
                    type="text"
                    placeholder="A1, B2, C3"
                    class="form-control"
                    v-model="bed.bed_code"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="inputPassword4">Bed for Which Ward? </label>
                  <v-select
                    v-model="bed.ward_id"
                    :reduce="(name) => name.id"
                    label="name"
                    :options="wards.data"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label> Description</label>
                  <textarea
                    class="form-control"
                    placeholder="Type your message..."
                    rows="4"
                    v-model="bed.description"
                  ></textarea>
                </div>
              </div>

              <button class="btn btn-success btn-block" type="submit">
                Submit <i class="las la-paper-plane"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
  
  <script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Loading,
  },
  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      edit: false,
      wards: "",
      bed: {
        facility_id: this.$selected_facility.id,
        bed_code: "",
        Ward_id: "",
        description: "",
      },
    };
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addBed();
      } else {
        this.updateBed();
      }
    },
    addBed() {
      this.isLoading = true;
      this.axios
        .post("/staff/beds", this.bed)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.clearForm();
          this.$emit("update");
          this.$toastr.s("Bed Created Successfully", "Success!");
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");
          this.isLoading = false;
        });
    },
    updateBed() {
      this.isLoading = true;
      this.axios
        .patch(`/staff/beds/${this.bed.id}`, this.bed)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.clearForm();
          this.$emit("update");
          this.$toastr.s("Updated Successfully", "Success!");
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.$toastr.e("not successful", "Error!");
        });
    },
    setValue(value) {
      this.edit = true;
      this.bed = value;
      console.log(value);
    },

    getWards() {
      this.isLoading = false;
      this.axios
        .get(`/staff/wards`)
        .then((response) => {
          this.wards = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    clearForm() {
      this.bed.bed_code = "";
      this.bed.Ward_id = "";
      this.bed.description = "";
      this.edit = false;
    },
  },
  created() {
    this.getWards();
  },
};
</script>
  