<template>
  <div>
    <Header />

    <div class="content-body">
      <div class="container-fluid">
        <div class="form-head d-flex mb-sm-4 mb-3">
          <div class="mr-auto">
            <h2 class="text-black font-w600">Bills Manager</h2>
            <p class="mb-0">Create Bills for services here</p>
          </div>
          <div>
            <div class="dropdown ml-auto mr-2">
              <a
                href="#"
                class="btn btn-success light sharp"
                data-toggle="dropdown"
                aria-expanded="true"
              >
                <li class="las la-ellipsis-h scale5"></li>
              </a>
              <ul class="dropdown-menu dropdown-menu-right">
                <li class="dropdown-item">
                  <span v-b-toggle.sidebar-right>
                    <a
                      href="javascript:void()"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Add"
                      ><i class="las la-plus color-muted text-success mr-2"></i>
                    </a>
                    New Bill
                  </span>
                </li>

                <li class="dropdown-item">
                  <a
                    href="javascript:void()"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Export"
                    ><i
                      class="las la-file-export color-muted text-success mr-2"
                    ></i
                  ></a>
                  Export to CSV
                </li>
              </ul>
            </div>
          </div>
        </div>
        <b-sidebar
          id="sidebar-right"
          title="New Bill"
          right
          shadow
          width="650px"
        >
          <div class="px-3 py-2">
            <AddBill />
          </div>
        </b-sidebar>

        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Bills</h4>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-responsive-md">
                    <thead>
                      <tr>
                        <th class="width80"><strong>#</strong></th>
                        <th><strong>Payment Date</strong></th>
                        <th><strong>Patient</strong></th>
                        <th><strong>Amount</strong></th>
                        <th><strong>Payment Method</strong></th>
                        <th><strong>Status</strong></th>
                        <th><strong>Action</strong></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(bill, index) in bills.data" :key="bill.id">
                        <td>
                          <strong> {{ index + 1 }} </strong>
                        </td>
                        <td>{{ bill.bill_date }}</td>
                        <td>{{ bill.patient.full_name }}</td>
                        <td>&#x20A6;{{ bill.bill_items_sum_amount | numeral(0, 0.00) }}</td>
                        <td>{{ bill.payment_method }}</td>
                        <td>
                          <a
                            v-if="bill.status != 'completed'"
                            href="javascript:void()"
                            class="badge badge-rounded badge-xs badge-outline-success text-success"
                            >{{ bill.status }}
                          </a>

                          <a
                            v-else
                            href="javascript:void()"
                            class="badge badge-rounded badge-xs badge-outline-success text-success"
                            >{{ bill.status }}
                          </a>
                        </td>
                        <td>

                          <span>
                            <!-- <a
                              :href="`/edit-staff-${bill.id}`"
                              class="mr-4 btn btn-outline-success"
                              ><i class="las la-edit"></i>
                            </a> -->
                            <a
                              :href="`/bill-${bill.id}`"
                              class="btn btn-outline-success"
                              ><i class="las la-eye"></i
                            ></a>
                          </span>


                         
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="vld-parent">
          <loading
            :active.sync="isLoading"
            loader="spinner"
            :can-cancel="true"
            :is-full-page="fullPage"
          ></loading>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Header from "@/views/Header.vue";
import AddBill from "@/views/finances/AddBill.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

import { reloadPage } from "@/functions/Settings";

export default {
  components: {
    Header,
    Loading,
    AddBill,
  },
  data() {
    return {
      user: null,
      isLoading: false,
      fullPage: true,
      edit: false,
      bills: "",
    };
  },
  beforeCreate() {
    reloadPage();
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.selected_facility = JSON.parse(
      localStorage.getItem("selected_facility")
    );
  },
  methods: {
    getBills() {
      this.isLoading = true;

      this.axios
        .get(`/staff/bills`)
        .then((response) => {
          this.bills = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    deleteRole(bill) {
      if (confirm("Are you Sure you want to delete?")) {
        this.isLoading = true;
        this.axios
          .patch(`/staff/bills/${bill.id}`)
          .then((response) => {
            console.log(response);
            this.isLoading = false;
            this.getRoles();
            this.isLoading = false;
            this.$toasted.success("Deleted Successfully!", {
              position: "top-center",
              duration: 3000,
            });
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
          });
      }
    },
  },
  created() {
    this.getBills();
  },
};
</script>
  