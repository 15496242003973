<template>
  <div>
    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header">
                    <h4 class="card-title pt-4 pb-4">Hospitalizations</h4>

                  </div>
                  <div class="card-body">
                    <vue-good-table
                      :columns="columns"
                      :rows="hospitalizations.data"
                      :search-options="{ enabled: true }"
                      @on-row-click="onRowClick"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
          
          <script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Loading,
  },
  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      hospitalizations: "",
      columns: [
        {
          label: "Ward",
          field: "ward.name",
        },
        {
          label: "Bed",
          field: "bed.bed_code",
        },
        {
          label: "Blood Pressure",
          field: "bp",
          type: "text",
        },
        {
          label: "Height",
          field: "height",
          type: "text",
        },
        {
          label: "Weight",
          field: "weight",
          type: "text",
        },
        {
          label: "Symptoms",
          field: "symptoms",
          type: "text",
        },
        {
          label: "Status",
          field: "status",
          type: "text",
        },
      ],
    
    };
  },
  beforeMount() {
   
  },
  methods: {
    fetchData() {
      this.isLoading = true;
    
      this.axios
        .get(`/staff/patient-hospitalizations/${this.$route.params.id}`)
        .then((response) => {
          this.hospitalizations = response.data;
          this.isLoading = false;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    onRowClick(params) {
      this.$router.push(`/hospitalization/${params.row.id}`)
   
  }
  },
  created() {
    this.fetchData();
  },
};
</script>
          