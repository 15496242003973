<template>
  <div class="row">
    <div class="col-md-3 text-white">
      <div class="card bg-success">
        <div class="card-body">
          <div class="media align-items-center">
            <div class="media-body mr-3">
              <h2 class="fs-34 text-white font-w600">0</h2>
              <span>Products</span>
            </div>
            <i class="las la-tablets la-2x"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-3">
      <div class="card">
        <div class="card-body">
          <div class="media align-items-center">
            <div class="media-body mr-3">
              <h2 class="fs-34 text-black font-w600"> {{ metrics.patients }} </h2>
              <span>Patients</span>
            </div>
            <i class="las la-user-injured la-2x"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-3">
      <div class="card bg-dark text-white">
        <div class="card-body">
          <div class="media align-items-center">
            <div class="media-body mr-3">
              <h2 class="fs-34 text-white font-w600"> {{ metrics.staffs }} </h2>
              <span>Staff  </span>
            </div>
            <i class="las la-user-friends la-2x"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-3">
      <div class="card bg-warning">
        <div class="card-body">
          <div class="media align-items-center">
            <div class="media-body mr-3">
              <h2 class="fs-34 text-black font-w600">0</h2>
              <span>Low Stock</span>
            </div>
            <i class="las la-percent la-2x"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default{
  props:['metrics'],
  data(){
    return{

    }
  }
}

</script>