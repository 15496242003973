import { connection } from "./jsstore_con";

export class CaseService {

    constructor() {

        this.tableName = "cases";
    }

    

    getCases() {
        return connection.select({
            from: this.tableName,
        })
    }

    addCase(payload) {
        return connection.insert({
            into: this.tableName,
            values: [payload],
            return: true
        })
    }

    getRecordById(id) {
        return connection.select({
            from: this.tableName,
            where: {
                id: id
            }
        })
    }

    getRecordByPatient(patient_card_no) {
        return connection.select({
            from: this.tableName,
            where: {
                patient_card_no: patient_card_no
            }
        })
    }

    getLabRecordByPatient(patient_card_no, is_lab) {
        return connection.select({
            from: this.tableName,
            where: {
                patient_card_no : patient_card_no,
                is_lab : is_lab,
            }
        })
    }

    removeRecord(id) {
        return connection.remove({
            from: this.tableName,
            where: {
                id: id
            }
        })
    }
    clearTable(){
   return  connection.clear('cases')
   .then(response=>{
     console.log(response);

   })


    }

    updatePatientById(patient) {
        return connection.update({
            in: this.tableName,
            set: {
                first_name: patient.first_name,
                last_name: patient.last_name,
                email: patient.email,
                phone_no: patient.phone_no,
                dob: patient.dob,
                gender: patient.gender,
                genotype: patient.genotype,
            },
            where: {
                id: patient.id
            }
        })
    }
}
