<template>
  <div>
  
    <div class="col-lg-12">
      <div class="card">
        <div class="settings-form">
          <div class="card-header">
            <h4 class="text-success">Add Activity/Tasks</h4>
      
          </div>
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label> Description</label>
                  <textarea
                    class="form-control"
                    placeholder="Type your message..."
                    rows="4"
                    v-model="activity.description"
                  ></textarea>
                </div>
              </div>

              <button class="btn btn-success btn-block" type="submit">
                Submit <i class="las la-paper-plane"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>

</template>

<script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Loading,
  },
  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      edit: false,
      actions: "",
      activity: {
        description: "",
      },
    };
  },
  beforeMount() {
   
  },
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addActivity();
      } else {
        this.updateActivity();
      }
    },
    addActivity() {
      this.isLoading = true;
      this.axios
        .post("/staff/actions", {
          description: this.activity.description,
          facility_id: this.$selected_facility.id,
          job_title_id: this.$route.params.id,
        })
        .then((response) => {
          console.log(response);
          this.$toastr.s("Action Created Successfully", "Success!");
          this.isLoading = false;
          this.activity.description = "";
          this.$emit("update");
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");

          this.isLoading = false;
        });
    },
    updateActivity() {
      this.isLoading = true;
      this.axios
        .patch(`/staff/actions/${this.activity.id}`, {
          description: this.activity.description,
          facility_id: this.$selected_facility.id,
          job_title_id: this.$route.params.id,
        })
        .then((response) => {
          console.log(response);
          this.$toastr.s("Action Updated Successfully", "Success!");
          this.isLoading = false;
          this.activity.description = "";
          this.edit = false;
          this.$emit("update");
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");
          this.isLoading = false;
        });
    },

    setValue(value) {
      this.edit = true;
      this.activity = value;
      console.log(value);
    },
  },
  created() {
    // this.getDepts();
  },
};
</script>
