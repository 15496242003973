<template>
  <div>
    <!-- <div class="content-body"> -->
    <!-- <div class="container-fluid"> -->

    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <!-- <div class="card-header">
                    <h6 class="text-success">Create roles for Staffs</h6>
                  </div> -->
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Payment Date</label>
                  <input
                    type="date"
                    placeholder="20/20/20"
                    class="form-control"
                    v-model="form_data.payment_date"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label>Payment Made To?</label>
                  <input
                    type="text"
                    placeholder="The Collector"
                    class="form-control"
                    v-model="form_data.payment_to"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label>Amount </label>
                  <input
                    type="number"
                    placeholder="3000"
                    class="form-control"
                    v-model="form_data.amount"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label for="inputPassword4">Status of Payment </label>
                  <v-select v-model="form_data.status" :options="statuses" />
                </div>
              </div>
              <div class="form-group col-md-12">
                <label for="">Description</label>
                <textarea
                  name=""
                  id=""
                  v-model="form_data.description"
                  rows="5"
                  class="form-control"
                ></textarea>
              </div>

              <div class="form-row">
                <!-- <div class="form-group col-md-12">
                          <h5 class="mb-4">
                            <i class="fa fa-paperclip"></i> Upload Proof of Payment
                          </h5>
                          <vue-dropzone
                            ref="myVueDropzone"
                            id="dropzone"
                            :options="dropzoneOptions"
                            v-on:vdropzone-sending="sendingEvent"
                          ></vue-dropzone>
                        </div> -->
              </div>

              <button class="btn btn-success btn-block" type="submit">
                Submit <i class="las la-paper-plane"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
    
    <script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

//Init plugin
// import vue2Dropzone from "vue2-dropzone";
// import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  components: {
    Loading,
    // vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      edit: false,
      File: "",
      patients: "",
      services: "",
      form_data: {
        payment_to: "",
        status: "",
        receipt: "",
        payment_date: "",
        description: "description",
      },
      statuses: ["Pending", "Completed"],
      modes: ["Offline", "Online"],
      for_list: ["Goods bought", "Hospital Equipment", "Invoice", "Others"],
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 2.5,
        dictDefaultMessage: "<i class='fa fa-cloud-upload'></i>Upload Payment",
        headers: { "My-Awesome-Header": "header value" },
      },
    };
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addPayment();
      } else {
        this.editDept();
      }
    },
    addPayment() {
      this.isLoading = true;

      this.axios
        .post("/staff/payments", this.form_data)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toastr.s("Payment Created Successfully", "Success!");
          // this.getDepts();
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");
          this.clearForm();
          this.isLoading = false;
        });
    },
    updateDept() {
      //
    },

    clearForm() {
      this.form_data.Type = "Debit";
      this.form_data.Mode = "";
      this.form_data.Status = "";
      this.form_data.For = "";
      this.form_data.Receipt = "";
      this.form_data.Amount = "";
    },

    getServices() {
      this.isLoading = true;
      this.axios
        .get(`/services`)
        .then((response) => {
          this.services = response.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },

    sendingEvent(file, xhr, formData) {
      formData.append("paramName", "some value or other");
      this.File = file;
    },
  },
  created() {
    // this.getServices();
  },
};
</script>
    