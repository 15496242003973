import { render, staticRenderFns } from "./SingleLaboratory.vue?vue&type=template&id=61ef0665&scoped=true&"
import script from "./SingleLaboratory.vue?vue&type=script&lang=js&"
export * from "./SingleLaboratory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61ef0665",
  null
  
)

export default component.exports