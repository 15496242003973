<template>
  <div>
    <Header  v-if="$route.path == '/emergency-calls'" />

    <div class="content-body">
      <div class="container-fluid">
        <div class="form-head d-flex mb-sm-4 mb-3">
          <div class="mr-auto">
            <h2 class="text-black font-w600">Emergency/Ambulance Calls</h2>
            <p class="mb-0">Manage all emergency calls here</p>
          </div>
          <div class="dropdown ml-auto mr-2">
            <a
              href="#"
              class="btn btn-success light sharp"
              data-toggle="dropdown"
              aria-expanded="true"
            >
              <li class="las la-ellipsis-h scale5"></li>
            </a>
            <ul class="dropdown-menu dropdown-menu-right">
              <li class="dropdown-item">
                <span v-b-toggle.sidebar-right>
                  <a
                    href="javascript:void()"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Add"
                    ><i class="las la-plus color-muted text-success mr-2"></i>
                  </a>
                  New Emergency
                </span>
              </li>

              <li class="dropdown-item">
                <a
                  href="javascript:void()"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Export"
                  ><i
                    class="las la-file-export color-muted text-success mr-2"
                  ></i
                ></a>
                Export to CSV
              </li>
            </ul>
          </div>
        </div>
        <b-sidebar
          id="sidebar-right"
          title="Emergency Form"
          :visible="showSidebar"
          right
          shadow
          width="600px"
        >
          <div class="px-3 py-2">
            <AddCall ref="AddCall" @update="onUpdate($event)" />
          </div>
        </b-sidebar>

        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Calls</h4>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-responsive-md">
                    <thead>
                      <tr>
                        <th class="width80"><strong>#</strong></th>
                        <th><strong>Patient</strong></th>
                        <th><strong>Ambulance</strong></th>
                        <th><strong>Date of Call</strong></th>
                        <th><strong>Action</strong></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(call, index) in calls.data" :key="call.id">
                        <td>
                          <strong> {{ index + 1 }} </strong>
                        </td>
                        <td>
                          {{ call.patient.first_name }}
                          {{ call.patient.last_name }}
                        </td>
                        <td>
                          {{ call.ambulance.vehicle_model }} (
                          {{ call.ambulance.year_made }} )
                        </td>
                        <td>{{ call.date_of_call }}</td>
                        <td>
                          <span>
                            <a
                              href="javascript:void()"
                              class="mr-4"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Edit"
                              v-b-toggle.sidebar-right
                              @click="startUpdate(call)"
                              ><i
                                class="fa fa-pencil color-muted text-success"
                              ></i>
                            </a>
                            <a
                              href="javascript:void()"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Close"
                              @click="deleteCall(call)"
                              ><i
                                class="fa fa-trash color-danger text-success"
                              ></i
                            ></a>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
       
        </div>

        <div class="vld-parent">
          <loading
            :active.sync="isLoading"
            loader="spinner"
            :can-cancel="true"
            :is-full-page="fullPage"
          ></loading>
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
import Header from "@/views/Header.vue";
import AddCall from "@/views/emergencies/AddCall.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin
// import { reloadPage } from "@/functions/Settings";

export default {
  components: {
    Header,
    Loading,
    AddCall,
  },
  data() {
    return {
      user: null,
      isLoading: false,
      fullPage: true,
      showSidebar: false,
      edit: false,
      calls: "",
    };
  },
  beforeCreate() {
    //  reloadPage()
    
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    getCalls() {
      this.isLoading = true;
      this.axios
        .get(`/staff/emergency-calls`)
        .then((response) => {
          this.calls = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    startUpdate(call) {
      // this.showForm  = true
      this.$refs.AddCall.setValue(call);
      console.log(call);
    },
    deleteCall(call) {
      if (confirm("Are you Sure you want to delete?")) {
        this.isLoading = true;
        this.axios
          .delete(`/staff/emergency-calls/${call.id}`)
          .then((response) => {
            console.log(response);
            this.isLoading = false;
            this.getCalls();
            this.$toastr.s("Deleted Successfully", "Success!");
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
          });
      }
    },
    onUpdate() {
      this.getCalls();
      this.showSidebar = !this.showSidebar;
    },
  },
  created() {
    this.getCalls();
  },
};
</script>
    