<template>
  <div>
    <Header />

    <div class="content-body">
      <div class="container-fluid">
        <div class="form-head d-flex mb-sm-4 mb-3">
          <div class="mr-auto">
            <h2 class="text-black font-w600">Pharmacy Dashboard</h2>
          </div>
        </div>

        <PharmacyDashboardCards :metrics="metrics" />

        <div class="row">
          <div class="col-md-12">
            <SalesProductsTotal />
          </div>

          <div class="col-md-12">
            <LowInStockPharmacy />
          </div>

          <div class="col-md-6">
            <SalesByCategory />
          </div>
          <div class="col-md-6">
            <StaffGenderChart />
          </div>
        </div>

        <div class="vld-parent">
          <loading
            :active.sync="isLoading"
            loader="spinner"
            :can-cancel="true"
            :is-full-page="fullPage"
          ></loading>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Header from "@/views/Header.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import PharmacyDashboardCards from "./PharmacyDashboardCards.vue";
import StaffGenderChart from "./charts/StaffGenderChart.vue";
import SalesByCategory from "./charts/SalesByCategory.vue";
import SalesProductsTotal from "@/views/pharmacies/charts/SalesProductsTotal.vue";
import LowInStockPharmacy from "./charts/LowInStockPharmacy.vue";
// Init plugin
// import { reloadPage } from "@/functions/Settings";

export default {
  components: {
    Header,
    Loading,
    StaffGenderChart,
    SalesByCategory,
    SalesProductsTotal,
    LowInStockPharmacy, PharmacyDashboardCards
  },
  props:['metrics'],
  data() {
    return {
      user: null,
      isLoading: false,
      fullPage: true,
      showForm: false,
      edit: false,
    };
  },
  beforeCreate() {
    //  reloadPage();
  },
  beforeMount() {},
  methods: {
    getInventories() {
      this.isLoading = true;
      this.axios
        .get(`/inventory`)
        .then((response) => {
          this.inventories = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
  },
  created() {},
};
</script>
  