<template>
  <div>
    <Header />
    <div class="content-body">
      <div class="container-fluid">
        <div class="page-titles">
          <h4>{{ department.name }} Department</h4>

          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link to="/manage-departments">Departments</router-link>
            </li>
            <li class="breadcrumb-item active">
              <a href="javascript:void(0)"> DT000{{ department.id }}</a>
            </li>
          </ol>

        </div>

        <div class="row">
          <div class="col-xl-4 col-sm-6">
            <div class="card bg-success text-white">
              <div class="card-body">
                <div class="media align-items-center">
                  <div class="media-body mr-3">
                    <h2 class="fs-34 text-white font-w600">
                      {{ department.cases.length }}
                    </h2>
                    <span>Cases</span>
                  </div>
                  <i class="las la-file-medical la-3x success"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-sm-6">
            <div class="card">
              <div class="card-body">
                <div class="media align-items-center">
                  <div class="media-body mr-3">
                    <h2 class="fs-34 text-black font-w600">
                      {{ department.staffs.length }}
                    </h2>
                    <span>Staffs</span>
                  </div>
                  <i class="las la-user-tie la-3x"></i>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-sm-6">
            <div class="card bg-dark text-white">
              <div class="card-body">
                <div class="media align-items-center">
                  <div class="media-body mr-3">
                    <h2 class="fs-34 text-white font-w600">
                      {{ department.categories.length }}
                    </h2>
                    <span>Categories</span>
                  </div>
                  <i class="las la-folder la-3x"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 mb-4">
            <div class="col-md-3 float-right">
              <input
                type="text"
                v-on:keyup.enter="searchHere"
                v-model="search"
                class="form-control input-rounded"
                placeholder="Case Number..."
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="profile card card-body px-3 pt-3 pb-0">
              <div class="profile-head">
                <div class="profile-info">
                  <div class="profile-details">
                    <div class="profile-name px-3 pt-2">
                      <p>
                        Manage {{ department.name }} here, Add Cases,
                        Categories, etc.
                      </p>
                    </div>

                    <div class="dropdown ml-auto">
                      <a
                        href="#"
                        class="btn btn-success light sharp"
                        data-toggle="dropdown"
                        aria-expanded="true"
                      >
                        <li class="las la-ellipsis-h scale5"></li>
                      </a>
                      <ul class="dropdown-menu dropdown-menu-right">
                        <li class="dropdown-item">
                          <a
                            href="javascript:void(0)"
                            class="text-success"
                            v-b-toggle.sidebar-category
                          >
                            <i class="lab la-buffer mr-2"></i>
                            Add Categories
                          </a>
                        </li>

                        <li class="dropdown-item">
                          <a
                            href="javascript:void(0)"
                            class="text-success"
                            v-b-toggle.sidebar-case
                          >
                            <i class="las la-file-medical mr-2"></i>
                            Add Case
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <div class="profile-statistics mb-5">
                  <div class="text-center">
                    <div class="row">
                      <p class="h5">Department Case Categories</p>
                      <p
                        class="text-left"
                        v-for="cat in department.categories"
                        :key="cat.id"
                      >
                        <span class="mr-2">{{ cat.name }}</span>

                        <a
                          href="javascript:void()"
                          class="mr-1"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Edit"
                          @click="startUpdate(cat)"
                          ><i class="fa fa-pencil color-muted text-success"></i>
                        </a>
                        <!-- <a
                          href="javascript:void()"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Close"
                          @click="deleteDept(cat)"
                          ><i class="fa fa-close color-danger text-success"></i
                        ></a> -->
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="card">
              <div class="card-header">
                <p class="h5">Department Cases</p>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-responsive-md">
                    <thead>
                      <tr>
                        <th class="width80"><strong>SN</strong></th>
                        <th><strong>Case Number</strong></th>
                        <th><strong>Patient</strong></th>
                        <th><strong>Staff</strong></th>
                        <th><strong>Date Created</strong></th>
                        <th><strong>Action</strong></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(caser, index) in department.cases"
                        v-bind:key="caser.id"
                      >
                        <td>
                          <strong> {{ index + 1 }} </strong>
                        </td>
                        <td>{{ caser.case_number }}</td>
                        <td>
                          {{ caser.patient.first_name }}
                          {{ caser.patient.last_name }}
                        </td>
                        <td>
                          <span v-if="caser.staff != null">
                            {{ caser.staff.first_name }}
                            {{ caser.staff.last_name }}
                          </span>
                        </td>
                        <td>{{ caser.created_at | moment("from", "now") }}</td>
                        <td>
                          <span>
                            <a
                              href="javascript:void()"
                              class="mr-4"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Edit"
                              @click="editFacility(caser)"
                              ><i
                                class="fa fa-eye color-muted text-success"
                              ></i>
                            </a>
                            <a
                              href="javascript:void()"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Close"
                              @click="deleteFacility(caser)"
                              ><i
                                class="las la-trash color-danger text-success"
                              ></i
                            ></a>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <b-sidebar
          id="sidebar-category"
          title="Case Report Category"
          right
          shadow
          width="630px"
        >
          <div class="px-3 py-2">
            <AddCategory />
          </div>
        </b-sidebar>

        <b-sidebar
          id="sidebar-case"
          title="Add Case"
          right
          shadow
          width="700px"
        >
          <div class="px-3 py-2">
            <AddCase ref="AddCase" :categories="department.categories" />
          </div>
        </b-sidebar>
      </div>
    </div>
  </div>
</template>

<script>

// import { reloadPage } from "@/functions/Settings";
import Header from "@/views/Header.vue";
import AddCategory from "@/views/departments/AddCategory.vue";
import AddCase from "@/views/departments/AddCase.vue";
import { DeptService } from "@/jsstore/depts";
import { CaseService } from "@/jsstore/cases";

export default {
  components: {
    Header,
    AddCategory,
    AddCase,
  },

  data() {
    return {
      jsstore: new DeptService(),
      case_service: new CaseService(),
      department: "",
      search: "",
      categories: "",
      cases: "",
      staffs: "",
    };
  },
  beforeCreate() {
    // reloadPage();
  },
  beforeMount() {},
  methods: {
    getDept() {
      if (this.$store.state.mode == false) {
        this.getDeptOffline();
      } else {
        this.getDeptOnline();
      }
    },

    async getDeptOffline() {
      try {
        let query = await this.jsstore.getDeptId(this.$route.params.id);

        let filtered = query.filter((x) => x.id == this.$route.params.id);
        this.department = filtered[0];
      } catch (ex) {
        this.$toastr.e(`${ex.message}`, "Error!");
      }
    },
    getDeptOnline() {
      this.isLoading = false;
      this.axios
        .get(`/staff/departments/` + this.$route.params.id)
        .then((response) => {
          this.department = response.data;
          console.log(response);
          this.isLoading = false;
          this.getCategories();
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },

    getCases() {
      if (this.$store.state.mode == false) {
        this.getCasesOffline();
      } else {
        this.getCasesOnline();
      }
    },

    getCasesOnline() {
      this.axios
        .get(`/staff/case-reports`)
        .then((response) => {
          this.cases = response.data.data;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async getCasesOffline() {
      let query = await this.case_service.getCases();
      this.cases = {
        data: query,
      };
    },
  },
  created() {
    this.getDept();
    this.getCases();
  },
};
</script>
