<template>
  <div>
    <!-- <div class="content-body"> -->
    <!-- <div class="container-fluid"> -->

    <div class="col-md-10">
      <div class="card">
        <div class="settings-form">
          <div class="card-header">
            <h4 class="text-success">Add Department</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Department Name</label>
                  <input
                    type="text"
                    placeholder="Dept. Name"
                    class="form-control"
                    v-model="department.name"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Department Description</label>
                  <textarea
                    class="form-control"
                    placeholder="Type your message..."
                    rows="4"
                    v-model="department.description"
                  ></textarea>
                </div>
              </div>

              <button class="btn btn-success btn-block" type="submit">
                Submit <i class="las la-paper-plane"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>

<script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

import { DeptService } from "@/jsstore/depts";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      jsstore: new DeptService(),
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      edit: false,
      departments: "",
      department: {
        name: "",
        facility_id: this.$selected_facility.id,
        description: "",
      },
    };
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    submitForm() {
      if (this.edit == false) {
        if (this.$store.state.mode == true) {
          this.addDept();
        } else {
          this.addDeptOffline();
        }
      } else {
        this.editDept();
      }
    },
    addDept() {
      this.isLoading = true;
      this.axios
        .post("/staff/departments", {
          name: this.department.name,
          facility_id: this.$selected_facility.id,
          description: this.department.description,
        })
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$emit("update");
          this.clearForm();
          this.$toastr.s("Department Created Successfully", "Success!");
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");
          this.isLoading = false;
        });
    },

    async addDeptOffline() {
      try {
        let payload = {
          name: this.department.name,
          description: this.department.description,
          created_at : new Date,
        };
        let query = await this.jsstore.addDept(payload);
        console.log(JSON.stringify(query + payload) + "jsstore");
        this.$toastr.s("Department added Successfully", "Success!");
        this.clearForm();
        this.$emit("update");
      } catch (ex) {
        this.$toastr.e(`${ex.message}`, "Error!");
      }
    },

    editDept() {
      this.isLoading = true;
      this.axios
        .patch(`/staff/departments/${this.department.id}`, this.department)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.clearForm();
          this.$emit("update");
          this.$toasted.success("Updated Successfully!", {
            position: "top-center",
            duration: 3000,
          });
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.$toastr.e("not successful", "Error!");
        });
    },
    setValue(value) {
      this.edit = true;
      this.department = value;
    },
    clearForm() {
      this.department.id = "";
      this.department.name = "";
      this.department.description = "";
      this.edit = false;
    },
  },
  created() {},
};
</script>
