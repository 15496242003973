<template>
    <div>
      <!-- <div class="content-body"> -->
      <!-- <div class="container-fluid"> -->
  
      <div class="col-md-12">
        <div class="card">
          <div class="settings-form">
            
            <div class="card-body">
              <form @submit.prevent="submitForm">
                <div class="form-row">

                    <div class="form-group col-md-12">
                  <label for="inputPassword4">Family Relationship</label>
                  <v-select
                    v-model="form_data.relationship_type"
                    class="form-control"
                    :options="relationships"
                  />
                </div>
                
  
                  <div class="form-group col-md-12">
                    <label>Description</label>
                    <textarea
                      class="form-control"
                      placeholder="There is a history of ..."
                      rows="8"
                      v-model="form_data.description"
                    ></textarea>
                  </div>
                </div>
  
                <div class="form-row"></div>
  
                <button class="btn btn-success btn-block" type="submit">
                  Submit <i class="las la-paper-plane"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
  
      <div class="vld-parent">
        <loading
          :active.sync="isLoading"
          loader="spinner"
          :can-cancel="true"
          :is-full-page="fullPage"
        ></loading>
      </div>
    </div>
    <!-- </div> -->
    <!-- </div> -->
  </template>
          
          <script>
  // Import component
  import Loading from "vue-loading-overlay";
  // Import stylesheet
  import "vue-loading-overlay/dist/vue-loading.css";  
  export default {
    components: {
      Loading,
    },
    data() {
      return {
        relationships: ["Father", "Mother", "Sibling", "Grand Father", "Grand Mother"],
        isLoading: false,
        fullPage: true,
        edit: false,
        File: "",
        patients: "",
        diseases: "",
        form_data: {
          patient_id: this.$route.params.id,
          description: "",
          family_relationship: "",
          relationship_type: "",
        },
      };
    },
    beforeMount() {},
    computed: {},
    methods: {
      submitForm() {
        if (this.edit == false) {
          this.addForm();
        } else {
          this.updateForm();
        }
      },
      addForm() {
        this.isLoading = true;
        this.axios
          .post("/staff/family-medical-histories", {
          patient_id: this.$route.params.id,
          description: this.form_data.description,
          family_relationship: this.form_data.relationship_type,
          relationship_type: this.form_data.relationship_type,
        })
          .then((response) => {
            console.log(response);
            this.isLoading = false;
            this.$toastr.s("Added Successfully", "Success!");
            this.clearForm();
          })
          .catch((error) => {
            console.log(error.response);
            this.$toastr.e("Error on Adding!");
            this.isLoading = false;
          });
      },
      updateForm() {
        //
      },
  
      clearForm() {
        this.form_data.description = "";
        this.form_data.relationship_type = "";
      },
    },
    created() {
    },
  };
  </script>
          