<template>
  <div>
    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header">
                    <h4 class="card-title pt-4 pb-4">{{ kins.total }} Kins</h4>

                    <button v-b-toggle.sidebar-kin class="btn btn-success mr-2">
                      <i class="las la-plus"></i> Add Next of Kin
                    </button>
                  </div>
                  <div class="card-body">
                    <vue-good-table
                      :columns="columns"
                      :rows="kins.data"
                      :search-options="{ enabled: true }"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
        
        <script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Loading,
  },
  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      columns: [
        {
          label: "Full Name",
          field: "full_name",
        },
        {
          label: "Relationship",
          field: "relationship_type",
          type: "text",
        },
        {
          label: "Blood Group",
          field: "blood_group",
          type: "text",
        },
        {
          label: "Phone Number",
          field: "phone_no",
          type: "text",
        },
      ],
      kins: "",
    };
  },
  beforeMount() {},
  methods: {
    getKins() {
      this.isLoading = true;

      this.axios
        .get(`/staff/patient-kins/${this.$route.params.id}`)
        .then((response) => {
          this.kins = response.data;
          this.isLoading = false;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.getKins();
  },
};
</script>
        