<template>
  <div class="">  

    <div class="row">
      <div class="col-xl-4 col-sm-6">
        <div class="card">
          <div class="card-body">
            <div class="media align-items-center">
              <div class="media-body mr-3">
                <h2 class="fs-34 text-black font-w600">{{ metrics.patients }} </h2>
                <span>Total Patients</span>
              </div>
              <i class="las la-user-injured la-3x"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-sm-6">
        <div class="card bg-success text-white">
          <div class="card-body">
            <div class="media align-items-center">
              <div class="media-body mr-3">
                <h2 class="fs-34 text-white font-w600">{{ metrics.hospitalizations }} </h2>
                <span>Admissions</span>
              </div>
              <i class="las la-calendar la-3x success"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-sm-6">
        <div class="card bg-dark text-white">
          <div class="card-body">
            <div class="media align-items-center">
              <div class="media-body mr-3">
                <h2 class="fs-34 text-white font-w600">20</h2>
                <span>Patient Records</span>
              </div>
              <i class="las la-notes-medical la-3x"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6">
        <div class="row">
          <div class="col-xl-12">
            <PatientPercentage />
          </div>

        </div>
      </div>
      <div class="col-xl-6">
        <div class="row">
          <div class="col-xl-12">
            <AdmissionsbyWards />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <InOutPatientTrend />
        </div>

        <div class="col-md-6">
          <PatientsbyDepartments />
        </div>
      </div>
  
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import PatientPercentage from "./PatientPercentage.vue";
import AdmissionsbyWards from "./AdmissionsbyWards.vue";
import InOutPatientTrend from "./InOutPatientTrend.vue";
import PatientsbyDepartments from "./PatientsbyDepartments.vue";

export default {
  components: {
    Loading,
    AdmissionsbyWards,
    InOutPatientTrend,
    PatientsbyDepartments,
    PatientPercentage,
  },
  props:['metrics'],

  data() {
    return {
      user: null,
      checked: false,
      isLoading: false,
      fullPage: true,
      selected_facility: null,
      auth_user: "",
    };
  },
  beforeMount() {
  
  },

  methods: {},
  created() {},
};
</script>
