<template>
  <div>
    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <div class="card-header">
            <h6 class="text-success">Create inventories</h6>
          </div>
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Name of Inventory</label>
                  <input
                    type="text"
                    placeholder="Surgical Gloves, Syringe"
                    class="form-control"
                    v-model="inventory.name"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label> Select category</label>
                  <v-select
                    v-model="inventory.category_id"
                    :reduce="(name) => name.id"
                    label="name"
                    :options="categories.data"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Minimum Reorder Level</label>
                  <input
                    type="number"
                    placeholder="30"
                    class="form-control"
                    v-model="inventory.minimum_required"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label> Description</label>
                  <textarea
                    class="form-control"
                    placeholder="Type your message..."
                    rows="4"
                    v-model="inventory.description"
                  ></textarea>
                </div>
              </div>

              <button class="btn btn-success btn-block" type="submit">
                Submit <i class="las la-paper-plane"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>

<script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Loading,
  },
  data() {
    return {
      user: null,
      isLoading: false,
      fullPage: true,
      edit: false,
      categories: "",
      inventory: {
        facility_id: this.$selected_facility.id,
        staff_id: this.$user.id,
        name: "",
        description: "",
        category_id: "",
        minimum_required: "",
      },
    };
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addInventory();
      } else {
        this.updateInventory();
      }
    },
    addInventory() {
      this.isLoading = true;
      this.axios
        .post("/staff/products", this.inventory)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toastr.s("Inventory Created Successfully", "Success!");
          this.clearForm();
          this.$emit("update");
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");
          this.isLoading = false;
        });
    },
    updateInventory() {
      this.isLoading = true;
      this.axios
        .patch(`/staff/products/${this.inventory.id}`, this.inventory)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.clearForm();
          this.$emit("update");
          this.$toastr.s("Updated Successfully", "Error!");

        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.$toastr.e("not successful", "Error!");
        });
    },

    getCategories() {
      this.isLoading = false;
      this.axios
        .get(`/staff/categories`)
        .then((response) => {
          this.categories = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    setValue(value) {
      this.edit = true;
      this.inventory = value;
      console.log(value);
    },
    clearForm() {
      this.inventory.id = "";
      this.inventory.name = "";
      this.inventory.category_id = "";
      this.inventory.description = "";
      this.inventory.minimum_required = "";
      this.edit = false;
    },

   
  },
  created() {
    this.getCategories();
  },
};
</script>
