<template>
  <div>
    <Header />
    <div class="content-body">
      <div class="col-md-8 offset-md-2">
        <div class="card">
          <div class="card-header border-0 pb-0">
            <h5 class="card-title">Add Facility</h5>
          </div>
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-group">
                <label class="text-black font-w500">Facility Name</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="facility.Title"
                />
              </div>
              <div class="form-group">
                <label class="text-black font-w500">Address </label>
                <input
                  type="text"
                  class="form-control"
                  v-model="facility.Address"
                />
              </div>

              <div class="form-group">
                <button type="submit" class="btn btn-success btn-block">
                  Submit <i class="las la-paper-plane"></i>
                </button>
              </div>
            </form>
            <LoaderComponent v-show="isLoading" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-8 offset-md-2">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">My Facilities</h4>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-responsive-md">
                  <thead>
                    <tr>
                      <th class="width80"><strong>SN</strong></th>
                      <th><strong>Facility Name</strong></th>
                      <th><strong>Added</strong></th>

                      <th><strong>Actions</strong></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(facility, index) in facilities.data"
                      v-bind:key="facility.id"
                    >
                      <td>
                        <strong> {{ index + 1 }} </strong>
                      </td>
                      <td>{{ facility.title }}</td>
                      <td>{{ facility.created_at | moment("from", "now")  }}</td>
                      <td>
                        <span>
                          <button class="btn btn-outline-success btn-sm mr-2"
                          @click="selectFacility(facility)"
                          >
                            select facility
                          </button>
                          <a
                            href="javascript:void()"
                            class="mr-4"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Edit"
                            @click="editFacility(facility)"
                            ><i
                              class="fa fa-pencil color-muted text-success"
                            ></i>
                          </a>
                          <a
                            href="javascript:void()"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Close"
                            @click="deleteFacility(facility)"
                            ><i
                              class="fa fa-close color-danger text-success"
                            ></i
                          ></a>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/views/Header.vue";
import LoaderComponent from "@/views/LoaderComponent.vue";
// import { reloadPage } from "@/functions/Settings";

export default {
  components: {
    LoaderComponent,
    Header,
  },
  data() {
    return {
      facility: {
        Title: "",
        Address: "",
      },
      edit: false,
      singleFacility_id: "",
      response: "",
      isLoading: false,
      facilities: "",
      user: null,
    };
  },
  beforeCreate() {
    // reloadPage();
  },
  beforeMount() {
  },
 
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addFacility();
      } else {
        this.updateFacility();
      }
    },

    selectFacility(facility) {
      this.isLoading = true;
      this.axios
        .post(`/admin/facility/change`, {
          id: facility.id,
        })
        .then((response) => {
          localStorage.setItem("selected_facility", JSON.stringify(facility));
          this.$toasted.success(`Switching to ${facility.title} Facility`, {
            position: "top-center",
            duration: 7000,
          });

          console.log(response);
          // this.$router.push("/admin-dashboard");
          window.location.href = `/admin-dashboard`;

          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    addFacility() {
      this.isLoading = true;
      this.axios
        .post("/admin/facility", {
          title: this.facility.Title,
          name: this.facility.Address,
          description: this.facility.Address,
          address: "my address",
        })

        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toastr.s("Facility Created Successfully", "Success!");
          let facility = response.data.data;
          this.assignFacility(facility);
          // this.getFacilities();
        })
        .catch((error) => {
          console.log(error.response);
          this.isLoading = false;
        });
    },
    assignFacility(facility) {
      this.axios
        .post(`/admin/facility/assign/${this.$user.id}`, {
          facility_id: facility.id,
        })

        .then((response) => {
          console.log(response);
          this.getFacilities();
        })
        .catch((error) => {
          console.log(error.response);
          this.isLoading = false;
        });
    },
    updateFacility() {
      this.isLoading = true;
      this.axios
        .put(`/facility/${this.singleFacility_id}`, {
          Title: this.facility.Title,
          Address: this.facility.Address,
          Icon: "https://image.com/sample.png",
        })

        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toastr.s("Updated Facility Successfully", "Success!");
          this.getFacilities();
        })
        .catch((error) => {
          console.log(error.response);
          this.isLoading = false;
        });
    },
    deleteFacility(facility) {
      if (confirm("Are you Sure you want to delete?")) {
        this.isLoading = true;
        this.axios
          .patch(`/admin/facility/${facility.Id}`)

          .then((response) => {
            console.log(response);
            this.isLoading = false;
            this.$toastr.s("Deleted Facility Successfully", "Success!");
            this.getFacilities();
          })
          .catch((error) => {
            console.log(error.response);
            this.isLoading = false;
          });
      }
    },
    getFacilities() {
      this.axios
        .get(`/admin/facility`)
        .then((response) => {
          this.facilities = response.data.data;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    editFacility(facility) {
      this.edit = true;
      this.singleFacility_id = facility.Id;
      this.facility.Title = facility.Title;
      this.facility.Address = facility.Address;
    },
  },
  created() {
    this.getFacilities();
  },
};
</script>
