<template>
  <div>
    <Header />
    <div class="content-body">
      <div class="container-fluid">
        <div class="page-titles">
          <h4>{{ inventory.Title }}</h4>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="javascript:void(0)">App</a>
            </li>
            <li class="breadcrumb-item active">
              <a href="javascript:void(0)">{{ inventory.Title }}</a>
            </li>
          </ol>
        </div>
        <!-- row -->
        <div class="row">
          <div class="col-lg-12">
            <div class="profile card card-body px-3 pt-3 pb-0">
              <div class="profile-head">
                <!-- <div class="photo-content">
                  <div class="cover-photo"></div>
                </div> -->
                <div class="profile-info">
                  <!-- <div class="profile-photo">
                    <img
                      src="images/profile/profile.png"
                      class="img-fluid rounded-circle"
                      alt=""
                    />
                  </div> -->
                  <div class="profile-details">
                    <div class="profile-name px-3 pt-2">
                      <h4 class="text-primary mb-0">{{ inventory.Title }}</h4>
                      <!-- <p>{{inventory}} </p> -->
                    </div>

                    <div class="dropdown ml-auto">
                      <a
                        href="#"
                        class="btn btn-success light sharp"
                        data-toggle="dropdown"
                        aria-expanded="true"
                      >
                        <li class="las la-ellipsis-h scale5"></li>
                      </a>
                      <ul class="dropdown-menu dropdown-menu-right">
                        <li class="dropdown-item" v-b-toggle.sidebar-category>
                          <i
                            class="las la-prescription-bottle text-success mr-2"
                            x
                          ></i>
                          Add Categories
                        </li>

                        <li class="dropdown-item" v-b-toggle.sidebar-item>
                          <i class="las la-tablets text-success mr-2" x></i>
                          Add Items
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <b-sidebar
          id="sidebar-category"
          title="Add Category"
          width="600px"
          right
          shadow
        >
          <div class="px-3 py-2">
            <AddInventoryCategory />
          </div>
        </b-sidebar>

        <b-sidebar
          id="sidebar-item"
          title="Add Item"
          width="600px"
          right
          shadow
        >
          <div class="px-3 py-2">
            <AddInventoryStock />
          </div>
        </b-sidebar>

        <div class="row">
          <div class="col-xl-4">
            <div class="card">
              <div class="card-body">
                <div class="profile-statistics mb-5">
                  <div class="text-center">
                    <div class="row">
                      <p>{{ inventory.Title }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-8">
            <div class="card">
              <div class="card-header">
                <p class="h5">Items Under this Inventory</p>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-responsive-md">
                    <thead>
                      <tr>
                        <th class="width80"><strong>SN</strong></th>
                        <th><strong>Name</strong></th>
                        <th><strong>Quantity</strong></th>
                        <th><strong>Amount</strong></th>
                        <th><strong>Date Created</strong></th>
                        <th><strong>Action</strong></th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- <tr
                      v-for="(facility, index) in facilities.data"
                      v-bind:key="facility.id"
                    >
                      <td>
                        <strong> {{ index + 1 }} </strong>
                      </td>
                      <td>{{ facility.Title }}</td>
                      <td>{{ facility.Created }}</td>
                      <td>
                        <span>
                          <a
                            href="javascript:void()"
                            class="mr-4"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Edit"
                            @click="editFacility(facility)"
                            ><i class="fa fa-pencil color-muted"></i>
                          </a>
                          <a
                            href="javascript:void()"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Close"
                            @click="deleteFacility(facility)"
                            ><i class="fa fa-close color-danger"></i
                          ></a>
                        </span>
                      </td>
                    </tr> -->
                      <tr v-for="(item, index) in items" v-bind:key="item.id">
                        <td>{{ index + 1 }}</td>
                        <td>{{ item.Title }}</td>
                        <td>{{ item.Quantity }}</td>
                        <td>
                          <i class="mdi mdi-currency-ngn"></i>
                          {{ item.Amount | numeral(0, 0) }}
                        </td>
                        <td>{{ item.Created | moment("from", "now") }}</td>
                        <td>
                          <div class="dropdown ml-auto">
                            <a
                              href="#"
                              class="btn btn-success light sharp"
                              data-toggle="dropdown"
                              aria-expanded="true"
                            >
                              <li class="las la-ellipsis-h scale5"></li>
                            </a>
                            <ul class="dropdown-menu dropdown-menu-right">
                              <li class="dropdown-item" v-b-toggle.sidebar-item>
                                <a
                                  href="javascript:void()"
                                  class="mr-4"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Edit"
                                  @click="editInventory(item)"
                                  ><i
                                    class="
                                      fa fa-pencil
                                      color-muted
                                      text-success
                                    "
                                  ></i>
                                </a>
                                Edit
                              </li>

                              <li class="dropdown-item">
                                <a
                                  href="javascript:void()"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Close"
                                  @click="deleteInventory(item)"
                                  ><i
                                    class="
                                      fa fa-close
                                      color-danger
                                      text-success
                                    "
                                  ></i
                                ></a>
                                Delete
                              </li>
                            </ul>
                          </div>
                          <span> </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/views/Header.vue";
import AddInventoryCategory from "@/views/inventories/AddInventoryCategory.vue";
import AddInventoryStock from "@/views/inventories/AddInventoryStock.vue";

export default {
  components: {
    Header,
    AddInventoryCategory,
    AddInventoryStock,
  },

  data() {
    return {
      inventory: "",
      categories: "",
      items: "",
    };
  },
  beforeMount() {
    this.isLoading = true;
    this.axios
      .get(`/inventory/${this.$route.params.Id}`)
      .then((response) => {
        this.inventory = response.data.data;
        console.log(response);
        this.isLoading = false;
      })
      .catch((error) => {
        console.error(error);
        this.isLoading = false;
      });
  },
  methods: {
    getStocks() {
      this.isLoading = false;
      this.axios
        .get(`/item`)
        .then((response) => {
          this.items = response.data.data;
          this.$toastr.s("Stocks fetched Successfully", "Success!");
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.getStocks();
  },
};
</script>
