<template>
  <div class="chatbox">
    <div class="chatbox-close"></div>
    <div class="custom-tab-1">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#notes">Notes</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#alerts"
            >Waiting List</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#chat">Chat</a>
        </li>
      </ul>
      <div class="tab-content">
        <div class="tab-pane fade" id="chat" role="tabpanel">
          <div class="card mb-sm-3 mb-md-0 contacts_card dz-chat-user-box">
            <div class="card-header chat-list-header text-center">
              <a href="javascript:void(0)"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="18px"
                  height="18px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <rect
                      fill="#000000"
                      x="4"
                      y="11"
                      width="16"
                      height="2"
                      rx="1"
                    />
                    <rect
                      fill="#000000"
                      opacity="0.3"
                      transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "
                      x="4"
                      y="11"
                      width="16"
                      height="2"
                      rx="1"
                    />
                  </g>
                </svg>
              </a>
              <div>
                <h6 class="mb-1">Chat List</h6>
                <p class="mb-0">Show All</p>
              </div>
              <a href="javascript:void(0)"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="18px"
                  height="18px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <rect x="0" y="0" width="24" height="24" />
                    <circle fill="#000000" cx="5" cy="12" r="2" />
                    <circle fill="#000000" cx="12" cy="12" r="2" />
                    <circle fill="#000000" cx="19" cy="12" r="2" />
                  </g>
                </svg>
              </a>
            </div>
            <div
              class="card-body contacts_body p-0 dz-scroll"
              id="DZ_W_Contacts_Body"
            >
              <ul class="contacts">
                <!-- <li class="name-first-letter">Chats</li> -->
                <!-- <li class="active dz-chat-user">
                  <div class="d-flex bd-highlight">
                    <div class="img_cont">
                      <img
                        src="images/avatar/1.jpg"
                        class="rounded-circle user_img"
                        alt=""
                      />
                      <span class="online_icon"></span>
                    </div>
                    <div class="user_info">
                      <span>Archie Parker</span>
                      <p>Kalid is online</p>
                    </div>
                  </div>
                </li>
                -->
              </ul>
            </div>
          </div>
          <div class="card chat dz-chat-history-box d-none">
            <div class="card-header chat-list-header text-center">
              <a href="javascript:void(0)" class="dz-chat-history-back">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="18px"
                  height="18px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <polygon points="0 0 24 0 24 24 0 24" />
                    <rect
                      fill="#000000"
                      opacity="0.3"
                      transform="translate(15.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-15.000000, -12.000000) "
                      x="14"
                      y="7"
                      width="2"
                      height="10"
                      rx="1"
                    />
                    <path
                      d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997) "
                    />
                  </g>
                </svg>
              </a>
              <div>
                <h6 class="mb-1">Chat with Khelesh</h6>
                <p class="mb-0 text-success">Online</p>
              </div>
              <div class="dropdown">
                <a href="javascript:void(0)" data-toggle="dropdown"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="18px"
                    height="18px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <circle fill="#000000" cx="5" cy="12" r="2" />
                      <circle fill="#000000" cx="12" cy="12" r="2" />
                      <circle fill="#000000" cx="19" cy="12" r="2" />
                    </g>
                  </svg>
                </a>

                <ul class="dropdown-menu dropdown-menu-right">
                  <li class="dropdown-item">
                    <i class="fa fa-user-circle text-primary mr-2"></i> View
                    profile
                  </li>
                  <li class="dropdown-item">
                    <i class="fa fa-users text-primary mr-2"></i> Add to close
                    friends
                  </li>
                  <li class="dropdown-item">
                    <i class="fa fa-plus text-primary mr-2"></i> Add to group
                  </li>
                  <li class="dropdown-item">
                    <i class="fa fa-ban text-primary mr-2"></i> Block
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="card-body msg_card_body dz-scroll"
              id="DZ_W_Contacts_Body3"
            >
              <div class="d-flex justify-content-start mb-4">
                <div class="img_cont_msg">
                  <img
                    src="images/avatar/1.jpg"
                    class="rounded-circle user_img_msg"
                    alt=""
                  />
                </div>
                <div class="msg_cotainer">
                  Hi, how are you samim?
                  <span class="msg_time">8:40 AM, Today</span>
                </div>
              </div>
              <div class="d-flex justify-content-end mb-4">
                <div class="msg_cotainer_send">
                  Hi Khalid i am good tnx how about you?
                  <span class="msg_time_send">8:55 AM, Today</span>
                </div>
                <div class="img_cont_msg">
                  <img
                    src="images/avatar/2.jpg"
                    class="rounded-circle user_img_msg"
                    alt=""
                  />
                </div>
              </div>
              <div class="d-flex justify-content-start mb-4">
                <div class="img_cont_msg">
                  <img
                    src="images/avatar/1.jpg"
                    class="rounded-circle user_img_msg"
                    alt=""
                  />
                </div>
                <div class="msg_cotainer">
                  I am good too, thank you for your chat template
                  <span class="msg_time">9:00 AM, Today</span>
                </div>
              </div>
              <div class="d-flex justify-content-end mb-4">
                <div class="msg_cotainer_send">
                  You are welcome
                  <span class="msg_time_send">9:05 AM, Today</span>
                </div>
                <div class="img_cont_msg">
                  <img
                    src="images/avatar/2.jpg"
                    class="rounded-circle user_img_msg"
                    alt=""
                  />
                </div>
              </div>
              <div class="d-flex justify-content-start mb-4">
                <div class="img_cont_msg">
                  <img
                    src="images/avatar/1.jpg"
                    class="rounded-circle user_img_msg"
                    alt=""
                  />
                </div>
                <div class="msg_cotainer">
                  I am looking for your next templates
                  <span class="msg_time">9:07 AM, Today</span>
                </div>
              </div>
              <div class="d-flex justify-content-end mb-4">
                <div class="msg_cotainer_send">
                  Ok, thank you have a good day
                  <span class="msg_time_send">9:10 AM, Today</span>
                </div>
                <div class="img_cont_msg">
                  <img
                    src="images/avatar/2.jpg"
                    class="rounded-circle user_img_msg"
                    alt=""
                  />
                </div>
              </div>
              <div class="d-flex justify-content-start mb-4">
                <div class="img_cont_msg">
                  <img
                    src="images/avatar/1.jpg"
                    class="rounded-circle user_img_msg"
                    alt=""
                  />
                </div>
                <div class="msg_cotainer">
                  Bye, see you
                  <span class="msg_time">9:12 AM, Today</span>
                </div>
              </div>
              <div class="d-flex justify-content-start mb-4">
                <div class="img_cont_msg">
                  <img
                    src="images/avatar/1.jpg"
                    class="rounded-circle user_img_msg"
                    alt=""
                  />
                </div>
                <div class="msg_cotainer">
                  Hi, how are you samim?
                  <span class="msg_time">8:40 AM, Today</span>
                </div>
              </div>
              <div class="d-flex justify-content-end mb-4">
                <div class="msg_cotainer_send">
                  Hi Khalid i am good tnx how about you?
                  <span class="msg_time_send">8:55 AM, Today</span>
                </div>
                <div class="img_cont_msg">
                  <img
                    src="images/avatar/2.jpg"
                    class="rounded-circle user_img_msg"
                    alt=""
                  />
                </div>
              </div>
              <div class="d-flex justify-content-start mb-4">
                <div class="img_cont_msg">
                  <img
                    src="images/avatar/1.jpg"
                    class="rounded-circle user_img_msg"
                    alt=""
                  />
                </div>
                <div class="msg_cotainer">
                  I am good too, thank you for your chat template
                  <span class="msg_time">9:00 AM, Today</span>
                </div>
              </div>
              <div class="d-flex justify-content-end mb-4">
                <div class="msg_cotainer_send">
                  You are welcome
                  <span class="msg_time_send">9:05 AM, Today</span>
                </div>
                <div class="img_cont_msg">
                  <img
                    src="images/avatar/2.jpg"
                    class="rounded-circle user_img_msg"
                    alt=""
                  />
                </div>
              </div>
              <div class="d-flex justify-content-start mb-4">
                <div class="img_cont_msg">
                  <img
                    src="images/avatar/1.jpg"
                    class="rounded-circle user_img_msg"
                    alt=""
                  />
                </div>
                <div class="msg_cotainer">
                  I am looking for your next templates
                  <span class="msg_time">9:07 AM, Today</span>
                </div>
              </div>
              <div class="d-flex justify-content-end mb-4">
                <div class="msg_cotainer_send">
                  Ok, thank you have a good day
                  <span class="msg_time_send">9:10 AM, Today</span>
                </div>
                <div class="img_cont_msg">
                  <img
                    src="images/avatar/2.jpg"
                    class="rounded-circle user_img_msg"
                    alt=""
                  />
                </div>
              </div>
              <div class="d-flex justify-content-start mb-4">
                <div class="img_cont_msg">
                  <img
                    src="images/avatar/1.jpg"
                    class="rounded-circle user_img_msg"
                    alt=""
                  />
                </div>
                <div class="msg_cotainer">
                  Bye, see you
                  <span class="msg_time">9:12 AM, Today</span>
                </div>
              </div>
            </div>
            <div class="card-footer type_msg">
              <div class="input-group">
                <textarea
                  class="form-control"
                  placeholder="Type your message..."
                ></textarea>
                <div class="input-group-append">
                  <button type="button" class="btn btn-primary">
                    <i class="fa fa-location-arrow"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade active show" id="alerts" role="tabpanel">
          <div class="card mb-sm-3 mb-md-0 contacts_card">
            <div class="card-header chat-list-header text-center">
              <a href="javascript:void(0)"> </a>
              <div>
                <h6 class="mb-1">{{ waitings.length }} Patients waiting</h6>
                <!-- <p class="mb-0">Show All</p> -->
              </div>
              <a href="javascript:void(0)"> </a>
            </div>
            <div
              class="card-body contacts_body p-0 dz-scroll"
              id="DZ_W_Contacts_Body1"
            >
              <ul class="contacts">
                <!-- <li class="name-first-letter">SEVER STATUS</li>
									<li class="active">
										<div class="d-flex bd-highlight">
											<div class="img_cont primary">KK</div>
											<div class="user_info">
												<span>David Nester Birthday</span>
												<p class="text-primary">Today</p>
											</div>
										</div>
									</li> -->
                <!-- <li class="name-first-letter">SOCIAL</li>
									<li>
										<div class="d-flex bd-highlight">
											<div class="img_cont success">RU<i class="icon fa-birthday-cake"></i></div>
											<div class="user_info">
												<span>Perfection Simplified</span>
												<p>Jame Smith commented on your status</p>
											</div>
										</div>
									</li> -->
                <li class="name-first-letter">My Patients</li>
                <li v-if="waitings.length < 1">
                  <div class="d-flex bd-highlight">
                    <!-- <div class="img_cont primary">
                      AU<i class="icon fa fa-user-plus"></i>
                    </div> -->
                    <div class="user_info">
                      <!-- <span>you have no waiting patients</span> -->
                      <p>0 waiting patients</p>
                    </div>
                  </div>
                </li>
                <li v-for="waiting in waitings" :key="waiting.id">
                  <div class="d-flex bd-highlight">
                    <div
                      class="img_cont success"
                      v-if="waiting.patient != null"
                    >
                      {{ waiting.patient.first_name.substring(0, 1) }}
                      {{ waiting.patient.last_name.substring(0, 1) }}
                      <i class="icon fa fa-user-plus"></i>
                    </div>
                    <div class="user_info">
                      <span v-if="waiting.patient != null">
                        {{ waiting.patient.first_name }}
                        {{ waiting.patient.last_name }}
                      </span>
                      <a
                        v-if="waiting.patient.pindexlatest_record == null"
                        :href="`/add-record/${waiting.patient.id}`"
                      >
                        <p class="text-success">
                          {{ waiting.created_at | moment("from", "now") }}
                        </p>
                      </a>
                      
                      <a
                        v-else
                        :href="`/record/${waiting.patient.latest_record.id}`"
                      >
                        <p class="text-success">
                          {{ waiting.action.description }}
                        </p>
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="card-footer"></div>
          </div>
        </div>
        <div class="tab-pane fade" id="notes">
          <div class="card mb-sm-3 mb-md-0 note_card">
            <div class="card-header chat-list-header text-center">
              <a href="javascript:void(0)"> </a>
              <div>
                <h6 class="mb-1">Notes</h6>
                <p class="mb-0">New Note</p>
              </div>
              <a href="javascript:void(0)"> </a>
            </div>
            <div
              class="card-body contacts_body p-0 dz-scroll"
              id="DZ_W_Contacts_Body2"
            >
              <ul class="contacts">
                <!-- <li class="active">
                  <div class="d-flex bd-highlight">
                    <div class="user_info">
                      <span>New order placed..</span>
                      <p>10 Aug 2020</p>
                    </div>
                    <div class="ml-auto">
                      <a
                        href="javascript:void(0)"
                        class="btn btn-primary btn-xs sharp mr-1"
                        ><i class="fa fa-pencil"></i
                      ></a>
                      <a
                        href="javascript:void(0)"
                        class="btn btn-danger btn-xs sharp"
                        ><i class="fa fa-trash"></i
                      ></a>
                    </div>
                  </div>
                </li>
                -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
</template>


<script>
// import SideNavbar from "@/views/SideNavbar.vue";
// import Chatbox from "@/views/Chatbox.vue";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  props: ["waitings", "appointments"],
  components: {
    // SideNavbar,
    // Chatbox,
    Loading,
  },
  data() {
    return {
      facilities: "",
      // waitings: "",
      results: "",
      isLoading: false,
      fullPage: false,
    };
  },
  beforeMount() {
    // this.isLoading = true;
    // this.axios
    //   .get(`/staff/waiting-list/${this.$user.id}/${this.$user.job_title_id}`)
    //   .then((response) => {
    //     this.waitings = response.data;
    //     console.log(response);
    //     this.isLoading = false;
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //     this.isLoading = false;
    //   });
  },
  methods: {
    //
  },
};
</script>
