<template>
  <div>
    <Header />

    <div class="content-body">
      <div class="container-fluid">
        <div class="row mb-3">
          <h4 class="text-black font-w600">Blood Records</h4>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <div class="media align-items-center">
                  <div class="media-body mr-3">
                    <h2 class="fs-34 text-black font-w600">30</h2>
                    <span>Records</span>
                  </div>
                  <i class="las la-file-medical la-2x"></i>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <div class="media align-items-center">
                  <div class="media-body mr-3">
                    <h2 class="fs-34 text-black font-w600">25</h2>
                    <span>Patients Serviced</span>
                  </div>
                  <i class="las la-user-injured la-2x"></i>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <div class="media align-items-center">
                  <div class="media-body mr-3">
                    <h2 class="fs-34 text-black font-w600">5</h2>
                    <span>Pints Issued</span>
                  </div>
                  <i class="las la-tint la-2x"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <BloodGenderChart />
          </div>

          <div class="col-md-6">
            <BloodAgeChart />
          </div>

          <div class="col-md-6">
            <BloodGroupChart />
          </div>

          <div class="col-md-6">
            <BloodGroupDateChart />
          </div>
        </div>

        <div class="row">
          <BloodOutData />
        </div>

        <div class="vld-parent">
          <loading
            :active.sync="isLoading"
            loader="spinner"
            :can-cancel="true"
            :is-full-page="fullPage"
          ></loading>
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
import Header from "@/views/Header.vue";
import BloodOutData from "@/views/bloods/BloodOutData.vue";
import BloodGroupChart from "@/views/emr/charts/BloodGroupChart.vue";
import BloodAgeChart from "@/views/emr/charts/BloodAgeChart.vue";
import BloodGenderChart from "@/views/emr/charts/BloodGenderChart.vue";
import BloodGroupDateChart from "@/views/emr/charts/BloodGroupDateChart.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Header,
    Loading,
    BloodOutData,
    BloodGroupChart,
    BloodAgeChart,
    BloodGenderChart,
    BloodGroupDateChart,
  },
  data() {
    return {
      user: null,
      isLoading: false,
      fullPage: true,
      showForm: true,
      edit: false,
      bloodouts: "",
    };
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addDept();
      } else {
        this.editDept();
      }
    },
    addward() {
      this.isLoading = true;
      this.axios
        .post("/roles", {
          Title: this.role.Title,
          Description: this.role.Description,
        })
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toastr.s("Ward Created Successfully", "Success!");
          this.getRoles();
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");

          this.isLoading = false;
        });
    },
    updateRole(role) {
      this.isLoading = true;
      this.axios
        .put(`/roles`, role)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.getRoles();
          this.isLoading = false;
          this.$toasted.success("Updated Successfully!", {
            position: "top-center",
            duration: 3000,
          });
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    getBloodOuts() {
      this.isLoading = true;

      this.axios
        .get(`/staff/blood-out`)
        .then((response) => {
          this.bloodouts = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    deleteRole(role) {
      if (confirm("Are you Sure you want to delete?")) {
        this.isLoading = true;
        this.axios
          .patch(`/roles/${role.Id}`)
          .then((response) => {
            console.log(response);
            this.isLoading = false;
            this.getRoles();
            this.isLoading = false;
            this.$toasted.success("Deleted Successfully!", {
              position: "top-center",
              duration: 3000,
            });
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
          });
      }
    },
  },
  created() {
    this.getBloodOuts();
  },
};
</script>
    