<template>
  <div>
    <div class="nav-header">
      <a href="/dashboard" class="brand-logo">
        <img class="logo-abbr" src="/images/logos/Sm.png" alt="" />
        <img
          class="logo-compact"
          src="/images/logos/SmartMedicare.png"
          alt=""
        />

        <!-- <img class="brand-title" src="/images/logos/SmartMedicare.png" alt="" /> -->
      </a>

      <div class="nav-control">
        <div class="hamburger">
          <span class="line"></span><span class="line"></span
          ><span class="line"></span>
        </div>
      </div>
    </div>
    <Chatbox :appointments="appointments" :waitings="waitings" />

    <!--**********************************
            Header start
        ***********************************-->
    <div class="header">
      <div class="header-content">
        <nav class="navbar navbar-expand">
          <div class="collapse navbar-collapse justify-content-between">
            <div class="header-left">
              <div class="dashboard_bar">
                <div class="input-group search-area d-lg-inline-flex d-none">
                  <!-- @change="selectPatient" -->
                  <!-- {{ JSON.parse(keyWord) }} -->
                  <!-- <input
                    type="text"
                    class="form-control"
                    v-model="keyWord"
                    @keyup="searchHere"
                    @change="selectPatient"
                    list="patients"
                    placeholder="Search Patient"
                  /> -->

                  <!-- <datalist id="patients">
                    <option
                      v-for="dis in patients.data"
                      :key="dis.id"
                      :value="dis.id"
                    >
                      ({{ dis.card_no }})
                      <span class="h">{{ dis.full_name }}</span> -
                      {{ dis.phone_no }}
                    </option>
                  </datalist> -->
                  <!-- <div class="input-group-append">
                    <span class="input-group-text"
                      ><a href="javascript:void(0)" @click="selectPatient"
                        ><i class="flaticon-381-search-2 text-success"></i></a
                    ></span>
                  </div> -->

                  <a
                    v-if="$selected_facility != null"
                    href="javascript:void()"
                    class="btn btn-success btn-sm px-3 light ml-2"
                  >
                    {{ $selected_facility.title }}
                  </a>
                </div>
              </div>
            </div>

            <ul class="navbar-nav header-right">
              <li class="nav-item dropdown notification_dropdown">
                <a
                  class="nav-link ai-icon"
                  href="javascript:void(0)"
                  role="button"
                  data-toggle="dropdown"
                >
                  <i class="las la-bell la-4x"></i>
                  <span class="badge light text-white bg-success">0</span>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <div
                    id="DZ_W_Notification1"
                    class="widget-media dz-scroll p-3 height380"
                  >
                    <ul class="timeline">
                      <!-- <li>
                        <div class="timeline-panel">
                          <div class="media mr-2">
                            <img
                              alt="image"
                              width="50"
                              src="images/avatar/1.jpg"
                            />
                          </div>
                          <div class="media-body">
                            <h6 class="mb-1">Dr sultads Send you Photo</h6>
                            <small class="d-block"
                              >29 July 2020 - 02:26 PM</small
                            >
                          </div>
                        </div>
                      </li> -->
                    </ul>
                  </div>
                
                </div>
              </li>
              <!-- <li class="nav-item dropdown notification_dropdown">
                <a class="nav-link bell bell-link" href="javascript:void(0)">
                  <i class="las la-business-time la-4x"></i>
                  <span class="badge light text-white bg-success"
                    >{{ waitings.length }}
                  </span>
                </a>
              </li> -->

              <!-- Waitings Notifications -->
              <li class="nav-item dropdown notification_dropdown">
                <a
                  class="nav-link"
                  href="javascript:void(0)"
                  role="button"
                  data-toggle="dropdown"
                >
                  <i class="las la-business-time la-4x"></i>
                  <span class="badge light text-white bg-success"
                    >{{ waitings.length }}
                  </span>
                </a>
                <div class="dropdown-menu dropdown-menu-right p-3">
                  <div
                    id="DZ_W_TimeLine1"
                    class="widget-timeline dz-scroll style-1 height370"
                  >
                    <ul class="timeline">
                      <li v-for="waiting in waitings" :key="waiting.id">
                        <div class="timeline-badge primary"></div>
                        <a class="timeline-panel text-muted">
                          <span>
                            {{
                              waiting.created_at | moment("ddd, Do MM, YYYY")
                            }}
                          </span>
                          <p class="mb-0">
                            <strong v-if="waiting.patient != null">
                              {{ waiting.patient.full_name }}
                            </strong>
                          </p>

                          <p class="text-success">
                            <a
                              v-if="waiting.patient.latest_record == null"
                              :href="`/add-record-${waiting.patient.id}`"
                            >
                              <p class="text-success">
                                {{ waiting.action.description }}
                              </p>
                            </a>
                             

                            <a
                              v-if="waiting.patient.latest_record != null && waiting.action.description != 'Billing' "
                              :href="`/record-${waiting.patient.latest_record.id}`"
                            >
                              <p class="text-success">
                                {{ waiting.action.description }}
                              </p>
                            </a>

                            <a
                              v-if="waiting.patient.latest_record != null && waiting.action.description == 'Billing' "
                              :href="`/recordbilling-${waiting.patient.latest_record.id}`"
                            >
                              <p class="text-success">
                                {{ waiting.action.description }}
                              </p>
                            </a>
                          </p>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>

              <!-- Appointments Notifications -->
              <li class="nav-item dropdown notification_dropdown">
                <a
                  class="nav-link"
                  href="javascript:void(0)"
                  role="button"
                  data-toggle="dropdown"
                >
                  <i class="las la-calendar-week la-4x"></i>
                  <span class="badge light text-white bg-success"
                    >{{ appointments.length }}
                  </span>
                </a>
                <div class="dropdown-menu dropdown-menu-right p-3">
                  <div
                    id="DZ_W_TimeLine1"
                    class="widget-timeline dz-scroll style-1 height370"
                  >
                    <ul class="timeline">
                      <li
                        v-for="appointment in appointments"
                        :key="appointment.id"
                      >
                        <div class="timeline-badge primary"></div>
                        <a
                          class="timeline-panel text-muted"
                          :href="`/appointment-${appointment.id}`"
                        >
                          <span>
                            {{ appointment.date | moment("ddd, Do MM, YYYY") }}
                            - ({{ appointment.time }})</span
                          >
                          <p class="mb-0">
                            <!-- {{ appointment.description }} -->

                            You have appointment with
                            <strong>
                              {{ appointment.patient.full_name }} </strong
                            >. Click to view details.
                          </p>

                          <!-- <strong class="text-primary"> {{ appointment.patient.full_name }} </strong>. -->
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>

              <li class="nav-item dropdown header-profile">
                <a
                  class="nav-link"
                  href="javascript:void(0)"
                  role="button"
                  data-toggle="dropdown"
                >
                  <!-- <div class="header-info">
										<span class="text-black">Hello,<strong>Franklin</strong></span>
										<p class="fs-12 mb-0">Super Admin</p>
									</div> -->
                  <img
                    src="/images/profile/unknown_user.png"
                    width="20"
                    alt=""
                  />
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a href="#" class="dropdown-item ai-icon">
                    <i class="las la-user-tie"></i>
                    <span class="ml-2">Profile </span>
                  </a>

                  <a
                    href="javascript:void()"
                    class="dropdown-item ai-icon"
                    v-for="facility in facilities.data"
                    v-bind:key="facility.Id"
                  >
                    <i class="las la-hospital scale5"></i>
                    <span class="ml-2" @click="selectFacility(facility)">
                      {{ facility.title }}
                    </span>
                  </a>

                  <a
                    href="/add-facility"
                    class="dropdown-item ai-icon"
                    v-if="$user_type == 'admin'"
                  >
                    <i class="las la-clinic-medical scale5"></i>
                    <span class="ml-2">Manage Facility</span>
                  </a>
                  <button class="dropdown-item ai-icon">
                    <i class="las la-sign-in-alt"></i>
                    <span class="ml-2" @click="logOut">Logout </span>
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
    <!--**********************************
            Header end ti-comment-alt
        ***********************************-->
    <SideNavbar />
    <LoaderComponent v-show="isLoading" />
  </div>
</template>

<script>
import SideNavbar from "@/views/SideNavbar.vue";
import Chatbox from "@/views/Chatbox.vue";
import LoaderComponent from "@/views/LoaderComponent.vue";
import { PatientService } from "@/jsstore/patients";
// import { reloadPage } from "@/functions/Settings";

export default {
  components: {
    SideNavbar,
    Chatbox,
    LoaderComponent,
  },
  data() {
    return {
      renderComponent: true,
      jsstore: new PatientService(),
      waitings: "",
      appointments: "",
      patients: "",
      facilities: "",
      keyWord: "",
      results: "",
      isLoading: false,
    };
  },

  mounted: function () {
    this.timer = setInterval(() => {
      this.getAppointments();
      this.getWaiting();
    }, 10000);
  },

  beforeMount() {
    this.axios
      .get(`/admin/facility`)
      .then((response) => {
        this.facilities = response.data.data;
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {
    
    selectFacility(facility) {
      this.isLoading = true;
      this.axios
        .post(`/admin/facility/change`, {
          id: facility.id,
        })
        .then((response) => {
          localStorage.setItem("selected_facility", JSON.stringify(facility));
          this.$toasted.success(`Switching to ${facility.title} Facility`, {
            position: "top-center",
            duration: 7000,
          });

          console.log(response);
          this.$router.push("/admin-dashboard");
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    selectPatient() {
      console.log(this.keyWord);
      this.$router.push(`/patient/${this.keyWord}`);
    },
    async searchHere() {
      if (this.$store.state.mode == false) {
        let query = await this.jsstore.getPatientByCard(this.keyWord);
        this.patient = query[0];
        this.$router.push(`/patient/${this.patient.card_no}`);
      } else {
        this.axios
          .post(`/staff/search-patient`, { search: this.keyWord })
          .then((response) => {
            let patients = response.data;
            this.patients = {
              data: patients,
            };
            this.isLoading = false;
            console.log(response);
            // this.$toastr.s("Searched Successfully!", "Success!");
          })
          .catch((error) => {
            console.error(error);
            this.$toasted.error(`Error!`, {
              position: "top-center",
              duration: 7000,
            });
          });
      }
    },
    getWaiting() {
      this.axios
        .get(`/staff/waiting-list/${this.$user.id}/${this.$user.job_title_id}`)
        .then((response) => {
          this.waitings = response.data;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    logOut() {
      this.$toastr.s("Logged out Successfully", "Success!");

      localStorage.removeItem("user");
      localStorage.removeItem("user_type");
      localStorage.removeItem("user_admin");
      localStorage.removeItem("user_staff");
      localStorage.removeItem("jwt");
      this.$router.push("/");

      // this.axios
      //   .post(`/admin/auth/logout`)
      //   .then((response) => {
      //     this.$toastr.s("Logged out Successfully", "Success!");
      //     console.log(response);

      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   });
    },

    getAppointments() {
      this.axios
        .get(`/staff/my-appointments/${this.$user.id}`)
        .then((response) => {
          this.appointments = response.data;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    endPassedVisits(){
      this.axios
      .post(`/staff/close-old-visits/${this.$selected_facility.id}`)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
    }
  },
  created() {
    this.getWaiting();
    this.endPassedVisits();
  },
};
</script>
