<template>
  <div>
    <Header />

    <div class="content-body">
      <div class="col-md-10 offset-md-1">
        <div class="card">
          <div class="settings-form">
            <div class="card-header">
              <h4 class="text-dark">Edit Patient</h4>
            </div>
            <div class="card-body">
              <form @submit.prevent="updateUser">
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label>First Name </label>
                    <input
                      type="text"
                      placeholder="First Name"
                      v-model="patient.first_name"
                      class="form-control"
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label>Last Name </label>
                    <input
                      type="text"
                      placeholder="Last Name"
                      v-model="patient.last_name"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label>Middle Name </label>
                    <input
                      type="text"
                      placeholder="Middle Name"
                      v-model="patient.middle_name"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label>Email</label>
                    <input
                      type="email"
                      placeholder="Email"
                      class="form-control"
                      v-model="patient.email"
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <label>Phone Number</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="patient.phone_no"
                    />
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label>Card No</label>
                    <input
                      type="text"
                      placeholder="Card Number"
                      class="form-control"
                      v-model="patient.card_no"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label>Date of Birth </label>
                    <input
                      type="date"
                      placeholder=""
                      class="form-control"
                      v-model="patient.dob"
                    />
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="inputPassword4">Gender </label>
                    <v-select
                      v-model="patient.gender"
                      class="form-control"
                      :options="gender_data"
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="inputPassword4">Blood Group </label>
                    <v-select
                      v-model="patient.blood_group"
                      class="form-control"
                      :options="blood_groups"
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="inputPassword4">Genotype</label>
                    <v-select
                      v-model="patient.genotype"
                      class="form-control"
                      :options="blood_groups"
                    />
                  </div>
                </div>

                <button class="btn btn-success btn-block" type="submit">
                  Update Patient <i class="la la-lock"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
</template>

<script>
import Header from "@/views/Header.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    Header,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      user: null,
      patient: "",
      gender_data: ["Male", "Female"],
      blood_groups: ["A+", "B+", "A-", "B-", "AB+", "AB-", "O+", "0-"],
      genotypes: ["AA", "AS", "AC", "SS", "SC"],
    };
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    getPatient() {
      this.axios
        .get(`/staff/patients/${this.$route.params.id}`)
        .then((response) => {
          this.patient = response.data.data;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    updateUser() {
      this.isLoading = true;
      this.axios
        .patch(`/staff/patients/${this.$route.params.id}`, this.patient)
        .then((response) => {
          console.log(response);
          this.getPatient();
          this.$toastr.s("Patient Details Updated Successfully", "Success!");
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.getPatient();
  },
};
</script>
