<template>
  <div>
    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label
                    >Case Number

                    <a
                      class="btn btn-success btn-sm px-3 light ml-2"
                      @click="autoGenerate"
                      >generate</a
                    >
                  </label>
                  <input
                    type="text"
                    required
                    placeholder="CS00021"
                    class="form-control"
                    v-model="form_data.case_number"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label for="inputPassword4">Select Category </label>
                  <v-select
                    v-model="form_data.category_id"
                    :reduce="(name) => name.id"
                    class="form-control"
                    label="name"
                    :options="categories"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label>Patient Card Number</label>
                  <input
                    type="text"
                    @keyup="searchPatient"
                    placeholder="CNX0001"
                    class="form-control"
                    v-model="form_data.search_query"
                  />
                  <a
                    class="btn btn-primary btn-sm px-3 light mt-2"
                    v-if="patient_data != ''"
                  >
                    <i class="las la-check"></i> patient found</a
                  >
                </div>

                <div class="form-group col-md-6" v-if="patient_data != ''">
                  <label>Patient Name</label>
                  <input
                    type="text"
                    disabled
                    class="form-control"
                    :value="`${patient_data.full_name}`"
                  />
                </div>

                <div class="form-group mt-4 col-md-12">
                  <label class="text-black font-w500">Case Report </label>
                  <wysiwyg v-model="form_data.case_report" />
                </div>

                <div class="form-group mt-4 col-md-12">
                  <label class="text-black font-w500"
                    >Other Extra Information
                    <span class="text-success text-sm">(optional)</span></label
                  >
                  <wysiwyg v-model="form_data.other_details" />
                </div>
              </div>

              <button class="btn btn-success btn-block" type="submit">
                Submit <i class="las la-paper-plane"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
        
  <script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin
import { CaseService } from "@/jsstore/cases";
import { PatientService } from "@/jsstore/patients";

export default {
  components: {
    Loading,
  },
  props:["categories"],
  data() {
    return {
      jsstore: new CaseService(),
      patient_service: new PatientService(),
      patient_data: "",
      isLoading: false,
      fullPage: true,
      edit: false,
      patients: "",
      staffs: "",
      form_data: {
        facility_id:"",
        search_query: "",
        patient_id: "",
        category_id: "",
        case_number: "",
        case_report: "",
        other_details: "",
      },
      // categories: ["General Case"],
    };
  },
  beforeMount() {},
  methods: {
    submitForm() {
      if (this.edit == false) {
        if (this.$store.state.mode == false) {
          this.addFormOffline();
        } else {
          this.addForm();
        }
      } else {
        this.updateForm();
      }
    },
    addForm() {
      this.isLoading = true;
      this.axios
        .post("/staff/case-reports", {
          patient_id: this.patient_data.id,
          staff_id: this.$user.id,
          facility_id: this.$selected_facility.id,
          category_id: this.form_data.category_id,
          department_id: this.$route.params.id,
          case_number: this.form_data.case_number,
          case_report: this.form_data.case_report,
          other_details: this.form_data.other_details,
        })
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.clearForm();
          this.$toastr.s("Case Created Successfully", "Success!");
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");

          this.isLoading = false;
        });
    },

    async addFormOffline() {
      try {
        let payload = {
          patient_card_no: this.form_data.search_query,
          patient: this.patient_data,
          category_id: this.form_data.category_id,
          case_number: this.form_data.case_number,
          case_report: this.form_data.case_report,
          other_details: this.form_data.other_details,
          created_at: new Date(),
        };
        let query = await this.jsstore.addCase(payload);
        console.log(JSON.stringify(query + payload) + "jsstore");
        this.$toastr.s("Case added Successfully", "Success!");
        this.clearForm();
      } catch (ex) {
        this.$toastr.e(`${ex.message}`, "Error!");
      }
    },
    clearForm() {
      this.form_data.staff_id = "";
      this.form_data.category_id = "";
      this.form_data.case_number = "";
      this.form_data.case_report = "";
      this.form_data.other_details = "";
    },
    updateForm() {
      //
    },

    searchPatient() {
      if (this.$store.state.mode == false) {
        this.getPatientOffline();
      } else {
        this.searchPatientOnline();
      }
    },

    searchPatientOnline() {
      this.axios
        .get(`/staff/patients`, {
          params: {
            scope: `card_no:${this.form_data.search_query}`,
          },
        })
        .then((response) => {
          this.patient_data = response.data.data.data[0];
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
          this.$toasted.error(`Error!`, {
            position: "top-center",
            duration: 7000,
          });
        });
    },

    async getPatientOffline() {
      let query = await this.patient_service.getPatientByCard(
        this.form_data.search_query
      );
      this.patient_data = query[0];
      console.log(this.patient + "jsstore");
    },

   

    autoGenerate() {
      let length = 10;
      let unique_id = Math.floor(
        Math.pow(10, length - 1) +
          Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1)
      );

      this.form_data.case_number = "CX" + unique_id;
    },
  },
  created() {
  },
};
</script>

  
<style scoped>
@import "~vue-wysiwyg/dist/vueWysiwyg.css";
</style>
    
        