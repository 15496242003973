<template>
    <div class="">
  
      <div class="row">
          <div class="col-md-4 text-white">
            <div class="card bg-success">
              <div class="card-body">
                <div class="media align-items-center">
                  <div class="media-body mr-3">
                    <h2 class="fs-20 text-white font-w600">
                      <i class="mdi mdi-currency-ngn mr-n2"></i>  0.00
                    </h2>
                    <span>Revenue</span>
                  </div>
                  <i class="las la-coins la-2x"></i>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4 text-white">
            <div class="card bg-danger">
              <div class="card-body">
                <div class="media align-items-center">
                  <div class="media-body mr-3">
                    <h2 class="fs-20 text-white font-w600">
                      <i class="mdi mdi-currency-ngn mr-n2"></i>  0.00
                    </h2>
                    <span>Expenses</span>
                  </div>
                  <i class="las la-money-bill la-2x"></i>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="card bg-dark text-white">
              <div class="card-body">
                <div class="media align-items-center">
                  <div class="media-body mr-3">
                    <h2 class="fs-20 text-white font-w600">
                      <i class="mdi mdi-currency-ngn mr-n2"></i>
                      0.00
                    </h2>
                    <span>Current Month Rev. </span>
                  </div>
                  <i class="las la-wallet la-2x"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <RevenueExpenseMonthly />
          </div>

          <div class="col-md-6">
            <RevenueByChannel />
          </div>


        </div>

   
  
      <div class="vld-parent">
        <loading
          :active.sync="isLoading"
          loader="spinner"
          :can-cancel="true"
          :is-full-page="fullPage"
        ></loading>
      </div>
    </div>
  </template>
  
  <script>
  import Loading from "vue-loading-overlay";
  // Import stylesheet
  import "vue-loading-overlay/dist/vue-loading.css";
  import RevenueExpenseMonthly from "@/views/finances/charts/RevenueExpenseMonthly.vue";
import RevenueByChannel from "@/views/finances/charts/RevenueByChannel.vue";
  
  export default {
    components: {
      Loading, RevenueExpenseMonthly, RevenueByChannel
    
    },
    data() {
      return {
        user: null,
        checked: false,
        isLoading: false,
        fullPage: true,
        selected_facility: null,
        auth_user: "",
      };
    },
    beforeMount() {
     
    },
  
    methods: {},
    created() {},
  };
  </script>
  