<template>
  <div>
    <Header />

    <div class="content-body">
      <div class="container-fluid">
        <div class="form-head d-flex mb-sm-4 mb-3">
          <div class="mr-auto">
            <h2 class="text-black font-w600">Transfer Products</h2>
            <p class="mb-0">
              Transfer products to other Departments in the facilities here
            </p>
          </div>
          <div>
            <div class="dropdown ml-auto mr-2">
              <a
                href="#"
                class="btn btn-success light sharp"
                data-toggle="dropdown"
                aria-expanded="true"
              >
                <li class="las la-ellipsis-h scale5"></li>
              </a>
              <ul class="dropdown-menu dropdown-menu-right">
                <li class="dropdown-item">
                  <span v-b-toggle.sidebar-transfer>
                    <a
                      href="javascript:void()"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Export"
                      ><i
                        class="
                          las
                          la-exchange-alt
                          color-muted
                          text-success
                          mr-2
                        "
                      ></i
                    ></a>

                    Transfer Item
                  </span>
                </li>

                <li class="dropdown-item">
                  <span>
                    <a
                      href="javascript:void()"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Export"
                      ><i
                        class="
                          las
                          la-file-export
                          color-muted
                          text-success
                          mr-2
                        "
                      ></i
                    ></a>

                    Export CSV
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <b-sidebar
          id="sidebar-transfer"
          title="Transfer Inventory"
          right
          shadow
          width="500px"
        >
          <div class="px-3 py-2">
            <TransferInventory ref="TransferInventory" @update="onUpdate($event)" />
          </div>
        </b-sidebar>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group col-md-3 float-right">
              <b-form-input
                v-model="search"
                type="search"
                size="sm"
                placeholder="Search Transfers"
              ></b-form-input>
            </div>
          </div>
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Transfers</h4>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-responsive-md">
                    <thead>
                      <tr>
                        <th class="width80"><strong>#</strong></th>
                        <th><strong>Product</strong></th>
                        <th><strong>Department</strong></th>
                        <th><strong>Transferring Officer</strong></th>
                        <th><strong>Receving Officer</strong></th>
                        <th><strong>Date Dispensed On</strong></th>
                        <th><strong>Action</strong></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(transfer, index) in transfers.data"
                        :key="transfer.id"
                      >
                        <td>
                          <strong> {{ index + 1 }} </strong>
                        </td>
                        <td>{{ transfer.product_id }}</td>
                        <td>{{ transfer.department_id }}</td>
                        <td>{{ transfer.transferring_officer }}</td>
                        <td>{{ transfer.collecting_officer }}</td>
                        <td>
                          {{
                            transfer.date_dispensed
                              | moment("dddd, MMMM Do YYYY")
                          }}
                        </td>
                        <td>
                          <span>
                            <a
                              href="javascript:void()"
                              class="mr-4"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Edit"
                              v-b-toggle.sidebar-transfer
                              @click="startUpdate(transfer)"
                              ><i
                                class="fa fa-pencil color-muted text-success"
                              ></i>
                            </a>
                            <!-- <a
                              href="javascript:void()"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Close"
                              @click="deleteTransfer(transfer)"
                            >
                              <i class="las la-trash text-success"></i
                            ></a> -->
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="vld-parent">
          <loading
            :active.sync="isLoading"
            loader="spinner"
            :can-cancel="true"
            :is-full-page="fullPage"
          ></loading>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Header from "@/views/Header.vue";
import TransferInventory from "@/views/inventories/TransferInventory.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Header,
    Loading,
    TransferInventory,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      showSidebar: false,
      edit: false,
      search: "",
      transfers: "",
    };
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    startUpdate(transfer) {
      this.$refs.TransferInventory.setValue(transfer);
    },
    getTransfer() {
      this.isLoading = true;
      this.axios
        .get(`/staff/stock-transfer`)
        .then((response) => {
          this.transfers = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    deleteTransfer(transfer) {
      if (confirm("Are you Sure you want to delete?")) {
        this.isLoading = true;
        this.axios
          .delete(`/staff/stock-transfer/${transfer.id}`)
          .then((response) => {
            console.log(response);
            this.isLoading = false;
            this.getStocks();
            this.$toastr.s("Deleted Successfully", "Success!");
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
          });
      }
    },
    onUpdate() {
      this.getTransfer();
      this.showSidebar = !this.showSidebar;
    },
  },
  created() {
    this.getTransfer();
  },
};
</script>
  