<template>
  <div>
    <Header />

    <!--**********************************
              Content body start
          ***********************************-->
    <div class="content-body" id="printDiv">
      <div class="container-fluid">
        <div class="page-titles">
          <h4>Laboratory Test Details</h4>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="javascript:void(0)">Laboratory</a>
            </li>
            <li class="breadcrumb-item active">
              <a href="javascript:void(0)"> Lab{{ lab.id }}</a>
            </li>
          </ol>
        </div>
        <!-- row -->
        <div class="row">
          <div class="col-md-4 col-lg-4 col-sm-12">
            <PatientCard :patient="patient" />
          </div>

          <div class="col-md-8">
            <div class="card">
              <div class="card-body">
                <div class="ml-0 ml-sm-4 ml-sm-0" ref="printNow">
                  <div class="row">
                    <div class="col-12">
                      <div class="right-box-padding">
                        <div class="read-content">
                          <div
                            class="media pt-3 d-sm-flex d-block justify-content-between"
                          >
                            <div class="clearfix mb-3 d-flex">
                              <img
                                class="mr-3 rounded"
                                width="50"
                                alt="image"
                                src="/images/avatar/1.jpg"
                              />
                              <div class="media-body mr-2">
                                <h5 class="text-success mb-0 mt-1">
                                  By
                                  {{ lab.staff.initials }}
                                  {{ lab.staff.full_name }}
                                </h5>
                                <p class="mb-0">
                                  {{ lab.created_at | moment("MMMM Do YYYY, h:mm:ss a") }}
                                </p>
                              </div>
                            </div>
                            <div class="clearfix mb-3">
                            

                              <a
                                href="javascript:void()"
                                @click="printMe"
                                class="btn btn-primary px-3 light"
                                ><i class="las la-print"></i>
                              </a>
                            
                            </div>
                          </div>

                          <div class="read-content-body mt-4">
                           <hr>
                            <h5 class="mb-4">
                              <strong> Selected Samples </strong>
                            </h5>
                            <p
                              class="mb-2"
                              v-for="list_item in lab.lab_tests
                                .selected_samples"
                              :key="list_item"
                            >
                              {{ list_item }}
                            </p>

                            <hr />
                          </div>


                          <div class="read-content-body">
                           
                            <h5 class="mb-4">
                              <strong> Profile Tests </strong>
                            </h5>
                            <p
                              class="mb-2"
                              v-for="list_item in lab.lab_tests
                                .profile_test"
                              :key="list_item"
                            >
                              {{ list_item }}
                            </p>

                            <hr />
                          </div>


                          <div class="read-content-body">
                           
                            <h5 class="mb-4">
                              <strong> Biochemistry </strong>
                            </h5>
                            <p
                              class="mb-2"
                              v-for="list_item in lab.lab_tests
                                .biochemistry"
                              :key="list_item"
                            >
                              {{ list_item }}
                            </p>

                            <hr />
                          </div>

                          <div class="read-content-body">
                           
                            <h5 class="mb-4">
                              <strong> Haematology </strong>
                            </h5>
                            <p
                              class="mb-2"
                              v-for="list_item in lab.lab_tests
                                .haematology"
                              :key="list_item"
                            >
                              {{ list_item }}
                            </p>

                            <hr />
                          </div>


                          <div class="read-content-body">
                            
                            <h5 class="mb-4">
                              <strong> Microbiology  </strong>
                            </h5>
                            <p
                              class="mb-2"
                              v-for="list_item in lab.lab_tests
                                .microbiology"
                              :key="list_item"
                            >
                              {{ list_item }}
                            </p>

                            <hr />
                          </div>


                          <div class="read-content-body">
                           
                            <h5 class="mb-4">
                              <strong> Pathology </strong>
                            </h5>
                            <p
                              class="mb-2"
                              v-for="list_item in lab.lab_tests
                                .pathology"
                              :key="list_item"
                            >
                              {{ list_item }}
                            </p>

                            <hr />
                          </div>


                          <div class="read-content-body">
                           
                            <h5 class="mb-4">
                              <strong> Cytology  </strong>
                            </h5>
                            <p
                              class="mb-2"
                              v-for="list_item in lab.lab_tests
                                .cytology"
                              :key="list_item"
                            >
                              {{ list_item }}
                            </p>

                            <hr />
                          </div>


                          <div class="read-content-body">
                          
                            <h5 class="mb-4">
                              <strong> Additional Tests  </strong>
                            </h5>
                            <p
                              class="mb-2"
                             
                            >
                              {{ lab.lab_tests.additional }}
                            </p>

                            <hr />
                          </div>



                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--**********************************
              Content body end
          ***********************************-->

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
</template>
  
  <script>
import Header from "@/views/Header.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin
import PatientCard from "@/views/records/PatientCard.vue";

export default {
  components: {
    Header,
    Loading,
    PatientCard,
  },
  data() {
    return {
      user: null,
      isLoading: false,
      fullPage: true,
      patient: "",
      lab: "",
    };
  },
  beforeMount() {},
  methods: {
    getAppointment() {
      this.axios
        .get(`/staff/labs/${this.$route.params.id}`)
        .then((response) => {
          this.lab = response.data;
          this.patient = response.data.patient;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    printMe() {
      var printContents = document.getElementById("printDiv").innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    },
  },
  created() {
    this.getAppointment();
  },
};
</script>
  
  <style scoped></style>
  