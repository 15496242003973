<template>
  <div>
    <div class="col-md-12">
      <div class="">
        <div class="settings-form">
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Category Name</label>
                  <input
                    type="text"
                    placeholder="Medication, Laboratory Equipment"
                    class="form-control"
                    v-model="category.name"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label> Description</label>
                  <textarea
                    class="form-control"
                    placeholder="Type your message..."
                    rows="4"
                    v-model="category.description"
                  ></textarea>
                </div>
              </div>

              <button class="btn btn-success btn-block" type="submit">
                Submit <i class="las la-paper-plane"></i>
              </button>
            </form>

            <div class="col-md-12 mt-2">
              <h3 class="text-black font-w400">Categories</h3>
              <ol>
                <li
                  class="my-2"
                  v-for="(cat, index) in categories.data"
                  v-bind:key="cat.id"
                >
                 {{ index + 1 }}. {{ cat.name }}

                  <i class="las la-edit ml-2" @click="startUpdate(cat)"></i>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>

<script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Loading,
  },
  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      edit: false,
      categories: "",
      category: {
        id: "",
        name: "",
        description: "",
      },
    };
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addCategory();
      } else {
        this.updateCategory();
      }
    },
    addCategory() {
      this.isLoading = true;
      this.axios
        .post("/staff/categories", this.category )
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.clearForm()
          this.getCategories()
          this.$toastr.s("Category Created Successfully", "Success!");
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");
          this.isLoading = false;
        });
    },
    updateCategory() {
      this.isLoading = true;
      this.axios
        .patch(`/staff/categories/${this.category.id}`, this.category )
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.clearForm()
          this.getCategories()
          this.$toastr.s("Category Updated Successfully", "Success!");
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");
          this.isLoading = false;
        });
    },

    getCategories() {
      this.isLoading = false;
      this.axios
        .get(`/staff/categories`)
        .then((response) => {
          this.categories = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    startUpdate(cat){
      this.category = cat
      this.edit = true
    },
    clearForm(){
      this.category = ""
    }
  },
  created() {
    this.getCategories();
  },
};
</script>
