<template>
  <div>
    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header">
                    <h4 class="card-title pt-4 pb-4">Surgeries</h4>
                  </div>
                  <div class="card-body">
                    <vue-good-table
                      :columns="columns"
                      :rows="surgeries.data"
                      :search-options="{ enabled: true }"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-sidebar
      id="sidebar-condition"
      title="Surgery Form"
      right
      shadow
      width="600px"
    >
      <div class="px-3 py-2">
        <AddSurgery />
      </div>
    </b-sidebar>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
            
            <script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin
import AddSurgery from "@/views/patients/components/AddSurgery.vue";

export default {
  components: {
    Loading,
    AddSurgery,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      columns: [
        {
          label: "Staff",
          field: "staff.full_name",
          type: "text",
        },

        {
          label: "Procedure",
          field: "name",
          type: "text",
        },

        {
          label: "Support Devices",
          field: "support_devices",
          type: "text",
        },

        {
          label: "Description",
          field: "notes",
          type: "text",
        },

        {
          label: "Performed On",
          field: "date",
          type: "text",
        },
      ],
      surgeries: [],
    };
  },
  beforeMount() {},
  methods: {
    fetchData() {
      this.isLoading = true;

      this.axios
        .get(`/staff/patient-surgeries/${this.$route.params.id}`)
        .then((response) => {
          this.surgeries = response.data;
          this.isLoading = false;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
            