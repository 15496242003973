<template>
  <div>
    <Header />

    <div class="content-body">
      <div class="container-fluid">
        <div class="form-head d-flex mb-sm-4 mb-3">
          <div class="mr-auto">
            <h2 class="text-black font-w600">Payment Manager</h2>
            <p class="mb-0">Enter all expenses and payments here</p>
          </div>
          <div>
            <a
              href="javascript:void(0)"
              class="btn btn-success mr-3"
              @click="showForm = !showForm"
              v-b-toggle.sidebar-right
              ><i class="las la-plus scale5 mr-3"></i> Create Payment</a
            >

            <!-- <a href="#" class="btn btn-outline-success"
                ><i class="las la-calendar-plus scale5 mr-3"></i>Filter</a
              > -->
          </div>
        </div>
        <b-sidebar
          id="sidebar-right"
          title="Create Payment"
          right
          shadow
          width="630px"
        >
          <div class="px-3 py-2">
            <AddPayment v-show="showForm" />
          </div>
        </b-sidebar>

        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Services</h4>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-responsive-md">
                    <thead>
                      <tr>
                        <th class="width80"><strong>#</strong></th>
                        <th><strong>Dealer</strong></th>
                        <th><strong>Description</strong></th>
                        <th><strong>Amount</strong></th>
                        <th><strong>Created At</strong></th>
                        <th><strong>Status</strong></th>
                        <th><strong>Action</strong></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(payment, index) in payments.data"
                        :key="payment.id"
                      >
                        <td>
                          <strong> {{ index + 1 }} </strong>
                        </td>
                        <td>{{ payment.payment_to }}</td>
                        <td>{{ payment.description }}</td>
                        <td>
                          <i class="mdi mdi-currency-ngn"></i>
                          {{ payment.amount | numeral(0, 0) }}
                        </td>
                        <td>
                          {{ payment.created_at | moment("from", "now") }}
                        </td>
                        <td>
                          <a
                            href="javascript:void()"
                            class="badge badge-rounded badge-xs badge-outline-success text-success"
                            >completed
                          </a>
                        </td>
                        <td>
                          <span>
                            <a
                              href="javascript:void()"
                              class="mr-4"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Edit"
                              @click="updateRole(role)"
                              ><i
                                class="fa fa-pencil color-muted text-success"
                              ></i>
                            </a>
                            <a
                              href="javascript:void()"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Close"
                              @click="deleteRole(role)"
                              ><i
                                class="fa fa-close color-danger text-success"
                              ></i
                            ></a>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="vld-parent">
          <loading
            :active.sync="isLoading"
            loader="spinner"
            :can-cancel="true"
            :is-full-page="fullPage"
          ></loading>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Header from "@/views/Header.vue";
import AddPayment from "@/views/finances/AddPayment.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

import { reloadPage } from "@/functions/Settings";

export default {
  components: {
    Header,
    Loading,
    AddPayment,
  },
  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      showForm: false,
      edit: false,
      payments: "",
    };
  },
  beforeCreate() {
    reloadPage();
  },

  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addDept();
      } else {
        this.editDept();
      }
    },

    getPayments() {
      this.isLoading = true;

      this.axios
        .get(`/staff/payments`)
        .then((response) => {
          this.payments = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    deleteRole(role) {
      if (confirm("Are you Sure you want to delete?")) {
        this.isLoading = true;
        this.axios
          .patch(`/roles/${role.Id}`)
          .then((response) => {
            console.log(response);
            this.isLoading = false;
            this.getRoles();
            this.isLoading = false;
            this.$toasted.success("Deleted Successfully!", {
              position: "top-center",
              duration: 3000,
            });
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
          });
      }
    },
  },
  created() {
    this.getPayments();
  },
};
</script>
  