<template>
  <div>
    <Header />

    <div class="content-body">
      <div class="container-fluid">
        <div class="form-head d-flex mb-sm-4 mb-3">
          <div class="mr-auto">
            <h2 class="text-black font-w600">Pharmacy Manager</h2>
            <p class="mb-0">
              Manage your drugs, consumables and dispensaries here.
            </p>
            <p>Add Products, dispense/make sales etc.</p>
          </div>
          <div>
            <div class="dropdown ml-auto mr-2">
              <a
                href="#"
                class="btn btn-success light sharp"
                data-toggle="dropdown"
                aria-expanded="true"
              >
                <li class="las la-ellipsis-h scale5"></li>
              </a>
              <ul class="dropdown-menu dropdown-menu-right">
                <li class="dropdown-item">
                  <span v-b-toggle.sidebar-right>
                    <a
                      href="javascript:void()"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Add"
                      ><i class="las la-plus color-muted text-success mr-2"></i>
                    </a>
                    New Product
                  </span>
                </li>

                <li class="dropdown-item">
                  <span v-b-toggle.sidebar-stock>
                    <a
                      href="javascript:void()"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Export"
                    >
                      <i class="las la-store text-success mr-2"></i>
                    </a>
                    Add Stock
                  </span>
                </li>

                <li class="dropdown-item">
                  <span v-b-toggle.sidebar-dispense>
                    <a
                      href="javascript:void()"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Export"
                      ><i
                        class="las la-prescription-bottle-alt text-success mr-2"
                      ></i
                    ></a>
                    Dispense
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <b-sidebar
          id="sidebar-right"
          title="Pharmacy Product"
          right
          shadow
          width="500px"
        >
          <div class="px-3 py-2">
            <AddInventoryPharmacy  ref="AddInventoryPharmacy"   @update="onUpdate($event)" />
          </div>
        </b-sidebar>

        <b-sidebar
          id="sidebar-stock"
          title="Pharmacy Stock"
          right
          shadow
          width="500px"
        >
          <div class="px-3 py-2">
            <AddStockPharmacy />
          </div>
        </b-sidebar>

        <b-sidebar
          id="sidebar-dispense"
          title="Dispense Section"
          right
          shadow
          width="500px"
        >
          <div class="px-3 py-2">
            <DispensePharmacy />
          </div>
        </b-sidebar>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group col-md-3 float-right">
              <b-form-input
                v-model="search"
                type="search"
                size="sm"
                placeholder="search..."
              ></b-form-input>
            </div>
          </div>
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Pharmacy Products</h4>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-responsive-md">
                    <thead>
                      <tr>
                        <th class="width80"><strong>#</strong></th>
                        <th><strong>Product</strong></th>
                        <th><strong>Quantity</strong></th>
                        <th><strong>Price</strong></th>
                        <th><strong>Min. Required </strong></th>
                        <th><strong>Added On</strong></th>
                        <th><strong>Action</strong></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(pharmacy, index) in pharmacies.data"
                        :key="pharmacy.id"
                      >
                        <td>
                          <strong>  {{ index + 1 }} </strong>
                        </td>
                        <td>{{ pharmacy.product_id }}</td>
                        <td>{{ pharmacy.quantity }}</td>
                        <td> <i class="mdi mdi-currency-ngn"></i> {{ pharmacy.price }}</td>
                        <td>{{ pharmacy.minimum_required }}</td>
                        <td>{{ pharmacy.created_at | moment("dddd, MMMM Do YYYY") }}</td>
                        <td>
                          <span>
                            <a
                              :href="`/inventory/${pharmacy.id}`"
                              class="mr-4"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="View"
                              ><i class="las la-eye text-success"></i>
                            </a>
                            <a
                              href="javascript:void()"
                              class="mr-4"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Edit"
                              v-b-toggle.sidebar-right
                              @click="startUpdate(pharmacy)"
                              ><i
                                class="fa fa-pencil color-muted text-success"
                              ></i>
                            </a>
                            <a
                              href="javascript:void()"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Close"
                              @click="deleteInventory(pharmacy)"
                            >
                              <i class="las la-trash text-success"></i
                            ></a>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="vld-parent">
          <loading
            :active.sync="isLoading"
            loader="spinner"
            :can-cancel="true"
            :is-full-page="fullPage"
          ></loading>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/views/Header.vue";
import AddInventoryPharmacy from "@/views/pharmacies/AddInventoryPharmacy.vue";
import AddStockPharmacy from "@/views/pharmacies/AddStockPharmacy.vue";
import DispensePharmacy from "@/views/pharmacies/DispensePharmacy.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin
// import { reloadPage } from "@/functions/Settings";

export default {
  components: {
    Header,
    Loading,
    AddStockPharmacy,
    AddInventoryPharmacy,
    DispensePharmacy,
  },
  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      showSidebar: false,
      edit: false,
      search: "",
      pharmacies: "",
      
    };
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  beforeCreate() {
    //  reloadPage();
  },
  methods: {
  
    getProducts() {
      this.isLoading = true;
      this.axios
        .get(`/staff/pharmacy`)
        .then((response) => {
          this.pharmacies = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    deleteInventory(pharmacy) {
      if (confirm("Are you Sure you want to delete?")) {
        this.isLoading = true;
        this.axios
          .patch(`/inventory/${pharmacy.id}`)
          .then((response) => {
            console.log(response);
            this.isLoading = false;
            this.getRoles();
            this.isLoading = false;
            this.$toastr.s("Deleted Successfully", "Success!");
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
          });
      }
    },
    startUpdate(pharmacy) {
      this.$refs.AddInventoryPharmacy.setValue(pharmacy);
      console.log(pharmacy);
    },

    onUpdate() {
      this.getProducts();
      this.showSidebar = !this.showSidebar;
    },
  },
  created() {
    this.getProducts();
  },
};
</script>
