<template>
  <div>
    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header">
                    <h4 class="card-title pt-4 pb-4">Encounter Records</h4>
                  </div>

                  <div class="card-body">
                    <vue-good-table
                      :columns="columns"
                      :rows="records.data"
                      :line-numbers="true"
                      :search-options="{ enabled: true }"
                      @on-row-click="onRowClick"
                      :pagination-options="{
                        enabled: true,
                      }"
                      v-if="$store.state.mode == true"
                    />

                    <div class="table-responsive" v-else>
                      <table class="table table-responsive-md">
                        <thead>
                          <tr>
                            <th class="width80"><strong>#</strong></th>
                            <th><strong>Patient</strong></th>
                            <th><strong>Card No</strong></th>
                            <th><strong>Title</strong></th>
                            <th><strong>Description</strong></th>

                            <th><strong>Created At</strong></th>
                            <th><strong>Action</strong></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(record, index) in records.data"
                            :key="record.id"
                          >
                            <td>
                              <strong> {{ index + 1 }} </strong>
                            </td>
                            <td>
                              {{ record.patient.first_name }}

                              {{ record.patient.last_name }}
                            </td>
                            <td>
                              {{ record.patient.card_no }}
                            </td>
                            <td>{{ record.title }}</td>
                            <td>{{ record.details }}</td>
                            <td>
                              {{
                                record.created_at | moment("dddd, MMMM Do YYYY")
                              }}
                            </td>
                            <td>
                              <span>
                                <a
                                  href="javascript:void()"
                                  class="mr-4"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Edit"
                                  v-b-toggle.sidebar-right
                                  @click="startUpdate(bed)"
                                  ><i
                                    class="fa fa-pencil color-muted text-success"
                                  ></i>
                                </a>
                                <a
                                  href="javascript:void()"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Close"
                                  @click="deleteBed(bed)"
                                  ><i
                                    class="fa fa-close color-danger text-success"
                                  ></i
                                ></a>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
      
      <script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

import { RecordService } from "@/jsstore/records";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      record_service: new RecordService(),
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      records: "",
      columns: [
        {
          label: "Patient",
          field: "patient.full_name",
        },
        {
          label: "Title",
          field: "action.description",
          type: "text",
        },
        // {
        //   label: "Notes",
        //   field: "details",
        //   type: "text",
        // },
        {
          label: "Created At",
          field: "created_at",
          type: "text",
        },
      ],

      columns_offline: [
        {
          label: "Patient",
          field: "patient",
        },
        {
          label: "Title",
          field: "title",
          type: "text",
        },
        {
          label: "Samples",
          field: "selected_samples",
          type: "text",
        },
        {
          label: "Created At",
          field: "created_at",
          type: "text",
        },
      ],
    };
  },
  beforeMount() {},
  methods: {
    getPatientRecords() {
      this.axios
        .get(`/staff/patient-records/${this.$route.params.id}`)
        .then((response) => {
          this.records = response.data.data;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async getRecordsOffline() {
      let query = await this.record_service.getRecordByPatient(
        "this.$route.params.card_no"
      );

      this.records = {
        data: query,
      };

      console.log(this.records + "jsstore");
    },

    onRowClick(params) {
      // this.$router.push(`/record/${params.row.id}`);
      window.location.href = `/record-${params.row.id}`;

    },
  },
  created() {
    if (this.$store.state.mode == true) {
      this.getPatientRecords();
    } else {
      this.getRecordsOffline();
    }
  },
};
</script>
      