<template>
  <div>
    <div class="footer">
      <!-- <div class="col-md-8 offset-md-2"> -->
      <div class="copyright">
        <p>
          Copyright © Designed &amp; Developed by
          <a href="https://uniccongroup.com/" target="_blank">Uniccon</a> 2023
        </p>
        <p>
          Version  {{ app_package.version }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { getAppVersion } from "@/functions/Settings";
// import { supabase } from "@/lib/Supabase";


export default{
  data(){
    return{
      app_package: ""
    }
  },
async  beforeCreate(){
    this.app_package = await getAppVersion();
  }
}
</script>