<template>
  <div class="">
    <div class="row">
      <div class="col-md-3 col-sm-6">
        <div class="card">
          <div class="card-body">
            <div class="media align-items-center">
              <div class="media-body mr-3">
                <h2 class="fs-20 text-black font-w600">
                  {{ metrics.patients }}
                </h2>
                <span>Total Patients</span>
              </div>
              <a href="/patients-all" class="text-black">
              <i class="las la-user-injured la-3x"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-3 text-white">
        <div class="card bg-success">
          <div class="card-body">
            <div class="media align-items-center">
              <div class="media-body mr-3">
                <h2 class="fs-20 text-white font-w600">
                  {{ metrics.todays_visits }}
                </h2>
                <span>Today's Patients</span>
              </div>
              <i class="las la-user-md la-2x"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-3 text-white">
        <div class="card bg-danger">
          <div class="card-body">
            <div class="media align-items-center">
              <div class="media-body mr-3">
                <h2 class="fs-20 text-white font-w600">{{ metrics.records | numeral(0, 0) }}</h2>
                <span>Records</span>
              </div>
              <a href="/all-records" class="text-white">
              <i class="las la-file-medical la-2x"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-3 text-white">
        <div class="card bg-dark">
          <div class="card-body">
            <div class="media align-items-center">
              <div class="media-body mr-3">
                <h2 class="fs-20 text-white font-w600">{{ metrics.visits }}</h2>
                <span>Patient Visits</span>
              </div>
              <i class="las la-user-secret la-2x"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
    
     

      <div class="col-md-6">
        <InOutPatientTrend />
      </div>

      <div class="col-md-6">
        <PatientsbyDepartments />
      </div>
    </div>
  </div>
</template>
    
    <script>
import InOutPatientTrend from "./InOutPatientTrend.vue";
import PatientsbyDepartments from "./PatientsbyDepartments.vue";

export default {
  components: {
    InOutPatientTrend,
    PatientsbyDepartments,
  },
  props: ["metrics"],
  data() {
    return {
      user: null,
      date: new Date(),
      checked: false,
      auth_user: "",
    };
  },
  beforeMount() {},

  methods: {},
  created() {},
};
</script>
    