<template>
  <!-- Chart Card -->
  <div class="card">
    <div class="card-body">
      <apexchart
        type="pie"
        height="200%"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>

  <!-- End of Chart -->
</template>
  
  <script>
export default {
  data() {
    return {
      series: [44, 55, 13, 43, 22, 2, 4],
      chartOptions: {
        chart: {
          //   height: 4000,
          type: "pie",
        },
        labels: [
          "Infant = 0-1 year",
          "Toddler = 2-4 yrs",
          "Child = 5-12 yrs",
          "Teen = 13-19 yrs",
          "Adult = 20-39 yrs",
          "Middle Age Adult = 40-59 yrs",
          "Senior Adult = 60+",
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],

        title: {
          text: "Distribution by Age Group",
          floating: true,
          margin: 10,
          offsetY: 0,
          align: "right",
          style: {
            color: "#444",
          },
        },
      },
    };
  },
};
</script>