import { connection } from "./jsstore_con";

export class DeptService {
  constructor() {
    this.tableName = "departments";
  }

  getDepts() {
    return connection.select({
      from: this.tableName,
    });
  }

  addDept(dept) {
    return connection.insert({
      into: this.tableName,
      values: [dept],
      return: true,
    });
  }

  getDeptId(id) {
    console.log(id);

    //filter let newarr = [1,2, 3].filter(x=> x<2)
    // return connection.select({
    //   from: this.tableName,
    //   where: {
    //     id: id
    //   },
    // });

    return this.getDepts();
  }

  removeRecord(id) {
    return connection.remove({
      from: this.tableName,
      where: {
        id: id,
      },
    });
  }
  clearTable() {
    return connection.clear("departments").then((response) => {
      console.log(response);
    });
  }
}
