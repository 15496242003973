<template>
  <div>
    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="row">
                <div class="form-group col-md-4">
                  <label>Blood Pressure (MM/HG) </label>
                  <input
                    type="text"
                    placeholder=""
                    class="form-control"
                    v-model="vital.blood_pressure"
                  />
                </div>

                <div class="form-group col-md-4">
                  <label>Weight (KG)</label>
                  <input
                    type="text"
                    placeholder=""
                    class="form-control"
                    v-model="vital.weight"
                  />
                </div>

                <div class="form-group col-md-4">
                  <label>Temperature (Celcius)</label>
                  <input
                    type="text"
                    placeholder=""
                    class="form-control"
                    v-model="vital.temperature"
                  />
                </div>


                <div class="form-group col-md-4">
                  <label>Height (FT)</label>
                  <input
                    type="text"
                    placeholder=""
                    class="form-control"
                    v-model="vital.height"
                  />
                </div>

                <div class="form-group col-md-4">
                  <label>Pulse (Bpm)</label>
                  <input
                    type="text"
                    placeholder=""
                    class="form-control"
                    v-model="vital.pulse"
                  />
                </div>
              </div>

              <button class="btn btn-success btn-block" type="submit">
                Submit <i class="las la-paper-plane"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
  
  <script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Loading,
  },
  props: ["patient"],
  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      edit: false,
      vital: {
        blood_pressure: "",
        weight: "",
        temperature: "",
        height: "",
        pulse: "",
      },
    };
  },
  beforeMount() {},
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addVitals();
      } else {
        this.updateVitals();
      }
    },

    addVitals() {
      this.isLoading = true;
      this.axios
        .post(`/staff/vitals`, {
          patient_id: this.patient.id,
          staff_id: this.$user.id,
          blood_pressure: this.vital.blood_pressure,
          weight: this.vital.weight,
          temperature: this.vital.temperature,
          height: this.vital.height,
          pulse: this.vital.pulse,
        })
        .then((response) => {
          console.log(response);
          this.$toastr.s("Vitals Added Successfully", "Success!");
          this.showVitals = false;
          this.vital.blood_pressure = "";
          this.vital.weight = "";
          this.vital.temperature = "";
          this.vital.height = "";
          this.vital.pulse = "";
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    updateService() {
      this.isLoading = true;
      this.axios
        .patch(`/staff/services/${this.service.id}`, this.service)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.clearForm();
          this.$emit("update");
          this.$toastr.s("Updated Successfully", "Error!");
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.$toastr.e("not successful", "Error!");
        });
    },

    setValue(value) {
      this.edit = true;
      this.service = value;
      console.log(value);
    },
    clearForm() {
      this.vital.id = "";
      this.vital.blood_pressure = "";
      this.vital.weight = "";
      this.vital.temperature = "";
      this.vital.height = "";
      this.vital.pulse = "";
      this.edit = false;
    },
  },
  created() {},
};
</script>
  