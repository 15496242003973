<template>
  <div class="">
    <div class="row">
      <div class="col-md-4">
        <div class="card bg-dark text-white">
          <div class="card-body">
            <div class="media align-items-center">
              <div class="media-body mr-3">
                <h2 class="fs-20 text-white font-w600">
                  {{ $selected_facility.name }}
                </h2>
                <span> {{ date | moment("dddd, MMMM Do YYYY") }} </span>
              </div>
              <i class="las la-hospital la-2x"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4 text-white">
        <div class="card bg-success">
          <div class="card-body">
            <div class="media align-items-center">
              <div class="media-body mr-3">
                <h2 class="fs-20 text-white font-w600">0</h2>
                <span>Ambulances</span>
              </div>
              <i class="las la-ambulance la-2x"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4 text-white">
        <div class="card bg-danger">
          <div class="card-body">
            <div class="media align-items-center">
              <div class="media-body mr-3">
                <h2 class="fs-20 text-white font-w600">0</h2>
                <span>Emergency Calls</span>
              </div>
              <i class="las la-phone-volume la-2x"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      user: null,
      date: new Date(),
      checked: false,
      auth_user: "",
    };
  },
  beforeMount() {},

  methods: {},
  created() {},
};
</script>
  