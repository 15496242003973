import { connection } from "./jsstore_con";
import { DATA_TYPE } from "jsstore";

const getDatabase = () => {
  //Patients table
  const tblPatients = {
    name: "patients",

    columns: {
      id: {
        primaryKey: true,
        autoIncrement: true,
      },
      first_name: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      last_name: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      middle_name: {
        notNull: false,
        unique: false,
        dataType: DATA_TYPE.String,
      },

      email: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      blood_group: {
        notNull: false,
        dataType: DATA_TYPE.String,
      },

      phone_no: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      dob: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      gender: {
        dataType: DATA_TYPE.String,
        default: "male",
      },
      genotype: {
        notNull: false,
        dataType: DATA_TYPE.String,
      },
      password: {
        dataType: DATA_TYPE.String,
        notNull: true,
      },
      card_no: {
        dataType: DATA_TYPE.String,
        notNull: false,
      },
      type: {
        dataType: DATA_TYPE.String,
        notNull: false,
      },
      created_at: {
        dataType: DATA_TYPE.DateTime,
        notNull: false,
      },
    },
  };

  //Staffs table
  const tblStaffs = {
    name: "staffs",

    columns: {
      id: {
        primaryKey: true,
        autoIncrement: true,
      },
      first_name: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      job_title: {
        notNull: true,
        dataType: DATA_TYPE.Object,
      },
      last_name: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      middle_name: {
        notNull: false,
        unique: false,
        dataType: DATA_TYPE.String,
      },

      email: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
    
      phone_no: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      dob: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      gender: {
        dataType: DATA_TYPE.String,
        default: "male",
      },
      department_id: {
        notNull: false,
        dataType: DATA_TYPE.String,
      },
      password: {
        dataType: DATA_TYPE.String,
        notNull: true,
      },
      staff_no: {
        dataType: DATA_TYPE.String,
        notNull: false,
      },
     
      created_at: {
        dataType: DATA_TYPE.DateTime,
        notNull: true,
      },
    },
  };

  //records table
  const tblRecords = {
    name: "records",
    columns: {
      id: {
        primaryKey: true,
        autoIncrement: true,
      },

      patient_card_no: {
        notNull: true,
        enableSearch: true,
        dataType: DATA_TYPE.String,
      },

      patient: {
        notNull: true,
        dataType: DATA_TYPE.Object,
      },

      is_lab: {
        notNull: true,
        dataType: DATA_TYPE.Boolean,
      },
      title: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      details: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      lab: {
        notNull: true,
        unique: false,
        dataType: DATA_TYPE.Object,
      },
      action: {
        notNull: false,
        dataType: DATA_TYPE.String,
      },
      action_id: {
        notNull: false,
        dataType: DATA_TYPE.Number,
      },
      next_id: {
        notNull: false,
        dataType: DATA_TYPE.String,
      },
    },
  };

  //departments table
  const tblDepts = {
    name: "departments",
    columns: {
      id: {
        primaryKey: true,
        autoIncrement: true,
      },

      name: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      description: {
        notNull: false,
        dataType: DATA_TYPE.String,
      },
      created_at: {
        notNull: true,
        dataType: DATA_TYPE.DateTime,
      },
    },
  };

  //cases table
  const tblCases = {
    name: "cases",
    columns: {
      id: {
        primaryKey: true,
        autoIncrement: true,
      },

      patient_card_no: {
        notNull: true,
        enableSearch: true,
        dataType: DATA_TYPE.String,
      },

      patient: {
        notNull: true,
        dataType: DATA_TYPE.Object,
      },

      case_number: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      category_id: {
        notNull: false,
        dataType: DATA_TYPE.String,
      },
      case_report: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },

      other_details: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      created_at: {
        notNull: true,
        dataType: DATA_TYPE.DateTime,
      },
    },
  };

  //services table
  const tblServices = {
    name: "services",
    columns: {
      id: {
        primaryKey: true,
        autoIncrement: true,
      },

      patient_card_no: {
        notNull: true,
        enableSearch: true,
        dataType: DATA_TYPE.String,
      },

      patient: {
        notNull: true,
        dataType: DATA_TYPE.Object,
      },

      case_number: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      category_id: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      case_report: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },

      other_details: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
    },
  };


   //bills table
   const tblBills = {
    name: "bills",
    columns: {
      id: {
        primaryKey: true,
        autoIncrement: true,
      },

      patient_card_no: {
        notNull: true,
        enableSearch: true,
        dataType: DATA_TYPE.String,
      },

      patient: {
        notNull: true,
        dataType: DATA_TYPE.Object,
      },

      case_number: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      category_id: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      case_report: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },

      other_details: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
    },
  };

  
   //wards table
   const tblWards = {
    name: "wards",
    columns: {
      id: {
        primaryKey: true,
        autoIncrement: true,
      },

      patient_card_no: {
        notNull: true,
        enableSearch: true,
        dataType: DATA_TYPE.String,
      },

      patient: {
        notNull: true,
        dataType: DATA_TYPE.Object,
      },

      case_number: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      category_id: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      case_report: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },

      other_details: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
    },
  };

  
   //beds table
   const tblBeds = {
    name: "beds",
    columns: {
      id: {
        primaryKey: true,
        autoIncrement: true,
      },

      patient_card_no: {
        notNull: true,
        enableSearch: true,
        dataType: DATA_TYPE.String,
      },

      patient: {
        notNull: true,
        dataType: DATA_TYPE.Object,
      },

      case_number: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      category_id: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      case_report: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },

      other_details: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
    },
  };

  
   //vaccinations table
   const tblVaccinations = {
    name: "vaccinations",
    columns: {
      id: {
        primaryKey: true,
        autoIncrement: true,
      },

      patient_card_no: {
        notNull: true,
        enableSearch: true,
        dataType: DATA_TYPE.String,
      },

      patient: {
        notNull: true,
        dataType: DATA_TYPE.Object,
      },

      case_number: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      category_id: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      case_report: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },

      other_details: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
    },
  };


  
   //vaccines table
   const tblVaccines = {
    name: "vaccines",
    columns: {
      id: {
        primaryKey: true,
        autoIncrement: true,
      },

      patient_card_no: {
        notNull: true,
        enableSearch: true,
        dataType: DATA_TYPE.String,
      },

      patient: {
        notNull: true,
        dataType: DATA_TYPE.Object,
      },

      case_number: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      category_id: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      case_report: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },

      other_details: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
    },
  };

  
   //blood_banks table
   const tblBloodBanks = {
    name: "blood_banks",
    columns: {
      id: {
        primaryKey: true,
        autoIncrement: true,
      },

      patient_card_no: {
        notNull: true,
        enableSearch: true,
        dataType: DATA_TYPE.String,
      },

      patient: {
        notNull: true,
        dataType: DATA_TYPE.Object,
      },

      case_number: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      category_id: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      case_report: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },

      other_details: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
    },
  };


  
   //donors table
   const tblDonors = {
    name: "donors",
    columns: {
      id: {
        primaryKey: true,
        autoIncrement: true,
      },

      patient_card_no: {
        notNull: true,
        enableSearch: true,
        dataType: DATA_TYPE.String,
      },

      patient: {
        notNull: true,
        dataType: DATA_TYPE.Object,
      },

      case_number: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      category_id: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      case_report: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },

      other_details: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
    },
  };

  
   //donations table
   const tblDonations = {
    name: "donations",
    columns: {
      id: {
        primaryKey: true,
        autoIncrement: true,
      },

      patient_card_no: {
        notNull: true,
        enableSearch: true,
        dataType: DATA_TYPE.String,
      },

      patient: {
        notNull: true,
        dataType: DATA_TYPE.Object,
      },

      case_number: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      category_id: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      case_report: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },

      other_details: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
    },
  };

  
   //bloodout table
   const tblBloodouts = {
    name: "blood_outs",
    columns: {
      id: {
        primaryKey: true,
        autoIncrement: true,
      },

      patient_card_no: {
        notNull: true,
        enableSearch: true,
        dataType: DATA_TYPE.String,
      },

      patient: {
        notNull: true,
        dataType: DATA_TYPE.Object,
      },

      case_number: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      category_id: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      case_report: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },

      other_details: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
    },
  };

  
   //bills table
   const tblInventories = {
    name: "inventories",
    columns: {
      id: {
        primaryKey: true,
        autoIncrement: true,
      },

      patient_card_no: {
        notNull: true,
        enableSearch: true,
        dataType: DATA_TYPE.String,
      },

      patient: {
        notNull: true,
        dataType: DATA_TYPE.Object,
      },

      case_number: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      category_id: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
      case_report: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },

      other_details: {
        notNull: true,
        dataType: DATA_TYPE.String,
      },
    },
  };

  const dataBase = {
    // name: "smartmedicare",
    name: "sm_db_1",
    // version: 1, // previous version was 1,
    tables: [tblPatients, tblRecords, tblCases, tblDepts, 
        tblServices, tblBills, tblStaffs,
        tblBeds, tblBills, tblBloodBanks, tblBloodouts,
        tblDonations, tblDonors, tblInventories, tblServices,
        tblVaccinations, tblVaccines, tblWards
    
    ],
  };
  return dataBase;
};

export const initJsStore = async () => {
  const dataBase = getDatabase();
//   console.log( JSON.stringify(dataBase) + 'jsstore' )
  return await connection.initDb(dataBase);
};
