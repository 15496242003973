<template>
  <div>
    <Header />
    <div class="content-body">
      <!-- row -->
      <div class="container-fluid">
        <div class="form-head d-flex mb-sm-4 mb-3">
          <div class="mr-auto">
            <h2 class="text-black">Staffs</h2>
            <p class="mb-0">Manage your Staff/Employees here</p>
          </div>

          <div class="dropdown ml-auto mr-2">
            <a
              href="#"
              class="btn btn-success light sharp"
              data-toggle="dropdown"
              aria-expanded="true"
            >
              <li class="las la-ellipsis-h scale5"></li>
            </a>
            <ul class="dropdown-menu dropdown-menu-right">
              <li class="dropdown-item">
                <span v-b-toggle.sidebar-right>
                  <a
                    v-b-toggle.sidebar-right
                    href="javascript:void()"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Add"
                    ><i class="las la-plus color-muted text-success mr-2"></i>
                  </a>
                  New Staff
                </span>
              </li>

              <li class="dropdown-item">
                <a
                  href="javascript:void()"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Export"
                  ><i
                    class="las la-file-export color-muted text-success mr-2"
                  ></i
                ></a>
                Export to CSV
              </li>
            </ul>
          </div>
        </div>
        <b-sidebar
          id="sidebar-right"
          title="Add Staff"
          right
          shadow
          width="500px"
        >
          <div class="px-3 py-2">
            <div class="modal-body">
              <form @submit.prevent="submitForm">
                <div class="form-group">
                  <label class="text-black">Title </label>
                  <select class="form-control" v-model="staff.initials">
                    <option>Mr.</option>
                    <option>Ms.</option>
                    <option>Mrs.</option>
                    <option>Dr.</option>
                    <option>Pharm.</option>
                  </select>
                </div>

                <div class="form-group">
                  <label class="text-black">Select Job Title </label>
                  <select
                    class="form-control"
                    v-model="staff.job_title_id"
                    v-if="$store.state.mode == true"
                  >
                    <option
                      v-for="role in roles"
                      :key="role.id"
                      :value="role.id"
                    >
                      {{ role.name }}
                    </option>
                  </select>

                  <select
                    class="form-control"
                    v-model="staff.job_title_id"
                    v-else
                  >
                    <option v-for="role in roles_offline" :key="role">
                      {{ role }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label class="text-black">First Name</label>
                  <input
                    required
                    type="text"
                    class="form-control"
                    v-model="staff.first_name"
                  />
                </div>

                <div class="form-group">
                  <label class="text-black">Last Name</label>
                  <input
                    required
                    type="text"
                    class="form-control"
                    v-model="staff.last_name"
                  />
                </div>

                <!-- <div class="form-group">
                  <label class="text-black">Middle Name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="staff.middle_name"
                  />
                </div> -->

                <div class="form-group">
                  <label class="text-black">Staff No.</label>
                  <input
                    required
                    type="text"
                    class="form-control"
                    v-model="staff.staff_no"
                  />
                </div>

                <!-- <div class="form-group">
                  <label class="text-black">Date of Birth</label>
                  <input type="date" class="form-control" v-model="staff.dob" />
                </div> -->

                <!-- <div class="form-group col-md-12">
                  <label for="inputPassword4">Select Gender </label>
                  <v-select
                    v-model="staff.gender"
                    class="form-control"
                    :options="genders"
                  />
                </div> -->

                <div class="form-group col-md-12">
                  <label for="inputPassword4">Select Department </label>
                  <!--   :reduce="(name) => name.id" -->
                  <v-select
                    v-model="staff.department_id"
                    class="form-control"
                    label="name"
                    :options="departments"
                  />
                </div>

                <div class="form-group">
                  <label class="text-black">Email</label>
                  <input
                    required
                    type="email"
                    class="form-control"
                    v-model="staff.email"
                  />
                </div>
                <div class="form-group">
                  <label class="text-black">Phone Number</label>
                  <input
                    required
                    type="text"
                    class="form-control"
                    v-model="staff.phone_no"
                  />
                </div>
                <div class="form-group">
                  <label class="text-black">Password </label>
                  <input
                    required
                    type="password"
                    class="form-control"
                    v-model="staff.password"
                  />
                </div>
                <div class="form-group">
                  <button type="submit" class="btn btn-success btn-block">
                    Submit <i class="las la-paper-plane"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </b-sidebar>

        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Staffs</h4>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-responsive-md">
                    <thead>
                      <tr>
                        <th class="width80"><strong>#</strong></th>
                        <th><strong>Name </strong></th>
                        <th><strong>Staff No.</strong></th>
                        <th><strong>Phone</strong></th>
                        <th><strong>E-mail</strong></th>
                        <th><strong>Job Role</strong></th>
                        <th><strong>Action</strong></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(staff, index) in staffs.data"
                        v-bind:key="staff.id"
                      >
                        <td>
                          <strong>{{ index + 1 }} </strong>
                        </td>
                        <td>
                          {{ staff.initials }} {{ staff.first_name }}
                          {{ staff.last_name }} {{ staff.middle_name }}
                        </td>
                        <td>{{ staff.staff_no }}</td>
                        <td>{{ staff.phone_no }}</td>
                        <td>{{ staff.email }}</td>
                        <td>
                          <span v-if="staff.job_title != null">
                            {{ staff.job_title.name }}
                          </span>
                        </td>
                        <td>
                          <span>
                            <a
                              :href="`/edit-staff-${staff.id}`"
                              class="mr-4 btn btn-outline-success"
                              ><i class="las la-edit"></i>
                            </a>
                            <a
                              :href="`/single-staff-${staff.id}`"
                              class="btn btn-outline-success"
                              ><i class="las la-eye"></i
                            ></a>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <nav>
                  <ul class="pagination pagination-gutter">
                    <li class="page-item page-indicator">
                      <a class="page-link" href="javascript:void()">
                        <i class="la la-angle-left"></i
                      ></a>
                    </li>
                    <li
                      :class="
                        staffs.current_page == link
                          ? 'page-item active'
                          : 'page-item'
                      "
                      v-for="link in staffs.last_page"
                      :key="link"
                    >
                      <a
                        class="page-link"
                        href="javascript:void()"
                        @click="getStaffs(link)"
                        >{{ link }}
                      </a>
                    </li>

                    <li class="page-item page-indicator">
                      <a class="page-link" href="javascript:void()">
                        <i class="la la-angle-right"></i
                      ></a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
</template>

<script>
import Header from "@/views/Header.vue";

import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin
import { reloadPage } from "@/functions/Settings";

import { StaffService } from "@/jsstore/staffs";
import { DeptService } from "@/jsstore/depts";

export default {
  components: {
    Header,
    Loading,
  },
  data() {
    return {
      jsstore: new StaffService(),
      dept_service: new DeptService(),
      edit: false,
      isLoading: true,
      fullPage: true,
      user: null,
      roles_offline: [
        "Doctor",
        "Nurse",
        "Pharmacist",
        "Laboratory Technician",
        "Receptionist",
        "Accountant",
        "Driver",
        "Cashier",
        "Records",
        "Inventory",
      ],
      staff: {
        id: "",
        email: "",
        first_name: "",
        last_name: "",
        middle_name: "",
        phone_no: "",
        dob: "1900-01-01",
        password: "",
        facility_id: this.$selected_facility.id,
        department_id: "",
        gender: "None Selected",
        initials: "",
        staff_no: "",
      },
      staffs: "",
      roles: "",
      departments: "",
      genders: ["Male", "Female"],
    };
  },
  beforeCreate() {
    reloadPage();
  },
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addStaff();
      } else {
        this.updateStaff();
      }
    },

    addStaff() {
      if (this.$store.state.mode == false) {
        this.addFormOffline();
      } else {
        this.addStaffOnline();
      }
    },
    addStaffOnline() {
      this.staff.department_id = this.staff.department_id.id;
      this.isLoading = true;
      this.axios
        .post(`/staff/auth/register`, this.staff)
        .then((response) => {
          this.$toastr.s("Staff Created Successfully", "Success!");
          console.log(response);
          this.clearform();
          this.getStaffs();
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.$toastr.e("Not added", "Error!");
        });
    },

    async addFormOffline() {
      try {
        this.staff.created_at = new Date();
        this.staff.department_id = this.staff.department_id.name;
        this.staff.job_title = { name: this.staff.job_title_id };

        let query = await this.jsstore.addStaff(this.staff);
        console.log(JSON.stringify(query) + "jsstore");
        this.$toastr.s("Staff added Successfully", "Success!");
        this.clearform();
        this.getStaffs();
      } catch (ex) {
        this.$toastr.e(`${ex.message}`, "Error!");
      }
    },

    getStaffs(page) {
      if (this.$store.state.mode == false) {
        this.getStaffOffline();
      } else {
        this.getStaffsOnline(page);
      }
    },
    async getStaffOffline() {
      let query = await this.jsstore.getStaffs();
      this.staffs = {
        data: query,
      };
    },
    getStaffsOnline(page) {
      this.isLoading = true;
      this.axios
        .get(`/staff/profile`, {
          params: {
            page: page,
          },
        })
        .then((response) => {
          this.staffs = response.data.data;
          this.isLoading = false;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          // this.$toastr.e("Server Error, Refresh page", "Error!");
        });
    },
    editStaff(staff) {
      this.edit = true;
      this.staff.id = staff.id;
      this.staff.initials = staff.initials;
      this.staff.email = staff.email;
      this.staff.first_name = staff.first_name;
      this.staff.last_name = staff.last_name;
      this.staff.middle_name = staff.middle_name;
      this.staff.staff_no = staff.staff_no;
      this.staff.dob = staff.dob;
      this.staff.gender = staff.gender;
      this.staff.picture = staff.picture;
      this.staff.phone_no = staff.phone_no;
      this.staff.password = staff.password;
      this.staff.job_title_id = staff.job_title_id;
    },
    updateStaff(staff) {
      this.isLoading = true;
      this.axios
        .patch(`/staff/profile/${staff.id}`, this.staff)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.getStaffs();
          this.$toastr.s("Staff Updated Successfully", "Success!");
          this.clearform();
        })
        .catch((error) => {
          console.error(error);
          this.$toastr.s("Not Added", "Error!");
          this.isLoading = false;
        });
    },
    getRoles() {
      this.isLoading = false;

      this.axios
        .get(`/staff/all-job-titles/${this.$selected_facility.id}`)
        .then((response) => {
          this.roles = response.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },

    getDepts() {
      if (this.$store.state.mode == false) {
        this.getDeptsOffline();
      } else {
        this.getDeptsOnline();
      }
    },

    getDeptsOnline() {
      this.isLoading = false;
      this.axios
        .get(`/staff/departments`)
        .then((response) => {
          this.departments = response.data.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    async getDeptsOffline() {
      let query = await this.dept_service.getDepts();
      this.departments = query;
    },
    clearform() {
      this.edit = false;
      this.staff.id = "";
      this.staff.email = "";
      this.staff.first_name = "";
      this.staff.last_name = "";
      this.staff.picture = "";
      this.staff.department_id = "";
      this.staff.phone_no = "";
      this.staff.password = "";
      this.staff.job_title_id = "";
    },
  },
  created() {
    this.getStaffs();
    this.getRoles();
    this.getDepts();
  },
};
</script>
