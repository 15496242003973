<template>
  <!-- Chart Card -->
  <div class="card">
    <div class="card-body">
      <apexchart
        type="bar"
        height="300"
        :options="options"
        :series="series"
      ></apexchart>
    </div>
  </div>

  <!-- End of Chart -->
</template>
  
  <script>
export default {
  data() {
    return {
      series: [
        {
          name: "Blood",
          data: [9, 1, 4, 3, 9, 0, 1],
        },
      ],
      options: {
        chart: {
          id: "emr-blood",
        },
        xaxis: {
          categories: [
            "Monday 10th",
            "Tuesday 9th",
            "Wednesday 8th",
            "Thursday 7th",
            "Friday 6th",
            "Saturday 5th",
            "Sunday 4th",
          ],
        },

        colors: ["#097969"],
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: 0,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        title: {
          text: "Blood Group by Date",
          floating: true,
          margin: 10,
          offsetY: 5,
          align: "center",
          style: {
            color: "#444",
          },
        },
      },
    };
  },
};
</script>