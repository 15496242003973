<template>
  <div>
    <Header />

    <div class="content-body">
      <div class="container-fluid">
        <div class="form-head d-flex mb-sm-4 mb-3">
          <div class="mr-auto">
            <h2 class="text-black font-w600">Finance Dashboard</h2>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3 text-white">
            <div class="card bg-success">
              <div class="card-body">
                <div class="media align-items-center">
                  <div class="media-body mr-3">
                    <h2 class="fs-20 text-white font-w600">
                      <i class="mdi mdi-currency-ngn mr-n2"></i> 146,090
                    </h2>
                    <span>Revenue</span>
                  </div>
                  <i class="las la-coins la-2x"></i>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3 text-white">
            <div class="card bg-danger">
              <div class="card-body">
                <div class="media align-items-center">
                  <div class="media-body mr-3">
                    <h2 class="fs-20 text-white font-w600">
                      <i class="mdi mdi-currency-ngn mr-n2"></i> 1,400
                    </h2>
                    <span>Expenses</span>
                  </div>
                  <i class="las la-money-bill la-2x"></i>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="card bg-dark text-white">
              <div class="card-body">
                <div class="media align-items-center">
                  <div class="media-body mr-3">
                    <h2 class="fs-20 text-white font-w600">
                      <i class="mdi mdi-currency-ngn mr-n2"></i>
                      52,000
                    </h2>
                    <span>Current Month Rev. </span>
                  </div>
                  <i class="las la-wallet la-2x"></i>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                <div class="media align-items-center">
                  <div class="media-body mr-3">
                    <h2 class="fs-20 text-black font-w600">
                      <i class="mdi mdi-currency-ngn mr-n2"></i> 120,000
                    </h2>
                    <span>Staff Payroll</span>
                  </div>
                  <i class="las la-money-bill-wave la-2x"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <RevenueExpenseMonthly />
          </div>

          <div class="col-md-6">
            <RevenueByChannel />
          </div>

          <div class="col-md-6">
            <RevenueGrowth />
          </div>

          <div class="col-md-6">
            <ExpensesReasons />
          </div>
        </div>

        <div class="vld-parent">
          <loading
            :active.sync="isLoading"
            loader="spinner"
            :can-cancel="true"
            :is-full-page="fullPage"
          ></loading>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Header from "@/views/Header.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import RevenueExpenseMonthly from "./charts/RevenueExpenseMonthly.vue";
import RevenueByChannel from "./charts/RevenueByChannel.vue";
import RevenueGrowth from "./charts/RevenueGrowth.vue";
import ExpensesReasons from "./charts/ExpensesReasons.vue";
// Init plugin

import { reloadPage } from "@/functions/Settings";

export default {
  components: {
    Header,
    Loading,
    RevenueExpenseMonthly,
    RevenueByChannel,
    RevenueGrowth,
    ExpensesReasons,
  },
  data() {
    return {
      user: null,
      isLoading: false,
      fullPage: true,
      showForm: false,
      edit: false,
    };
  },
  beforeCreate() {
     reloadPage();
    
  },
  beforeMount() {},
  methods: {
    getInventories() {
      this.isLoading = true;
      this.axios
        .get(`/inventory`)
        .then((response) => {
          this.inventories = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
  },
  created() {},
};
</script>
  