<template>
  <div>
    <Header />

    <div class="content-body">
      <div class="container-fluid">
        <div class="form-head d-flex mb-sm-4 mb-3">
          <div class="mr-auto">
            <h2 class="text-black font-w600">Diagnosis Manager</h2>
            <p class="mb-0">Manage all Diagnoses here</p>
          </div>
          <div>
           

            <div class="dropdown ml-auto mr-2">
                      <a
                        href="#"
                        class="btn btn-success light sharp"
                        data-toggle="dropdown"
                        aria-expanded="true"
                        >
                        <li class="las la-ellipsis-h scale5"></li>
                        </a>
                      <ul class="dropdown-menu dropdown-menu-right">
                       
                        <li class="dropdown-item">
                          <span  v-b-toggle.sidebar-right>
                            <a
                            href="javascript:void()"
                           
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Add"
                            @click="showForm = !showForm"
                            ><i class="las la-plus text-success mr-2"></i>
                          </a>
                          New Diagnosis

                          </span>
                         
                        </li>

                        <li class="dropdown-item">
                          <span v-b-toggle.sidebar-category>
                            <a
                            href="javascript:void()"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Category"
                            >
                            <i class="lab la-buffer text-success mr-2"></i>
                          </a>
                          Manage Category

                          </span>
                         
                        </li>

                       

                        <li class="dropdown-item">
                          <a
                            href="javascript:void()"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Export"
                            ><i class="las la-file-export color-muted text-success mr-2"></i
                          ></a>
                          Export to CSV
                        </li>
                                              
                       
                       
                      </ul>
                    </div>
          </div>
        </div>
        <b-sidebar
          id="sidebar-right"
          title="New Bill"
          right
          shadow
          width="650px"
        >
          <div class="px-3 py-2">
            <AddDiagnosis  />
          </div>
        </b-sidebar>

        <b-sidebar
          id="sidebar-category"
          title="Manage Category"
          right
          shadow
          width="650px"
        >
          <div class="px-3 py-2">
            <ManageDiagnosisCategory />
          </div>
        </b-sidebar>

        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Diagnoses</h4>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-responsive-md">
                    <thead>
                      <tr>
                        <th class="width80"><strong>#</strong></th>
                        <th><strong>Title</strong></th>
                        <th><strong>Description</strong></th>
                        <th><strong>Amount</strong></th>
                        <th><strong>Created At</strong></th>
                        <th><strong>Action</strong></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(bill, index) in bills.data"
                        :key="bill.Id"
                      >
                        <td>
                          <strong> {{ index + 1 }} </strong>
                        </td>
                        <td>{{ bill }}</td>
                        <td>{{ bill.Description }}</td>
                        <td>{{ bill.Amount | numeral(0, 0) }}</td>
                        <td>{{ bill.Created | moment("from", "now") }}</td>
                        <td>
                          <span>
                            <a
                              href="javascript:void()"
                              class="mr-4"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Edit"
                              @click="updateRole(role)"
                              ><i
                                class="fa fa-pencil color-muted text-success"
                              ></i>
                            </a>
                            <a
                              href="javascript:void()"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Close"
                              @click="deleteRole(role)"
                              ><i
                                class="fa fa-close color-danger text-success"
                              ></i
                            ></a>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="vld-parent">
          <loading
            :active.sync="isLoading"
            loader="spinner"
            :can-cancel="true"
            :is-full-page="fullPage"
          ></loading>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Header from "@/views/Header.vue";
import AddDiagnosis from "@/views/patients/AddDiagnosis.vue";
import ManageDiagnosisCategory from "@/views/patients/ManageDiagnosisCategory.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Header,
    Loading,
    AddDiagnosis, ManageDiagnosisCategory
  },
  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      showForm: false,
      showCategory: false,
      edit: false,
      bills: "",
      bed: {
        Title: "",
        Description: "",
        Acts: "",
      },
    };
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.selected_facility = JSON.parse(
      localStorage.getItem("selected_facility")
    );
  },
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addDept();
      } else {
        this.editDept();
      }
    },
    
   
    getBills() {
      this.isLoading = true;
      this.selected_facility = JSON.parse(
        localStorage.getItem("selected_facility")
      );

      this.axios
        .get(`/bills`)
        .then((response) => {
          this.services = response.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    deleteRole(bill) {
      if (confirm("Are you Sure you want to delete?")) {
        this.isLoading = true;
        this.axios
          .patch(`/bill/${bill.Id}`)
          .then((response) => {
            console.log(response);
            this.isLoading = false;
            this.getRoles();
            this.isLoading = false;
            this.$toasted.success("Deleted Successfully!", {
              position: "top-center",
              duration: 3000,
            });
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
          });
      }
    },
  },
  created() {
    this.getBills();
  },
};
</script>
  