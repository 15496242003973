<template>
  <div>
    <div class="col-md-12">
      <div class="">
        <div class="settings-form">
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label> Select Product</label>
                  <v-select
                    v-model="item.product_id"
                    :reduce="(name) => name.id"
                    label="name"
                    :options="products.data"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Quantity</label>
                  <input
                    type="number"
                    placeholder="20"
                    class="form-control"
                    v-model="item.quantity"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label>Expiry Date</label>
                  <input
                    type="date"
                    placeholder="12/12/2023"
                    class="form-control"
                    v-model="item.expiry_date"
                  />
                </div>
              </div>

              <button class="btn btn-success btn-block" type="submit">
                Submit <i class="las la-paper-plane"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
</template>
  
  <script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Loading,
  },
  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      edit: false,
      products: "",
      item: {
        id: "",
        product_id: "",
        quantity: "",
        staff_id: this.$user.id,
        expiry_date: "",
        date_time: "",
      },
    };
  },
  beforeMount() {},
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addItem();
      } else {
        this.updateItem();
      }
    },
    addItem() {
      this.isLoading = true;
      this.axios
        .post("/staff/pharmacy-stock", this.item)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.clearForm();
          this.$toastr.s("Stock Added Successfully", "Success!");
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");
          this.isLoading = false;
        });
    },
    updateItem() {
      this.isLoading = true;
      this.axios
        .patch(`/staff/pharmacy-stock/${this.item.id}`, this.item)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toastr.s("Stock Added Successfully", "Success!");
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");
          this.isLoading = false;
        });
    },
    clearForm() {
      this.item.product_id = "";
      this.item.quantity = "";
      this.item.expiry_date = "";
      this.item.date_time = "";
      this.edit = false;
    },
    getProducts() {
      this.isLoading = true;
      this.axios
        .get("/staff/products")
        .then((response) => {
          console.log(response);
          this.products = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");
          this.isLoading = false;
        });
    },
  },
  created() {
    this.getProducts();
  },
};
</script>
  