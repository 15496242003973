<template>
  <div>
    <Header />

    <div class="content-body">
      <div class="container-fluid">
        <div class="form-head d-flex mb-sm-4 mb-3">
          <div class="mr-auto">
            <h2 class="text-black font-w600">Roles Manager</h2>
            <p class="mb-0">Manage User roles here</p>
          </div>
          <div>
            <a
              href="javascript:void(0)"
              class="btn btn-success mr-3"
              @click="showForm = !showForm"
              v-b-toggle.sidebar-right
              ><i class="las la-plus scale5 mr-3"></i> Add Role</a
            >
          </div>
        </div>
        <b-sidebar
          id="sidebar-right"
          title="New Role"
          :visible="showSidebar"
          right
          shadow
          width="500px"
        >
          <div class="px-3 py-2">
            <AddRole ref="AddRole" @update="onUpdate($event)" />
          </div>
        </b-sidebar>

        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Roles</h4>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-responsive-md">
                    <thead>
                      <tr>
                        <th class="width80"><strong>#</strong></th>
                        <th><strong>Title</strong></th>
                        <th><strong>Description</strong></th>
                        <th><strong>Created Date</strong></th>
                        <th><strong>Action</strong></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(role, index) in roles" :key="role.id">
                        <td>
                          <strong> {{ index + 1 }} </strong>
                        </td>
                        <td>
                          <a :href="`/role-${role.id}`" class="mr-4 text-success"
                              >
                          {{ role.name }}
                          </a>
                        </td>
                        <td>{{ role.description }}</td>
                        <td>{{ role.created_at | moment("from", "now") }}</td>
                        <td>
                          <span>
                            <a :href="`/role-${role.id}`" class="mr-4"
                              ><i
                                class="fa fa-eye color-muted text-success"
                              ></i>
                            </a>

                            <a
                              href="javascript:void()"
                              class="mr-4"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Edit"
                              v-b-toggle.sidebar-right
                              @click="startUpdate(role)"
                              ><i
                                class="fa fa-pencil color-muted text-success"
                              ></i>
                            </a>
                            <!-- <a
                              href="javascript:void()"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Close"
                              @click="deleteRole(role)"
                              ><i
                                class="fa fa-close color-danger text-success"
                              ></i
                            ></a> -->
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="vld-parent">
          <loading
            :active.sync="isLoading"
            loader="spinner"
            :can-cancel="true"
            :is-full-page="fullPage"
          ></loading>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/views/Header.vue";
import AddRole from "@/views/roles/AddRole.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Header,
    Loading,
    AddRole,
  },

  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      showSidebar: false,
      faisal: "my name is Loe",
      edit: false,
      roles: "",
      singleRole: "",
      form_data: {
        Title: "",
        Description: "",
        Acts: "",
      },
    };
  },
  beforeCreate() {
  },
  beforeMount() {},
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addDept();
      } else {
        this.editDept();
      }
    },
    addRole() {
      this.isLoading = true;
      this.axios
        .post("/roles", {
          Title: this.form_data.Title,
          Acts: this.form_data.Acts,
          Description: this.form_data.Description,
        })
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toastr.s("Role Created Successfully", "Success!");
          this.getRoles();
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");

          this.isLoading = false;
        });
    },

    onUpdate() {
      this.getRoles();
      this.showSidebar = !this.showSidebar;
    },

    getRoles() {
      this.isLoading = true;
      this.axios
        .get(`/staff/all-job-titles/${this.$selected_facility.id}`)
        .then((response) => {
          this.roles = response.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    deleteRole(role) {
      if (confirm("Are you Sure you want to delete?")) {
        this.isLoading = true;
        this.axios
          .patch(`/roles/${role.Id}`)
          .then((response) => {
            console.log(response);
            this.isLoading = false;
            this.getRoles();
            this.isLoading = false;
            this.$toasted.success("Deleted Successfully!", {
              position: "top-center",
              duration: 3000,
            });
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
          });
      }
    },
    startUpdate(role) {
      this.showForm = true;
      this.$refs.AddRole.setValue(role);
      console.log(role);
    },
  },
  created() {
    this.getRoles();
  },
};
</script>
