<template>
  <div>
    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="inputPassword4">Select Patient? </label>
                  <v-select
                    v-model="blood.patient_id"
                    :reduce="(first_name) => first_name.id"
                    label="first_name"
                    :options="patients.data"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="inputPassword4">Select Blood Group? </label>
                  <v-select
                    v-model="blood.blood_group_id"
                    :reduce="(blood_group) => blood_group.id"
                    label="blood_group"
                    :options="bloods.data"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Number of Bags</label>
                  <input
                    type="number"
                    placeholder="1"
                    class="form-control"
                    v-model="blood.bags"
                  />
                </div>
              </div>

              <button class="btn btn-success btn-block" type="submit">
                Submit <i class="las la-paper-plane"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
    
    <script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Loading,
  },
  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      edit: false,
      patients: "",
      bloods: "",
      blood: {
        facility_id: this.$selected_facility.id,
        blood_group_id: "",
        patient_id: "",
        bags: "",
      },
    };
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    getGroups() {
      this.isLoading = true;

      this.axios
        .get(`/staff/blood-groups`)
        .then((response) => {
          this.bloods = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    submitForm() {
      if (this.edit == false) {
        this.addDept();
      } else {
        this.editDept();
      }
    },
    addDept() {
      this.isLoading = true;
      this.axios
        .post("/staff/blood-out", this.blood)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toastr.s("Blood Given out Successfully", "Success!");
          // this.getDepts();
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");

          this.isLoading = false;
        });
    },
    updateDept() {
      //
    },

    getPatients() {
      this.isLoading = true;
      this.axios
        .get(`/staff/patients`)
        .then((response) => {
          this.patients = response.data.data;
          this.isLoading = false;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.getPatients();
    this.getGroups();
  },
};
</script>
    