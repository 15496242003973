<template>
  <div>
    <!-- <div class="content-body"> -->
    <!-- <div class="container-fluid"> -->

    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <!-- <div class="card-header">
                  <h6 class="text-success">Create roles for Staffs</h6>
                </div> -->
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Invoice Date</label>
                  <input
                    type="date"
                    placeholder="20/20/20"
                    class="form-control"
                    v-model="form_data.Title"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label for="inputPassword4">Select Patient </label>
                  <v-select
                    v-model="form_data.WardId"
                    :reduce="(Title) => Title.Id"
                    label="FullName"
                    :options="patients.data"
                  />
                </div>
              </div>

            

              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Discount(%) </label>
                  <input
                    type="text"
                    placeholder="In Percentage"
                    class="form-control"
                    v-model="form_data.Amount"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label for="inputPassword4">Status</label>
                  <v-select
                    v-model="form_data.WardId"
                    label="Title"
                    :options="statuses"
                  />
                </div>
              </div>

              <div class="col-md-12 mb-3 mt-3">
                <span class="h4 align-left">ITEMS 
                
              </span>
              
                <a class="btn btn-outline-success float-right" @click="addArray">
                Add <i class="las la-plus"></i>
                </a>
                
              </div>
              

              <div class="form-row"  v-for="(service_renderred,index) in form_data.services"
                    v-bind:key="service_renderred">
                <div class="form-group col-md-4">
                  <label for="inputPassword4">{{index+1}}. Service Item </label>
                  <v-select
                    v-model="service_renderred.serviceId"
                    label="Title"
                    :options="services.data"
                  />
                </div>
                <div class="form-group col-md-1">
                  <label>Qty </label>
                  <input
                    type="number"
                    placeholder=""
                    class="form-control"
                    v-model="service_renderred.Quantity"
                  />
                </div>

                <div class="form-group col-md-3">
                  <label>Price </label>
                  <input
                    type="number"
                    placeholder="1,000"
                    class="form-control"
                    v-model="service_renderred.Price"
                  />
                </div>

                <div class="form-group col-md-3">
                  <label>Amount </label>
                  <input disabled
                    type="number"
                    placeholder="0.00"
                    class="form-control"
                    v-model="service_renderred.Amount"
                  />
                </div>

                <div class="form-group col-md-1">
                  <a @click="reduceArray(index)"  class="btn btn-outline-danger btn-sm" style="margin-top:40px">X</a>

                </div>
                <hr>
             
             
              </div>

              <button class="btn btn-success btn-block" type="submit">
                Submit <i class="las la-paper-plane"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
  
  <script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Loading,
  },
  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      edit: false,
      patients: "",
      services: "",
      form_data: {
        Title: "",
        Amount: "",
        Description: "",
        services: [
          {
            ServiceId: "",
            quantity: "",
            Amount: "",
          },
        ]

        
      },
      statuses: ["Pending", "Paid"],
    };
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.selected_facility = JSON.parse(
      localStorage.getItem("selected_facility")
    );
  },
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addDept();
      } else {
        this.editDept();
      }
    },
    addDept() {
      this.isLoading = true;
      var formData = new FormData();
      formData.append("Title", this.form_data.Title);
      formData.append("Amount", this.form_data.Amount);
      formData.append("Description", this.form_data.Description);

      this.axios
        .post("/services", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toastr.s("Invoice Created Successfully", "Success!");
          // this.getDepts();
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");

          this.isLoading = false;
        });
    },
    updateDept() {
      //
    },

    getPatients() {
      this.isLoading = true;
      this.selected_facility = JSON.parse(
        localStorage.getItem("selected_facility")
      );

      this.axios
        .get(`/patients`)
        .then((response) => {
          this.patients = response.data;
          this.isLoading = false;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },

    getServices() {
      this.isLoading = true;
      this.axios
        .get(`/services`)
        .then((response) => {
          this.services = response.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    addArray() {
      this.form_data.services.push({
        ServiceId: "",
        Quantity: "",
        Amount: "0.00",
      });
    },

    reduceArray(index) {
      // this.form_data.services.pop();
      this.form_data.services.splice( index, 1)

    },

  },
  created() {
    this.getPatients();
    this.getServices();
  },
};
</script>
  