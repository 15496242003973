<template>
  <div>
    <Header />

    <!--**********************************
              Content body start
          ***********************************-->
    <div class="content-body" id="printDiv">
      <div class="container-fluid">
        <div class="row">
          <div class="page-titles">
            <h4 v-if="record.action != null">
              Record: {{ record.action.description }}
            </h4>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="javascript:void(0)">Record</a>
              </li>
              <li class="breadcrumb-item active">
                <a href="javascript:void(0)"> RXD000{{ record.id }}</a>
              </li>
            </ol>
          </div>
          <!-- row -->
          <div class="row">
            <div class="col-md-4 col-lg-4 col-sm-12">
              <PatientCard :patient="patient" />
            </div>
            <div class="col-md-8">
              <div class="card">
                <div class="card-body">
                  <div class="ml-0 ml-sm-4 ml-sm-0" ref="printNow">
                    <div class="row">
                      <div class="col-12">
                        <div class="right-box-padding">
                          <div class="read-content">
                            <div
                              class="media pt-3 d-sm-flex d-block justify-content-between"
                            >
                              <div class="clearfix mb-3 d-flex">
                                <div class="media-body mr-2">
                                  <h5 class="text-success mb-0 mt-1">
                                    By: {{ record.staff.initials }}
                                    {{ record.staff.first_name }}
                                    {{ record.staff.last_name }}
                                  </h5>
                                  <p class="mb-0">
                                    {{
                                      record.created_at
                                        | moment("MMMM Do YYYY, h:mm:ss a")
                                    }}
                                  </p>
                                  <p>
                                    ({{
                                      record.created_at | moment("from", "now")
                                    }})
                                  </p>
                                </div>
                              </div>
                              <div class="clearfix mb-3">
                                <a
                                  href="javascript:void()"
                                  @click="printMe"
                                  class="btn btn-primary px-3 light"
                                  ><i class="las la-print"></i>
                                </a>
                                <a
                                  :href="`/specialized-${$route.params.id}`"
                                  class="btn btn-primary my-2 px-3 light ml-2"
                                >
                                  <i class="las la-id-card"></i>
                                </a>
                                <a
                                  :href="`/add-record-${patient.id}`"
                                  class="btn btn-primary px-3 light ml-2"
                                  ><i class="las la-edit"></i>
                                </a>

                               

                               
                                <a
                                  href="javascript:void()"
                                  v-b-toggle.sidebar-bill
                                  class="btn btn-primary my-2 px-3 light ml-2"
                                >
                                  <i class="las la-credit-card"></i>
                                </a>

                                <a
                                  href="javascript:void()"
                                  data-toggle="modal"
                                  data-target="#addVisit"
                                  class="btn btn-primary my-2 px-3 light ml-2"
                                >
                                  <i class="las la-exchange-alt"></i>
                                </a>
                              </div>
                            </div>

                            <div class="read-content-body">
                              <hr />

                              <h5 class="mb-4">
                                <strong> Prescriptions </strong>
                              </h5>

                              <p
                                class="mb-2"
                                v-for="(pres, index) in JSON.parse(
                                  record.prescriptions
                                )"
                                :key="pres"
                              >
                                {{ index + 1 }}). {{ pres.dose }}
                                {{ pres.drug }}, {{ pres.frequency }} times for
                                {{ pres.days }} days
                              </p>

                              <hr />

                            
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Transfer Visit Modal -->
    <div class="modal fade" id="addVisit">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Next Step</h5>
            <button type="button" class="close" data-dismiss="modal">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="transferPatient">
              <div class="form-group">
                <label class="text-black font-w500">Direct Patient to ? </label>
                <select class="form-control" v-model="visit.action_id">
                  <option
                    v-for="activity in activities"
                    v-bind:key="activity.id"
                    :value="activity.id"
                  >
                    {{ activity.description }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <button type="submit" class="btn btn-success btn-block">
                  Submit & Proceed
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

   

    <b-sidebar
      id="sidebar-bill"
      title="Bill Patient"
      right
      shadow
      width="800px"
    >
      <div class="px-3 py-2">
        <AddBill :patient="patient" />
      </div>
    </b-sidebar>

   

    <!--**********************************
              Content body end
          ***********************************-->

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
</template>
  
  <script>
import Header from "@/views/Header.vue";
import RecordsJSON from "@/views/records.json";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin
import PatientCard from "@/views/records/PatientCard.vue";
// import AddLaboratoryTest from "../patients/AddLaboratoryTest.vue";
import AddBill from "@/views/finances/AddBill.vue";
// import MakeAppointment from "@/views/patients/MakeAppointment.vue";

export default {
  components: {
    Header,
    Loading,
    PatientCard,
    // AddLaboratoryTest,
    AddBill,
    // MakeAppointment,
  },
  data() {
    return {
      user: null,
      isLoading: false,
      fullPage: true,
      records_json: RecordsJSON,
      patient: {},
      activities: "",
      specialized_prescription: {
        table_data: [],
        examination: {},
        ophthalmic: {},
      },
      record: "",
      visit: {
        action_id: "",
      },
    };
  },
  beforeMount() {},
  methods: {
    getRecord() {
      this.axios
        .get(`/staff/records/${this.$route.params.id}`)
        .then((response) => {
          this.record = response.data.data;
          this.patient = response.data.data.patient;
          this.specialized_prescription = JSON.parse(
            response.data.data.specialized_prescription
          );
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getActivities() {
      this.isLoading = true;
      this.axios
        .get(`/staff/all-actions/${this.$selected_facility.id}`)
        .then((response) => {
          this.activities = response.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    transferPatient() {
      //change visit status
      this.axios
        .post(`/staff/update-visit/status`, {
          id: this.patient.latest_visit.id,
          action_id: this.visit.action_id,
          status: "active",
          finished: false,
        })
        .then((response) => {
          console.log(response);
          this.$toastr.s("Patient Moved Successfully", "Success!");
          this.visit.action_id = "";
        })
        .catch((error) => {
          console.error(error);
        });

      //End of Vist Status
    },

    printMe() {
      var printContents = document.getElementById("printDiv").innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    },

    updateDate() {
      let toBeFiltered = this.records_json;

      let filtered = toBeFiltered.filter((x) => x.id == this.$route.params.id);
      let record = filtered[0];

      this.axios
        .post(`/staff/update/record/date`, {
          id: record.id,
          date: record.created_at,
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  created() {
    this.getRecord();
    this.getActivities();
  },
};
</script>
  <style scoped></style>
  