<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-row float-right">
          <div class="form-group mr-2">
            <label for="">From</label>
            <input
              type="date"
              v-model="from"
              class="form-control input-rounded"
              placeholder=""
            />
          </div>

          <div class="form-group">
            <label for="">To</label>
            <input
              type="date"
              @change="getVisits"
              v-model="to"
              class="form-control input-rounded"
              placeholder=""
            />
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="card">
          <div class="card-header pt-4 pb-4">
            <p class="card-title h5"> {{ visits.total }} Patient Visits</p>
            <download-excel
              class="btn btn-success"
              :data="visits.data"
              :fields="json_fields"
              type="xls"
              :escapeCsv="false"
              :name="'Visits'"
            >
            </download-excel>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-responsive-md">
                <thead>
                  <tr>
                    <th><strong>#</strong></th>
                    <th><strong>Patient</strong></th>
                    <th><strong>Hospital No.</strong></th>
                    <!-- <th><strong>Action</strong></th> -->
                    <th><strong>Date</strong></th>

                    <th><strong>Status</strong></th>
                    <th><strong>Action</strong></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(visit, index) in visits.data"
                    v-bind:key="visit.id"
                  >
                    <td>
                      <strong>{{ index + 1 }} </strong>
                    
                    </td>
                    <td>{{ visit.patient.full_name }}</td>
                   
                    <td>{{ visit.patient.card_no }}</td>
                    <!-- <td>{{ visit.action.description }}</td> -->
                    <td>
                      {{ visit.created_at | moment("MMMM Do YYYY, h:mm:ss a") }}
                    </td>
                    <td>
                      <a
                        href="javascript:void()"
                        :class="
                          visit.finished == 1
                            ? 'btn btn-success btn-sm px-3 light ml-2'
                            : 'btn btn-primary btn-sm px-3 light ml-2'
                        "
                      >
                        {{ visit.status }}
                      </a>
                    </td>
                    <td>
                      <span>
                        <a
                          class="mr-2 btn-sm btn btn-success"
                          @click="updateVisit(visit)"
                          v-if="visit.finished != 1"
                        >
                          end visit
                        </a>
                        <!-- <router-link :to="`/visit/${visit.id}`">
                          <i class="fa fa-eye color-danger text-success"></i>
                        </router-link> -->
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <nav>
              <ul class="pagination pagination-gutter">
                <li class="page-item page-indicator">
                  <a class="page-link" href="javascript:void()">
                    <i class="la la-angle-left"></i
                  ></a>
                </li>
                <li
                  :class="
                    visits.current_page == link
                      ? 'page-item active'
                      : 'page-item'
                  "
                  v-for="link in visits.last_page"
                  :key="link"
                >
                  <a
                    class="page-link"
                    href="javascript:void()"
                    @click="getVisits(link)"
                    >{{ link }}
                  </a>
                </li>

                <li class="page-item page-indicator">
                  <a class="page-link" href="javascript:void()">
                    <i class="la la-angle-right"></i
                  ></a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      fullPage: true,
      visits: "",
      from: "",
      to: "",
      json_fields: {
        Patient: "patient.full_name",
        "Patient Card No": "patient.card_no",
        Date: "created_at",
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
    };
  },
  methods: {
    getVisits(page) {
      this.isLoading = true;
      this.axios
        .get(`/staff/facility-visits/${this.$selected_facility.id}`, {
          params: {
            from: this.from,
            to: this.to,
            page: page,
          },
        })
        .then((response) => {
          this.visits = response.data;
          this.isLoading = false;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },

    updateVisit(visit) {
      this.isLoading = true;
      this.axios
        .post(`/staff/update-visit/status`, {
          id: visit.id,
          action_id: visit.action_id,
          status: "completed",
          finished: 1,
        })
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toasted.success("Updated Successfully!", {
            position: "top-center",
            duration: 3000,
          });
          this.getVisits(1);
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.getVisits();
  },
};
</script>
