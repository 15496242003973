<template>
  <div>
    <Header />
    <div class="content-body">
      <!-- row -->
      <div class="container-fluid">
        <div class="form-head d-flex align-items-center mb-sm-4 mb-3">
          <div class="mr-auto">
            <h2 class="text-black font-w600" v-if="$selected_facility != null">
              {{ $selected_facility.title }} Dashboard
            </h2>
            <p class="mb-0">Welcome {{ $store.state.user.name}},</p>
          </div>

          <a href="/add-facility" class="btn btn-outline-success mr-3"
            ><i class="las la-clinic-medical scale5 mr-3"></i>Manage
            Facility</a
          >
        </div>

      

        <div class="row">
          <div class="col-xl-3 col-sm-6">
            <div class="card bg-success text-white">
              <div class="card-body">
                <div class="media align-items-center">
                  <div class="media-body mr-3">
                    <h2 class="fs-34 text-white font-w600">
                      {{ metrics.patients | numeral(0, 0) }}
                    </h2>
                    <span>Total Patients</span>
                  </div>
                  <a href="/patients-all" class="text-white">
                  <i class="las la-user-injured la-3x"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6">
            <div class="card bg-dark text-white">
              <div class="card-body">
                <div class="media align-items-center">
                  <div class="media-body mr-3">
                    <h2 class="fs-34 text-white font-w600">
                      {{ metrics.staffs | numeral(0, 0) }}
                    </h2>
                    <span>Total Staff</span>
                  </div>
                  <i class="las la-user-tie la-3x"></i>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-sm-6">
            <div class="card">
              <div class="card-body">
                <div class="media align-items-center">
                  <div class="media-body mr-3">
                    <h2 class="fs-34 text-black font-w600">
                      &#8358; {{ metrics.earnings | numeral(0, 0.0) }}
                    </h2>
                    <span>Hospital Earnings</span>
                  </div>

                  <i class="las la-coins la-3x"></i>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-sm-6">
            <div class="card bg-info text-dark">
              <div class="card-body">
                <div class="media align-items-center">
                  <div class="media-body mr-3">
                    <h2 class="fs-34 text-dark font-w600">
                      {{ metrics.records | numeral(0, 0) }}
                    </h2>
                    <span>Patient Records</span>
                  </div>
                  <a href="/all-records" class="text-black">
                  <i class="las la-file-medical la-3x"></i>
                  </a>
               
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xl-6">
            <div class="row">
              <div class="col-xl-12">
                <PatientPercentage />
              </div>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="row">
              <div class="col-xl-12">
                <AdmissionsbyWards />
              </div>
            </div>
          </div>

          <!-- Finance Metrics -->
          <div class="row">
            <div class="col-md-6">
              <RevenueExpenseMonthly />
            </div>

            <div class="col-md-6">
              <RevenueGrowth />
            </div>
          </div>

          <!-- Patient Metrics -->
          <div class="row">
            <div class="col-md-6">
              <InOutPatientTrend />
            </div>

            <div class="col-md-6">
              <PatientsbyDepartments />
            </div>
          </div>

          <div class="col-md-12">
        <VisitsList />
      </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
</template>

<script>
import Header from "@/views/Header.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
// import { reloadPage } from "@/functions/Settings";
import "vue-loading-overlay/dist/vue-loading.css";
import PatientPercentage from "./components/PatientPercentage.vue";
import AdmissionsbyWards from "./components/AdmissionsbyWards.vue";
import InOutPatientTrend from "./components/InOutPatientTrend.vue";
import PatientsbyDepartments from "./components/PatientsbyDepartments.vue";
import RevenueExpenseMonthly from "@/views/finances/charts/RevenueExpenseMonthly.vue";
import RevenueGrowth from "@/views/finances/charts/RevenueGrowth.vue";
import VisitsList from "./components/VisitsList.vue";

export default {
  components: {
    Header,
    Loading,
    AdmissionsbyWards,
    InOutPatientTrend,
    PatientsbyDepartments,
    PatientPercentage,
    RevenueExpenseMonthly,
    RevenueGrowth,
    VisitsList
  },
  data() {
    return {
      user: null,
      checked: false,
      isLoading: false,
      fullPage: true,
      selected_facility: null,
      metrics: "",
      auth_user: "",
    };
  },
  beforeCreate() {
    // reloadPage();
  },

  beforeMount() {
   
  },
  mounted() {},

  methods: {
    getMetrics() {
      this.axios
        .get(`/staff/hospitaldashboard/${this.$selected_facility.id}`)
        .then((response) => {
          this.metrics = response.data;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    toggleAvail() {
      this.isLoading = true;
      this.user = JSON.parse(localStorage.getItem("user"));
      this.axios
        .put(`/staff`, {
          Availability: this.checked == false ? "Active" : "Away",
          Created: this.auth_user.Created,
          Department: this.auth_user.Department,
          Email: this.auth_user.Email,
          FacilityId: this.auth_user.FacilityId,
          FullName: this.auth_user.FullName,
          Id: this.auth_user.Id,
          Password: this.auth_user.Password,
          Phone: this.auth_user.Phone,
          Picture: this.auth_user.Picture,
          Role: this.auth_user.Role,
        })
        .then((response) => {
          this.isLoading = false;
          this.getSIngleStaff();
          this.$toastr.s(
            `You are now ${response.data.data.Availability}`,
            "Success!"
          );
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },

    getSIngleStaff() {
      this.user = JSON.parse(localStorage.getItem("user"));
      this.axios
        .get(`/staff/${this.user.id}`)
        .then((response) => {
          this.auth_user = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.getMetrics();
    // this.getSIngleStaff();
  },
};
</script>
