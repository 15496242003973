<template>
    <!-- Chart Card -->
    <div class="card">
      <!-- <div class="card-title">
        <h2 class="fs-20 font-w600 pl-4 pt-2">Patients by Departments</h2>
      </div> -->
      <div class="card-body">
        <div id="chart">
        <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
      </div>
      </div>
    </div>
  
    <!-- End of Chart -->
  </template>
    
    <script>
  export default {
    data() {
      return {
      
        series: [{
            data: [179]
          }],
          chartOptions: {
            chart: {
              type: 'bar',
              height: 350
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
              }
            },
            dataLabels: {
              enabled: true,
              colors: ['#00FF00']
            },
            title: {
          text: "Patients by Departments",
          align: "left",
        },
            xaxis: {
              categories: [ 'Eye Clinic'
              ],
            },
            colors: ["#097969"],
        }
        
      };
    },
  };
  </script>