<template>
  <div>
    <Header />

    <div class="content-body">
      <div class="container-fluid">
        <div class="form-head d-flex mb-sm-4 mb-3">
          <div class="mr-auto">
            <h2 class="text-black font-w600">Department Manager</h2>
            <p class="mb-0">Hospital Admin Dashboard Template</p>
          </div>
          <div>
            <a
              href="javascript:void(0)"
              class="btn btn-success mr-3"
              @click="showForm = !showForm"
              ><i class="las la-plus scale5 mr-3"></i> Add Department</a
            >
            <!-- <a href="#" class="btn btn-outline-success"
              ><i class="las la-calendar-plus scale5 mr-3"></i>Filter</a
            > -->
          </div>
        </div>

        <AddDepartment
          ref="AddDepartment"
          v-show="showForm"
          @update="onUpdate($event)"
        />

        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Departments</h4>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-responsive-md">
                    <thead>
                      <tr>
                        <th class="width80"><strong>#</strong></th>
                        <th><strong>Department Name</strong></th>
                        <th><strong>Created At</strong></th>
                        <th><strong>Action</strong></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(department, index) in departments.data"
                        :key="department.Id"
                      >
                        <td>
                          <strong> {{ index + 1 }}  </strong>
                        </td>
                        <td>
                          <a
                            class="text-success"
                            :href="`/department-${department.id}`"
                          >
                            {{ department.name }}
                          </a>
                        </td>
                        <td>
                          {{ department.created_at | moment("from", "now") }}
                        </td>
                        <td>
                          <span>
                            <router-link
                              :to="`/department-${department.id}`"
                              class="mr-2 btn btn-outline-success"
                              ><i class="las la-eye"></i>
                            </router-link>
                            <a
                              href="javascript:void()"
                              class="btn btn-outline-success"
                              @click="startUpdate(department)"
                              ><i class="las la-edit"></i>
                            </a>
                            <!-- <a
                              href="javascript:void()"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Close"
                              @click="deleteDept(department)"
                              ><i
                                class="fa fa-close color-danger text-success"
                              ></i
                            ></a> -->
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="vld-parent">
          <loading
            :active.sync="isLoading"
            loader="spinner"
            :can-cancel="true"
            :is-full-page="fullPage"
          ></loading>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/views/Header.vue";
import AddDepartment from "@/views/departments/AddDepartment.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin
// import { reloadPage } from "@/functions/Settings";
import { DeptService } from "@/jsstore/depts";

export default {
  components: {
    Header,
    Loading,
    AddDepartment,
  },
  data() {
    return {
      jsstore: new DeptService(),
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      showForm: false,
      edit: false,
      departments: "",
      department: {
        Name: "",
        Location: "Nigeria",
        Description: "",
      },
    };
  },
  beforeCreate() {
    //  reloadPage();
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.selected_facility = JSON.parse(
      localStorage.getItem("selected_facility")
    );
  },
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addDept();
      } else {
        this.editDept();
      }
    },

    startUpdate(department) {
      this.showForm = true;
      this.$refs.AddDepartment.setValue(department);
      console.log(department);
    },

    getDepts() {
      if (this.$store.state.mode == false) {
        this.getDeptsOffline();
      } else {
        this.getDeptsOnline();
      }
    },
    getDeptsOnline() {
      this.isLoading = false;
      this.axios
        .get(`/staff/departments`)
        .then((response) => {
          this.departments = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.$toastr.e("Error Querying Department", "Error!");
        });
    },
    async getDeptsOffline() {
      let query = await this.jsstore.getDepts();
      this.departments = {
        data: query,
      };
    },
    deleteDept(department) {
      if (confirm("Are you Sure you want to delete?")) {
        this.isLoading = true;
        this.axios
          .delete(`/staff/departments/${department.id}`)
          .then((response) => {
            console.log(response);
            this.getDepts();
            this.isLoading = false;
            this.$toastr.s("Department Deleted Successfully", "Success!");
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    onUpdate() {
      this.getDepts();
      this.showForm = false;
    },
  },
  created() {
    this.getDepts();
  },
};
</script>
