<template>
  <!-- Chart Card -->
  <div class="card">
    <div class="card-body">
      <apexchart
        type="donut"
        height="250%"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>

  <!-- End of Chart -->
</template>
  
  <script>
export default {
  data() {
    return {
      series: [404, 131, 100, 79],
      chartOptions: {
        chart: {
          //   height: 4000,
          type: "pie",
        },
        labels: ["Offline", "Card", "Transfer", "Online"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],

        title: {
          text: "Payment Channels",
          floating: true,
          margin: 10,
          offsetY: 0,
          align: "right",
          style: {
            color: "#444",
          },
        },
      },
    };
  },
};
</script>