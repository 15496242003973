<template>
  <div>
    <div id="preloader">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>

    <!--**********************************
        Main wrapper start
    ***********************************-->
    <div id="main-wrapper">
      <router-view />
    </div>
    <!--**********************************
        Main wrapper end
    ***********************************-->
    <div v-if=" $route.path != '/' ">
      <Footer  v-show="showfooter" />
    </div>
  </div>
</template>

<script>
import Footer from "@/views/Footer.vue";
import { initJsStore } from "@/jsstore/idb_service";
    //  import { StudentService } from "@/jsstore/student_service";
     import { Global } from "@/jsstore/global";



export default {
  name: "App",
  components: {
    Footer,
  },

  data: () => ({
    //
  }),

  async beforeCreate() {
   

    try {
      const isDbCreated = await initJsStore();
      if (isDbCreated) {
        console.log("db created");
        // prefill database
      } else {
        console.log("db opened");
      }
    } 
    catch (ex) {
      console.error(ex);
      alert(ex.message);
      Global.isIndexedDbSupported = false;
    }
  },


  computed:{
    showfooter(){
      if (this.$route.path == '/404') {
        return false
      } else {

        return true
        
      }
    }
  }
};
</script>
<style>
a {
  text-decoration: none !important;
}
</style>
