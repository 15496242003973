<template>
  <div>
    <!-- <div class="content-body"> -->
    <!-- <div class="container-fluid"> -->

    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <!-- <div class="card-header">
                        <h6 class="text-success">Create roles for Staffs</h6>
                      </div> -->
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Performed On </label>
                  <input
                    type="date"
                    placeholder="12/12/12"
                    class="form-control"
                    v-model="form_data.date"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label>Procedure </label>
                  <input
                    type="text"
                    placeholder="Colonoscopy"
                    class="form-control"
                    v-model="form_data.name"
                  />
                </div>

                <div class="form-group col-md-12">
                  <label for="inputPassword4"
                    >Doctor Performing(ed) the Procedure</label
                  >
                  <select v-model="form_data.staff_id" class="form-control">
                    <option
                      :value="staff.id"
                      v-for="staff in staffs.data"
                      :key="staff.id"
                    >
                      {{ staff.full_name }} 
                    </option>
                  </select>
                </div>

                <!-- <div class="form-group col-md-6">
                  <label for="inputPassword4">Status </label>
                  <v-select
                    v-model="form_data.status"
                    :options="statuses"
                    class="form-control"
                  />
                </div> -->

                <div class="form-group col-md-12">
                  <label>Support Devices</label>
                  <textarea
                    class="form-control"
                    placeholder="Type your message..."
                    rows="4"
                    v-model="form_data.support_devices"
                  ></textarea>
                </div>

                <div class="form-group col-md-12">
                  <label> Description of Procedure</label>
                  <textarea
                    class="form-control"
                    placeholder="Type here..."
                    rows="4"
                    v-model="form_data.notes"
                  ></textarea>
                </div>
              </div>

              <button class="btn btn-success btn-block" type="submit">
                Submit <i class="las la-paper-plane"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
        
        <script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      edit: false,
      File: "",
      patients: "",
      staffs: "",
      form_data: {
        patient_id: this.$route.params.id,
        staff_id: "",
        surgery: "",
        name: "",
        support_devices: "",
        date: "",
        notes: "",
      },
    };
  },
  beforeMount() {},
  computed: {},
  methods: {
    getStaffs() {
      this.isLoading = true;
      this.axios
        .get(`/staff/profile`)
        .then((response) => {
          this.staffs = response.data.data;
          this.isLoading = false;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },

    submitForm() {
      if (this.edit == false) {
        this.addForm();
      } else {
        this.updateForm();
      }
    },
    addForm() {
      this.isLoading = true;
      this.axios
        .post("/staff/surgery", {
          patient_id: this.$route.params.id,
          facility_id: this.$selected_facility.id,
          staff_id: this.form_data.staff_id,
          surgery: this.form_data.name,
          name: this.form_data.name,
          support_devices: this.form_data.support_devices,
          date: this.form_data.date,
          notes: this.form_data.notes,
        })
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toastr.s("Surgery Created Successfully", "Success!");
          this.clearForm();
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");
          this.isLoading = false;
        });
    },
    updateForm() {
      //
    },

    clearForm() {
      this.form_data.staff_id = "";
      this.form_data.surgery = "";
      this.form_data.name = "";
      this.form_data.support_devices = "";
      this.form_data.date = "";
      this.form_data.notes = "";
    },
  },
  created() {
    this.getStaffs();
  },
};
</script>
        