<template>
  <div>
    <!-- <div class="content-body"> -->
    <!-- <div class="container-fluid"> -->

    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <!-- <div class="card-header">
                  <h6 class="text-success">Create roles for Staffs</h6>
                </div> -->
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Name of {{ $route.params.type }} </label>
                  <input
                    type="text"
                    placeholder="name of the service/drug"
                    class="form-control"
                    v-model="service.name"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Amount</label>
                  <input
                    type="text"
                    placeholder="45,000.00"
                    class="form-control"
                    v-model="service.amount"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label> Description</label>
                  <textarea
                    class="form-control"
                    placeholder="Type your message..."
                    rows="4"
                    v-model="service.description"
                  ></textarea>
                </div>
              </div>

              <button class="btn btn-success btn-block" type="submit">
                Submit <i class="las la-paper-plane"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
  
  <script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Loading,
  },
  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      edit: false,
      activities: "",
      service: {
        id: "",
        facility_id: this.$selected_facility.id,
        name: "",
        amount: "",
        description: "",
        is_drug: false,
      },
    };
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    submitForm() {
      if (this.edit == false) {
          if (this.$route.params.type == 'Services') {
            this.addService();
          } else {
            this.addDrug()
          }
       
      } else {
        this.updateService();
      }
    },
    addService() {
      this.isLoading = true;

      this.axios
        .post("/staff/services", this.service)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toastr.s("Service Created Successfully", "Success!");
          this.clearForm();
          this.$emit("update");
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");
          this.isLoading = false;
        });
    },

    addDrug() {
      this.isLoading = true;

      this.service.is_drug = true;

      this.axios
        .post("/staff/services", this.service)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toastr.s(`${this.$route.params.type} Created Successfully`, "Success!");
          this.clearForm();
          this.$emit("update");
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");
          this.isLoading = false;
        });
    },
    updateService() {
      this.isLoading = true;
      this.axios
        .patch(`/staff/services/${this.service.id}`, this.service)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.clearForm();
          this.$emit("update");
          this.$toastr.s("Updated Successfully", "Error!");

        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.$toastr.e("not successful", "Error!");
        });
    },

    setValue(value) {
      this.edit = true;
      this.service = value;
      console.log(value);
    },
    clearForm() {
      this.service.id = "";
      this.service.name = "";
      this.service.amount = "";
      this.service.description = "";
      this.edit = false;
    },
  },
  created() {
    this.getActivities();
  },
};
</script>
  