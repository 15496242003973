<template>
  <div>
    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Date Administered </label>
                  <input
                    type="date"
                    class="form-control"
                    v-model="form_data.date_administered"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label>Serial Number </label>
                  <input
                    type="text"
                    placeholder="CS00021"
                    class="form-control"
                    v-model="form_data.serial_number"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label>Patient Card Number</label>
                  <input
                    type="text"
                    @keyup="searchPatient"
                    placeholder="CNX0001"
                    class="form-control"
                    v-model="form_data.search_query"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label for="inputPassword4">Select Vaccine </label>
                  <v-select
                    v-model="form_data.vaccination_id"
                    :reduce="(name) => name.id"
                    label="name"
                    class="form-control"
                    :options="vaccines.data"
                  />
                </div>
              </div>

              <div class="form-row"></div>

              <div class="form-row">
                <div class="form-group col-md-12" v-if="patient_data != ''">
                  <label>Patient Name</label>
                  <input
                    type="text"
                    disabled
                    class="form-control"
                    :value="`${patient_data.first_name}  ${patient_data.last_name}`"
                  />
                </div>

                <div class="form-group col-md-12">
                  <label>Dose Number</label>
                  <input
                    type="number"
                    placeholder="5"
                    class="form-control"
                    v-model="form_data.dose_number"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label> Notes</label>
                  <textarea
                    class="form-control"
                    placeholder="Type your message..."
                    rows="4"
                    v-model="form_data.notes"
                  ></textarea>
                </div>
              </div>

              <button class="btn btn-success btn-block" type="submit">
                Submit <i class="las la-paper-plane"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
</template>
    
    <script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Loading,
  },
  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      edit: false,
      vaccines: "",
      patient_data: "",
      form_data: {
        id: "",
        search_query: "",
        patient_id: "",
        vaccination_id: "",
        date_administered: "",
        serial_number: "",
        dose_number: "",
        notes: "",
      },
    };
  },
  beforeMount() {},
  methods: {
    searchPatient() {
      this.axios
        .get(`/staff/patients`, {
          params: {
            scope: `card_no:${this.form_data.search_query}`,
          },
        })
        .then((response) => {
          this.patient_data = response.data.data.data[0];
          console.log(response);
          this.$toasted.success(`Searched Successfully!`, {
            position: "top-center",
            duration: 7000,
          });
        })
        .catch((error) => {
          console.error(error);
          this.$toasted.error(`Error!`, {
            position: "top-center",
            duration: 7000,
          });
        });
    },
    submitForm() {
      if (this.edit == false) {
        this.addForm();
      } else {
        this.updateForm();
      }
    },
    addForm() {
      this.axios
        .post("/staff/vaccinate-patient", {
          patient_id: this.patient_data.id,
          vaccination_id: this.form_data.vaccination_id,
          date_administered: this.form_data.date_administered,
          serial_number: this.form_data.serial_number,
          dose_number: this.form_data.dose_number,
          notes: this.form_data.notes,
        })
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toastr.s("Patient Vaccinated Successfully", "Success!");
          this.clearForm();
          this.$emit("update");
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");
          this.isLoading = false;
        });
    },

    updateForm() {
      this.isLoading = true;
      this.axios
        .put(`/staff/vaccinate-patient/${this.form_data.id}`, this.form_data)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.edit = false;
          this.$toasted.success("Updated Successfully!", {
            position: "top-center",
            duration: 3000,
          });
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.$toastr.e("not successful", "Error!");
        });
    },

    clearForm() {
      this.form_data.patient_id = "";
      this.form_data.vaccination_id = "";
      this.form_data.date_administered = "";
      this.form_data.serial_number = "";
      this.form_data.dose_number = "";
      this.form_data.notes = "";
    },

    setValue(value) {
      this.edit = true;
      this.form_data = value;
      console.log(value);
    },
    getVaccines() {
      this.isLoading = true;
      this.axios
        .get(`/staff/vaccinations`)
        .then((response) => {
          this.vaccines = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.$toastr.e("error on querying", "Error!");
        });
    },
  },
  created() {
    this.getVaccines();
  },
};
</script>
    