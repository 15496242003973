<template>
  <div>
    <Header />

    <div class="content-body">
      <div class="col-md-8 offset-md-2">
        <div class="card">
          <div class="settings-form">
            <div class="card-header">
              <h4 class="text-primary">Edit Patient Record </h4>
            </div>
            <div class="card-body">
              <form @submit.prevent="updateRecord">
                  <div class="form-group">
                    <label class="text-black font-w500"
                      >Select Title {{ record.Title }}
                    </label>
                    <select class="form-control" v-model="record.Title">
                      <option>Checkup</option>
                      <option>Emergency</option>
                      <option>Lab Test</option>
                    </select>
                  </div>

                  <div class="form-group">
                    <label class="text-black font-w500"
                      >Details of Record</label
                    >
                    <wysiwyg v-model="record.Details" />
                   
                  </div>

                  <div class="form-group">
                    <label class="text-black font-w500">Brief</label>
                     <wysiwyg v-model="record.Brief" />
                   
                  </div>
                  <div class="form-group">
                    <label class="text-black font-w500">Lab</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="record.Lab"
                    />
                  </div>

                  <div class="form-group">
                    <label class="text-black font-w500">Action</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="record.Action"
                    />
                  </div>
                  <div class="form-group">
                    <button type="submit" class="btn btn-primary btn-block">
                      Submit <i class="la la-save"></i>
                    </button>
                  </div>
                </form>
            </div>
          </div>
        </div>
      </div>
    </div>
     <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
</template>

<script>
import Header from "@/views/Header.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Header,  Loading,
  },
  data() {
    return {
      user: null,
      isLoading: false,
      fullPage: true,
      patient: "",
      record: "",

    };
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
     getRecord() {
      this.axios
        .get(`/record/${this.$route.params.PatientId}/${this.$route.params.Id}`)
        .then((response) => {
          this.record = response.data.data;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
   getPatient() {
      this.axios
        .get(`/patient/${this.$route.params.PatientId}`)
        .then((response) => {
          this.patient = response.data.data;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    updateRecord() {
        this.isLoading = true
      this.axios
        .put(`/record`,this.record)
        .then((response) => {
          this.getRecord()
          console.log(response);
           this.isLoading = false
            this.$toasted.success("updated Successfully!", {
            position: "top-center",
            duration: 3000,
          });
        })
        .catch((error) => {
          console.error(error);
           this.isLoading = false
        });
    },
  },
  created() {
    this.getPatient()
    this.getRecord()
  },
};
</script>
