<template>
  <div>
    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header">
                    <h4 class="card-title pt-4 pb-4">Family Medical History</h4>

                    <button
                      class="btn btn-success mr-2"
                      v-b-toggle.sidebar-condition
                    >
                      <i class="las la-plus"></i> Add History
                    </button>
                  </div>
                  <div class="card-body">
                    <vue-good-table
                      :columns="columns"
                      :rows="histories.data"
                      :search-options="{ enabled: true }"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-sidebar
      id="sidebar-condition"
      title="Family Med. History Form"
      right
      shadow
      width="600px"
    >
      <div class="px-3 py-2">
        <AddMedicalHistory />
      </div>
    </b-sidebar>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
            
            <script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin
import AddMedicalHistory from "./AddMedicalHistory.vue";

export default {
  components: {
    Loading,
    AddMedicalHistory,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true, 
      columns: [
        {
          label: "Familial Relationship",
          field: "family_relationship",
          type: "text",
        },
        {
          label: "Description",
          field: "description",
          type: "text",
        },

        {
          label: "Added On",
          field: "created_at",
          type: "text",
        },
      ],
      histories: [],
    };
  },
  beforeMount() {},
  methods: {
    fetchData() {
      this.isLoading = true;

      this.axios
        .get(`/staff/family-medical-histories`, {
          params: {
            scope: `patient_id:${this.$route.params.id}`,
          },
        })
        .then((response) => {
          this.histories = response.data.data;
          this.isLoading = false;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
            