<template>
  <div>
    <!-- <div class="content-body"> -->
    <!-- <div class="container-fluid"> -->

    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <!-- <div class="card-header">
                <h6 class="text-success">Create roles for Staffs</h6>
              </div> -->
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Ward Title</label>
                  <input
                    type="text"
                    placeholder="Ward A, Ward B, Ward C"
                    class="form-control"
                    v-model="ward.name"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="inputPassword4"
                    >Ward Belongs To Which Department?
                  </label>
                  <v-select
                    v-model="ward.department_id"
                    :reduce="(name) => name.id"
                    label="name"
                    :options="departments.data"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label> Description</label>
                  <textarea
                    class="form-control"
                    placeholder="Type your message..."
                    rows="4"
                    v-model="ward.description"
                  ></textarea>
                </div>
              </div>

              <button class="btn btn-success btn-block" type="submit">
                Submit <i class="las la-paper-plane"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>

<script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Loading,
  },
  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      edit: false,
      departments: "",
      ward: {
        id: "",
        name: "",
        facility_id: this.$selected_facility.id,
        department_id: "",
        description: "",
      },
    };
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addWard();
      } else {
        this.updateWard();
      }
    },
    addWard() {
      this.isLoading = true;

      this.axios
        .post("/staff/wards", this.ward)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toastr.s("Ward Created Successfully", "Success!");
          this.clearForm();
          this.$emit("update");
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");

          this.isLoading = false;
        });
    },
    updateWard() {
      this.isLoading = true;
      this.axios
        .patch(`/staff/wards/${this.ward.id}`, this.ward)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toastr.s("Updated Successfully", "Error!");
          this.clearForm();
          this.$emit("update");
          this.isLoading = false;
       
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    setValue(value) {
      this.edit = true;
      this.ward = value;
      console.log(value);
    },

    clearForm() {
      this.ward.id = "";
      this.ward.name = "";
      this.ward.description = "";
      this.ward.department_id = "";
      this.edit = false;
    },
    getDepts() {
      this.isLoading = false;
      this.axios
        .get(`/staff/departments`)
        .then((response) => {
          this.departments = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.$toastr.e("Error Querying Department", "Error!");
        });
    },
  },
  created() {
    this.getDepts();
  },
};
</script>
