<template>
  <div>
    <Header />

    <div class="content-body">
      <div class="container-fluid">
        <div class="form-head d-flex mb-sm-4 mb-3">
          <div class="mr-auto">
            <h2 class="text-black font-w600">Blood Issued Out</h2>
            <p class="mb-0">Manage all blood issued out here</p>
          </div>
          <div>
            <a
              href="javascript:void(0)"
              class="btn btn-success mr-3"
              v-b-toggle.sidebar-right
              ><i class="las la-plus scale5 mr-3"></i>Issue BLood Out</a
            >
          </div>
        </div>
        <b-sidebar
          id="sidebar-right"
          title="Log Blood Issued Out"
          right
          shadow
          width="500px"
        >
          <div class="px-3 py-2">
            <AddBloodOut v-show="showForm" />
          </div>
        </b-sidebar>

        <div class="row">
          <BloodOutData />
        </div>

        <div class="vld-parent">
          <loading
            :active.sync="isLoading"
            loader="spinner"
            :can-cancel="true"
            :is-full-page="fullPage"
          ></loading>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Header from "@/views/Header.vue";
import AddBloodOut from "@/views/bloods/AddBloodOut.vue";
import BloodOutData from "@/views/bloods/BloodOutData.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin
import { reloadPage } from "@/functions/Settings";

export default {
  components: {
    Header,
    Loading,
    AddBloodOut,
    BloodOutData,
  },
  data() {
    return {
      user: null,
      isLoading: false,
      fullPage: true,
      showForm: true,
      edit: false,
      bloodouts: "",
    };
  },
  beforeCreate() {
     reloadPage();
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addDept();
      } else {
        this.editDept();
      }
    },
    addward() {
      this.isLoading = true;
      this.axios
        .post("/roles", {
          Title: this.role.Title,
          Description: this.role.Description,
        })
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toastr.s("Ward Created Successfully", "Success!");
          this.getRoles();
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");

          this.isLoading = false;
        });
    },
    updateRole(role) {
      this.isLoading = true;
      this.axios
        .put(`/roles`, role)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.getRoles();
          this.isLoading = false;
          this.$toasted.success("Updated Successfully!", {
            position: "top-center",
            duration: 3000,
          });
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    getBloodOuts() {
      this.isLoading = true;

      this.axios
        .get(`/staff/blood-out`)
        .then((response) => {
          this.bloodouts = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    deleteRole(role) {
      if (confirm("Are you Sure you want to delete?")) {
        this.isLoading = true;
        this.axios
          .patch(`/roles/${role.Id}`)
          .then((response) => {
            console.log(response);
            this.isLoading = false;
            this.getRoles();
            this.isLoading = false;
            this.$toasted.success("Deleted Successfully!", {
              position: "top-center",
              duration: 3000,
            });
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
          });
      }
    },
  },
  created() {
    this.getBloodOuts();
  },
};
</script>
  