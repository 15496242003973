<template>
  <div>
    <!-- <div class="content-body"> -->
    <!-- <div class="container-fluid"> -->

    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <!-- <div class="card-header">
                      <h6 class="text-success">Create roles for Staffs</h6>
                    </div> -->
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group col-md-12 mb-3">
                  <label id="default">Health Condition </label>

                  <v-select
                    v-model="form_data.health_condition"
                    :reduce="(name) => name.id"
                    label="name"
                    :options="diseases"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label>Diagnosed On </label>
                  <input
                    type="date"
                    placeholder="12/12/12"
                    class="form-control"
                    v-model="form_data.diagnosed_on"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label for="inputPassword4">Status </label>
                  <v-select
                    v-model="form_data.status"
                    :options="statuses"
                    class="form-control"
                  />
                </div>

                <div class="form-group col-md-12">
                  <label> Notes</label>
                  <textarea
                    class="form-control"
                    placeholder="Type your message..."
                    rows="4"
                    v-model="form_data.notes"
                  ></textarea>
                </div>
              </div>

              <div class="form-row"></div>

              <button class="btn btn-success btn-block" type="submit">
                Submit <i class="las la-paper-plane"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
      
      <script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// import ICDJSON from "@/views/patients/components/icd_10.json";

export default {
  components: {
    Loading,
  },
  props: ["patient"],
  data() {
    return {
      isLoading: false,
      fullPage: true,
      edit: false,
      querying: false,
      File: "",
      all_diseases: "",
      patients: "",
      // diseases: ICDJSON,
      diseases: "",
      form_data: {
        patient_id: "",
        facility_id: this.$selected_facility.id,
        health_condition: "",
        diagnosed_on: "",
        status: "",
        notes: "",
      },
      statuses: ["active", "treated"],
    };
  },
  beforeMount() {},
  computed: {},
  methods: {
    queryDiseases() {
      if (this.form_data.health_condition.length >= 4) {
        this.querying = true;
        let toBeFiltered = this.diseases;
        toBeFiltered = toBeFiltered.filter((item) => {
          // return item.desc

          return item;
        });

        this.querying = false;

        this.all_diseases = toBeFiltered;
        console.log(toBeFiltered);
      } else {
        console.log("keep typing");
      }
    },

    submitForm() {
      if (this.edit == false) {
        this.addForm();
      } else {
        this.updateForm();
      }
    },
    addForm() {
      this.form_data.patient_id = this.patient.id;
      this.isLoading = true;
      this.axios
        .post("/staff/health-conditions", this.form_data)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toastr.s("Condition Created Successfully", "Success!");
          this.clearForm();
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");
          this.isLoading = false;
        });
    },
    updateForm() {
      //
    },

    getDiseases() {
      this.axios
        .get(`/staff/diseases`)
        .then((response) => {
          this.diseases = response.data.data;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    clearForm() {
      this.form_data.health_condition = "";
      this.form_data.status = "";
      this.form_data.diagnosed_on = "";
      this.form_data.notes = "";
    },
  },
  created() {
    this.getDiseases();
  },
};
</script>
      