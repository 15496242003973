<template>
  <div>
    <!-- <div class="content-body"> -->
    <!-- <div class="container-fluid"> -->

    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <!-- <div class="card-header">
                    <h6 class="text-success">Create roles for Staffs</h6>
                  </div> -->
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="inputPassword4">Select Donor? </label>
                  <v-select
                    v-model="donation.blood_donor_id"
                    :reduce="(full_name) => full_name.id"
                    label="full_name"
                    :options="donors.data"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Bags</label>
                  <input
                    type="number"
                    placeholder="1"
                    class="form-control"
                    v-model="donation.bags"
                  />
                </div>
              </div>

              <button class="btn btn-success btn-block" type="submit">
                Submit <i class="las la-paper-plane"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
    
    <script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Loading,
  },
  data() {
    return {
      user: null,
      isLoading: false,
      fullPage: true,
      edit: false,
      donors: "",
      donation: {
        facility_id: this.$selected_facility.id,
        blood_donor_id: "",
        bags: "",
      },
    };
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addForm();
      } else {
        this.editDept();
      }
    },
    addForm() {
      this.isLoading = true;
      this.axios
        .post("/staff/blood-donations", this.donation)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toastr.s("Bed Created Successfully", "Success!");
          // this.getDepts();
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");

          this.isLoading = false;
        });
    },
    updateDept() {
      //
    },

    getDonors() {
      this.isLoading = true;
      this.axios
        .get(`/staff/blood-donors`)
        .then((response) => {
          this.donors = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.getDonors();
  },
};
</script>
    