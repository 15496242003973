<template>
  <div>
    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header">
                    <h4 class="card-title pt-4 pb-4">Health Conditions</h4>

                   
                  </div>
                  <div class="card-body">
                    <vue-good-table
                      :columns="columns"
                      :rows="conditions.data"
                      :search-options="{ enabled: true }"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-sidebar
      id="sidebar-condition"
      title="Health Condition"
      right
      shadow
      width="600px"
    >
      <div class="px-3 py-2">
        <AddHealthCondition />
      </div>
    </b-sidebar>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
          
          <script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin
// import AddHealthCondition from "@/views/patients/components/AddHealthCondition.vue";

export default {
  components: {
    Loading,
    // AddHealthCondition,
  },
  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      columns: [
        {
          label: "Diagnosed On",
          field: "diagnosed_on",
          type: "text",
        },
        {
          label: "Health Condition",
          field: "disease.name",
        },

        {
          label: "Status",
          field: "status",
          type: "text",
        },
      ],
      conditions: [],
    };
  },
  beforeMount() {},
  methods: {
    getConditions() {
      this.isLoading = true;

      this.axios
        .get(`/staff/health-conditions`, {
          params: {
            scope: `patient_id:${this.$route.params.id}`,
          },
        })
        .then((response) => {
          this.conditions = response.data.data;
          this.isLoading = false;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.getConditions();
  },
};
</script>
          