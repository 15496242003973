<template>
    <div>
      <div class="col-md-12">
        <div class="card">
          <div class="settings-form">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-header">
                      <h4 class="card-title pt-4 pb-4">Vitals</h4>
                    </div>
                    <div class="card-body">
                      <vue-good-table
                        :columns="columns"
                        :rows="vitals.data"
                        :search-options="{ enabled: true }"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="vld-parent">
        <loading
          :active.sync="isLoading"
          loader="spinner"
          :can-cancel="true"
          :is-full-page="fullPage"
        ></loading>
      </div>
    </div>
    <!-- </div> -->
    <!-- </div> -->
  </template>
                
    <script>
  // Import component
  import Loading from "vue-loading-overlay";
  // Import stylesheet
  import "vue-loading-overlay/dist/vue-loading.css";
  // Init plugin
  
  export default {
    components: {
      Loading,
  
    },
    data() {
      return {
        isLoading: false,
        fullPage: true,
        columns: [
  
          {
            label: "Weight (KG)",
            field: "weight",
            type: "text",
          },
  
          {
            label: "Blood Pressure (MM/HG) ",
            field: "blood_pressure",
            type: "text",
          },

          {
            label: "Temperature (Celcius)",
            field: "temperature",
            type: "text",
          },
  
          {
            label: "Date",
            field: "created_at",
            type: "text",
          },
        ],
        vitals: [],
      };
    },
    beforeMount() {},
    methods: {
      fetchData() {
        this.isLoading = true;
  
        this.axios
          .get(`/staff/patient-vitals/${this.$route.params.id}`)
          .then((response) => {
            this.vitals = response.data;
            this.isLoading = false;
            console.log(response);
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
          });
      },
     
    },
    created() {
      this.fetchData();
    },
  };
  </script>
                