<template>
  <div>
    <Header />

    <div class="content-body">
      <div class="container-fluid">
        <div class="col-md-12">
          <div class="form-head d-flex mb-sm-4 mb-3">
            <div class="mr-auto">
              <h2 class="text-black font-w600">Patients</h2>
              <p class="mb-0">Manage your patients here</p>
            </div>
            <div>
              <router-link to="/add-patient" class="btn btn-success mr-3">
                +<i class="las la-user-injured"></i> New Patient</router-link
              >
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group col-md-5 float-right">
              <div class="form-group">
                <input
                  type="text"
                  v-on:keyup.enter="searchHere"
                  v-model="search"
                  list="patients"
                  class="form-control input-rounded"
                  placeholder="Card No., Phone No., Email, First Name, Last Name"
                />
              </div>

              <datalist id="patients">
                <option v-for="dis in patients.data" :key="dis.id">
                  {{ dis.first_name }}
                  {{ dis.last_name }} ({{ dis.card_no }} )
                </option>
              </datalist>
            </div>
          </div>
          <div class="col-md-12">
            <div class="card">
              <div class="card-header pt-4 pb-4">
                <p class="card-title h4">Patients</p>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-responsive-md">
                    <thead>
                      <tr>
                        <th><strong>#</strong></th>
                        <th><strong>Full Name</strong></th>
                        <th><strong>Card No.</strong></th>
                        <th><strong>Phone</strong></th>
                        <th><strong>Category</strong></th>
                        <th><strong>Genotype/Blood Group </strong></th>
                        <th><strong>Action</strong></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(patient, index) in patients.data"
                        v-bind:key="patient.id"
                      >
                        <td>
                          <strong>{{ index + 1 }} </strong>
                        </td>

                        <td>
                          <a
                            :href="`/patient-${patient.id}`"
                            class="text-success"
                          >
                            {{ patient.full_name }}
                          </a>
                        </td>
                        <td>{{ patient.card_no }}</td>
                        <td>{{ patient.phone_no }}</td>

                        <td>
                          <a
                            href="javascript:void()"
                            class="btn btn-primary btn-sm px-3 light ml-2"
                          >
                            {{ patient.type }}
                          </a>
                        </td>
                        <td>
                          {{ patient.genotype }} / {{ patient.blood_group }}
                        </td>
                        <td>
                          <span>
                            <a
                              :href="`/edit-patient-${patient.id}`"
                              class="mr-2 btn btn-outline-success"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Edit"
                              ><i
                                class="las la-edit"
                              ></i>
                            </a>
                            <a :href="`/patient-${patient.id}`" class="btn btn-outline-success">
                              <i
                                class="las la-eye"
                              ></i>
                            </a>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <nav>
                  <ul class="pagination pagination-gutter">
                    <li class="page-item page-indicator">
                      <a class="page-link" href="javascript:void()">
                        <i class="la la-angle-left"></i
                      ></a>
                    </li>
                    <li
                      :class="
                        patients.current_page == link
                          ? 'page-item active'
                          : 'page-item'
                      "
                      v-for="link in patients.last_page"
                      :key="link"
                    >
                      <a
                        class="page-link"
                        href="javascript:void()"
                        @click="QueryPatients(link)"
                        >{{ link }}
                      </a>
                    </li>

                    <li class="page-item page-indicator">
                      <a class="page-link" href="javascript:void()">
                        <i class="la la-angle-right"></i
                      ></a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div class="vld-parent">
          <loading
            :active.sync="isLoading"
            loader="spinner"
            :can-cancel="true"
            :is-full-page="fullPage"
          ></loading>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/views/Header.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin
// import { reloadPage } from "@/functions/Settings";

import { PatientService } from "@/jsstore/patients";

export default {
  components: {
    Header,
    Loading,
  },
  data() {
    return {
      jsstore: new PatientService(),
      user: null,
      selected_facility: null,
      isLoading: false,
      edit: false,
      fullPage: true,
      patients: "",
      search: "",
      department: {
        Name: "",
        Location: "Nigeria",
        Description: "",
      },
    };
  },
  beforeCreate() {
    // reloadPage();
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    QueryPatients(page) {
      if (this.$route.params.type == "all") {
        if (this.$store.state.mode == true) {
          this.getPatientsAll(page);
        } else {
          this.getPatientsOffline();
        }
      } else {
        this.getPatientsType(page);
      }
    },

    getPatientsAll(page) {
      this.isLoading = true;
      this.axios
        .get(`/staff/patients`, {
          params: {
            page: page,
          },
        })
        .then((response) => {
          this.patients = response.data.data;
          this.isLoading = false;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },

    getPatientsType(page) {
      this.isLoading = true;
      this.axios
        .get(`/staff/patients`, {
          params: {
            scope: `type:${this.$route.params.type}`,
            page: page,
          },
        })
        .then((response) => {
          this.patients = response.data.data;
          this.isLoading = false;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    async searchHere() {
      if (this.$store.state.mode == false) {
        //offline search
        let query = await this.jsstore.getPatientByCard(this.search);
        this.patient = query[0];
        this.$router.push(`/patient/${this.patient.card_no}`);
      } else {
        //online search
        this.isLoading = true;
        this.axios

          .post(`/staff/search-patient`, { search: this.search })
          .then((response) => {
            let patients = response.data;
            this.patients = {
              data: patients,
            };
            this.isLoading = false;
            console.log(response);
            this.$toastr.s("Searched Successfully!", "Success!");

            // this.$router.push(`/patient/${response.data.data.data[0].id}`);
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
            this.$toastr.e("not successful", "Error!");
          });
      }
    },

    async getPatientsOffline() {
      let query = await this.jsstore.getPatients();
      this.patients = {
        data: query,
      };

      console.log(this.patients + "jsstore");
    },
  },
  created() {
    this.QueryPatients();
  },
};
</script>
