<template>
    <div>
      <!-- <div class="content-body"> -->
      <!-- <div class="container-fluid"> -->
  
      <div class="col-md-12">
        <div class="card">
          <div class="settings-form">
            <!-- <div class="card-header">
                          <h6 class="text-success">Create roles for Staffs</h6>
                        </div> -->
            <div class="card-body">
              <form @submit.prevent="submitForm">
                <div class="form-row">
                    <div class="form-group col-md-6">
                    <label>Hx of Presenting Complaint<br></label>
                    <textarea
                      class="form-control"
                      placeholder=""
                      rows="4"
                      v-model="form_data.support_devices"
                    ></textarea>
                  </div>
  
                  <div class="form-group col-md-6">
                    <label>Past Medical Hx </label>
                    <textarea
                      class="form-control"
                      placeholder=""
                      rows="4"
                      v-model="form_data.support_devices"
                    ></textarea>
                  </div>
  
                  <div class="form-group col-md-6">
                    <label>Himmunization Hx </label>
                    <textarea
                      class="form-control"
                      placeholder=""
                      rows="4"
                      v-model="form_data.support_devices"
                    ></textarea>
                  </div>
  
                  <div class="form-group col-md-6">
                    <label>Family Hx</label>
                    <textarea
                      class="form-control"
                      placeholder=""
                      rows="4"
                      v-model="form_data.support_devices"
                    ></textarea>
                  </div>

                  <div class="form-group col-md-6">
                    <label>Diet/Drug/Social Hx</label>
                    <textarea
                      class="form-control"
                      placeholder=""
                      rows="4"
                      v-model="form_data.support_devices"
                    ></textarea>
                  </div>
                  <div class="form-group col-md-6">
                    <label>Examination</label>
                    <textarea
                      class="form-control"
                      placeholder=""
                      rows="4"
                      v-model="form_data.support_devices"
                    ></textarea>
                  </div>

                  <div class="form-group col-md-6">
                    <label>Results</label>
                    <textarea
                      class="form-control"
                      placeholder=""
                      rows="4"
                      v-model="form_data.support_devices"
                    ></textarea>
                  </div>

                  <div class="form-group col-md-6">
                    <label>Investigations</label>
                    <textarea
                      class="form-control"
                      placeholder=""
                      rows="4"
                      v-model="form_data.support_devices"
                    ></textarea>
                  </div>


                  <div class="form-group col-md-6">
                    <label>Treatment</label>
                    <textarea
                      class="form-control"
                      placeholder=""
                      rows="4"
                      v-model="form_data.support_devices"
                    ></textarea>
                  </div>

                  <div class="form-group col-md-6">
                    <label>Summary</label>
                    <textarea
                      class="form-control"
                      placeholder=""
                      rows="4"
                      v-model="form_data.support_devices"
                    ></textarea>
                  </div>
  
                 
                </div>
  
                <button class="btn btn-success btn-block" type="submit">
                  Submit <i class="las la-paper-plane"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
  
      <div class="vld-parent">
        <loading
          :active.sync="isLoading"
          loader="spinner"
          :can-cancel="true"
          :is-full-page="fullPage"
        ></loading>
      </div>
    </div>
    <!-- </div> -->
    <!-- </div> -->
  </template>
          
          <script>
  // Import component
  import Loading from "vue-loading-overlay";
  // Import stylesheet
  import "vue-loading-overlay/dist/vue-loading.css";
  
  export default {
    components: {
      Loading,
    },
    data() {
      return {
        isLoading: false,
        fullPage: true,
        edit: false,
        File: "",
        patients: "",
        staffs: "",
        form_data: {
          patient_id: this.$route.params.id,
          staff_id: "",
          surgery: "",
          name: "",
          support_devices: "",
          date: "",
          notes: "",
        },
      };
    },
    beforeMount() {},
    computed: {},
    methods: {
      getStaffs() {
        this.isLoading = true;
        this.axios
          .get(`/staff/profile`)
          .then((response) => {
            this.staffs = response.data.data;
            this.isLoading = false;
            console.log(response);
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
          });
      },
  
      submitForm() {
        if (this.edit == false) {
          this.addForm();
        } else {
          this.updateForm();
        }
      },
      addForm() {
        this.isLoading = true;
        this.axios
          .post("/staff/surgery", {
            patient_id: this.$route.params.id,
            facility_id: this.$selected_facility.id,
            staff_id: this.form_data.staff_id,
            surgery: this.form_data.name,
            name: this.form_data.name,
            support_devices: this.form_data.support_devices,
            date: this.form_data.date,
            notes: this.form_data.notes,
          })
          .then((response) => {
            console.log(response);
            this.isLoading = false;
            this.$toastr.s("IVF Created Successfully", "Success!");
            this.clearForm();
          })
          .catch((error) => {
            console.log(error.response);
            this.$toastr.e("Error on Adding!");
            this.isLoading = false;
          });
      },
      updateForm() {
        //
      },
  
      clearForm() {
        this.form_data.staff_id = "";
        this.form_data.surgery = "";
        this.form_data.name = "";
        this.form_data.support_devices = "";
        this.form_data.date = "";
        this.form_data.notes = "";
      },
    },
    created() {
      this.getStaffs();
    },
  };
  </script>
          