<template>
  <div>
    <Header />

    <div class="content-body">
      <div class="container-fluid">
        <div class="form-head d-flex mb-sm-4 mb-3">
          <div class="mr-auto">
            <h2 class="text-black font-w600">Employee Payrolls</h2>
            <p class="mb-0">Manage your employees Payroll here</p>
          </div>
          <div>
            <a
              href="javascript:void(0)"
              class="btn btn-success mr-3"
              @click="showForm = !showForm"
              v-b-toggle.sidebar-right
              ><i class="las la-plus scale5 mr-3"></i> New Payroll</a
            >

            <!-- <a href="#" class="btn btn-outline-success"
                ><i class="las la-calendar-plus scale5 mr-3"></i>Filter</a
              > -->
          </div>
        </div>
        <b-sidebar
          id="sidebar-right"
          title="New Payroll"
          right
          shadow
          width="630px"
        >
          <div class="px-3 py-2">
            <AddPayroll v-show="showForm" />
          </div>
        </b-sidebar>

        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Payrolls</h4>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-responsive-md">
                    <thead>
                      <tr>
                        <th class="width80"><strong>#</strong></th>
                        <th><strong>Employee</strong></th>
                        <th><strong>Year</strong></th>
                        <th><strong>Basic Salary</strong></th>
                        <th><strong>Allowance</strong></th>
                        <th><strong>Deductions</strong></th>
                        <th><strong>Net Salary</strong></th>
                        <th><strong>Created At</strong></th>
                        <th><strong>Action</strong></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(service, index) in payrolls.data"
                        :key="service.Id"
                      >
                        <td>
                          <strong> {{ index + 1 }} </strong>
                        </td>
                        <td>
                          {{ service.staff.first_name }}
                          {{ service.staff.last_name }}
                        </td>
                        <td>{{ service.year }}</td>
                        <td>
                          <i class="mdi mdi-currency-ngn"></i>
                          {{ service.basic_salary | numeral(0, 0) }}
                        </td>
                        <td>
                          <i class="mdi mdi-currency-ngn"></i
                          >{{ service.allowance | numeral(0, 0) }}
                        </td>

                        <td>
                          <i class="mdi mdi-currency-ngn"></i
                          >{{ service.deduction | numeral(0, 0) }}
                        </td>
                        <td>
                          <i class="mdi mdi-currency-ngn"></i
                          >{{ service.net_salary | numeral(0, 0) }}
                          <a
                            v-if="service.status != 'completed'"
                            href="javascript:void()"
                            class="
                              badge badge-rounded badge-xs badge-outline-success
                              text-success
                            "
                            >{{ service.status }}
                          </a>

                          <a
                            v-else
                            href="javascript:void()"
                            class="
                              badge badge-rounded badge-xs badge-outline-success
                              text-success
                            "
                            >{{ service.status }}
                          </a>
                        </td>
                        <td>
                          {{ service.created_at | moment("from", "now") }}
                        </td>
                        <td>
                          <span>
                            <a
                              href="javascript:void()"
                              class="mr-4"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Edit"
                              @click="updateRole(role)"
                              ><i
                                class="fa fa-pencil color-muted text-success"
                              ></i>
                            </a>
                            <a
                              href="javascript:void()"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Close"
                              @click="deleteRole(role)"
                              ><i
                                class="fa fa-close color-danger text-success"
                              ></i
                            ></a>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="vld-parent">
          <loading
            :active.sync="isLoading"
            loader="spinner"
            :can-cancel="true"
            :is-full-page="fullPage"
          ></loading>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Header from "@/views/Header.vue";
import AddPayroll from "@/views/finances/AddPayroll.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

import { reloadPage } from "@/functions/Settings";

export default {
  components: {
    Header,
    Loading,
    AddPayroll,
  },
  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      showForm: false,
      edit: false,
      payrolls: "",
    };
  },
  beforeCreate() {
     reloadPage();
    
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.selected_facility = JSON.parse(
      localStorage.getItem("selected_facility")
    );
  },
  methods: {
    getPayrolls() {
      this.isLoading = true;
      this.axios
        .get(`/staff/payrolls`)
        .then((response) => {
          this.payrolls = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    deleteRole(role) {
      if (confirm("Are you Sure you want to delete?")) {
        this.isLoading = true;
        this.axios
          .patch(`/roles/${role.Id}`)
          .then((response) => {
            console.log(response);
            this.isLoading = false;
            this.getRoles();
            this.isLoading = false;
            this.$toasted.success("Deleted Successfully!", {
              position: "top-center",
              duration: 3000,
            });
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
          });
      }
    },
  },
  created() {
    this.getPayrolls();
  },
};
</script>
  