<template>
  <div>
    <Header  v-if="$route.path == '/inventory-dashboard'" />

    <div class="content-body">
      <div class="container-fluid">
        <div class="form-head d-flex mb-sm-4 mb-3">
          <div class="mr-auto">
            <h2 class="text-black font-w600">Inventory Dashboard</h2>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3 text-white">
            <div class="card bg-success">
              <div class="card-body">
                <div class="media align-items-center">
                  <div class="media-body mr-3">
                    <h2 class="fs-34 text-white font-w600">0</h2>
                    <span>Inventories</span>
                  </div>
                  <i class="lar la-object-group la-2x"></i>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="card">
              <div class="card-body">
                <div class="media align-items-center">
                  <div class="media-body mr-3">
                    <h2 class="fs-34 text-black font-w600">0</h2>
                    <span>Receive Inventory</span>
                  </div>
                  <i class="las la-cart-plus la-2x"></i>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="card bg-warning">
              <div class="card-body">
                <div class="media align-items-center">
                  <div class="media-body mr-3">
                    <h2 class="fs-34 text-black font-w600">0</h2>
                    <span>Low Stock</span>
                  </div>
                  <i class="las la-percent la-2x"></i>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="card bg-dark text-white">
              <div class="card-body">
                <div class="media align-items-center">
                  <div class="media-body mr-3">
                    <h2 class="fs-34 text-white font-w600">0</h2>
                    <span>Still in Store</span>
                  </div>
                  <i class="las la-warehouse la-2x"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <CategoriesProductsTotal />
          </div>

          <div class="col-md-12">
            <LowInStockPharmacy />
          </div>

          <div class="col-md-6">
            <LowInStockByCategory />
          </div>
          <div class="col-md-6">
            <ExpiredProductByCategory />
          </div>
        </div>

        <div class="vld-parent">
          <loading
            :active.sync="isLoading"
            loader="spinner"
            :can-cancel="true"
            :is-full-page="fullPage"
          ></loading>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Header from "@/views/Header.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import ExpiredProductByCategory from "./charts/ExpiredProductByCategory.vue";
import LowInStockByCategory from "./charts/LowInStockByCategory.vue";
import CategoriesProductsTotal from "@/views/inventories/charts/CategoriesProductsTotal.vue";
import LowInStockPharmacy from "@/views/pharmacies/charts/LowInStockPharmacy.vue";
// Init plugin
import { reloadPage } from "@/functions/Settings";

export default {
  components: {
    Header,
    Loading,
    ExpiredProductByCategory,
    LowInStockByCategory,
    CategoriesProductsTotal,
    LowInStockPharmacy,
  },
  data() {
    return {
      user: null,
      isLoading: false,
      fullPage: true,
      showForm: false,
      edit: false,
    };
  },
  beforeCreate() {
     reloadPage();
  },
  beforeMount() {},
  methods: {
    getInventories() {
      this.isLoading = true;
      this.axios
        .get(`/inventory`)
        .then((response) => {
          this.inventories = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
  },
  created() {},
};
</script>
  