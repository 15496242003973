<template>
  <div>
    <!-- <div class="content-body"> -->
    <!-- <div class="container-fluid"> -->

    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <!-- <div class="card-header">
                    <h6 class="text-success">Create roles for Staffs</h6>
                  </div> -->
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="inputPassword4">Select Employee </label>
                  <v-select
                    v-model="form_data.staff_id"
                    :reduce="(first_name) => first_name.id"
                    class="form-control"
                    label="first_name"
                    :options="staffs.data"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label for="inputPassword4">Select Month </label>
                  <v-select
                    v-model="form_data.month"
                    :reduce="(name) => name.id"
                    class="form-control"
                    label="name"
                    :options="months"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label>Year </label>
                  <input
                    type="text"
                    placeholder="2022"
                    class="form-control"
                    v-model="form_data.year"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label for="inputPassword4">Status </label>
                  <v-select
                    v-model="form_data.status"
                    class="form-control"
                    label="FullName"
                    :options="statuses"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label>Basic Salary </label>
                  <input
                    type="number"
                    placeholder="10,000"
                    class="form-control"
                    v-model="form_data.basic_salary"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Allowance </label>
                  <input
                    type="number"
                    placeholder="0.00"
                    class="form-control"
                    v-model="form_data.allowance"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label>Deductions </label>
                  <input
                    type="number"
                    placeholder="0.00"
                    class="form-control"
                    v-model="form_data.deduction"
                  />
                </div>

                <div class="form-group col-md-12">
                  <label>Net Salary </label>
                  <input
                    disabled
                    type="number"
                    placeholder="0.00"
                    class="form-control"
                    v-model="netSalary"
                  />
                </div>
              </div>

              <button class="btn btn-success btn-block" type="submit">
                Submit <i class="las la-paper-plane"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
    
    <script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Loading,
  },
  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      edit: false,
      File: "",
      patients: "",
      services: "",
      roles: "",
      staffs: "",
      form_data: {
        staff_id: "",
        allowance: "",
        basic_salary: "",
        month: "",
        year: "",
        net_salary: "",
        deduction: "",
        status: "",
      },
      statuses: ["Pending", "Paid"],
      months: [
        {
          id: "1",
          name: "January",
        },
        {
          id: "2",
          name: "February",
        },
        {
          id: "3",
          name: "March",
        },
      ],
    };
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.selected_facility = JSON.parse(
      localStorage.getItem("selected_facility")
    );
  },
  computed: {
    netSalary() {
      let form_data = this.form_data;
      let creditTotal =
        parseInt(form_data.basic_salary) + parseInt(form_data.allowance);
      let DebitTotal = form_data.deduction;
      let net_Salary = creditTotal - DebitTotal;
      return net_Salary;
    },
  },
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addPayroll();
      } else {
        this.editDept();
      }
    },
    addPayroll() {
      this.isLoading = true;
      this.axios
        .post("/staff/payrolls", {
          staff_id: this.form_data.staff_id,
          allowance: this.form_data.allowance,
          basic_salary: this.form_data.basic_salary,
          month: this.form_data.month,
          year: this.form_data.year,
          net_salary: this.netSalary,
          deduction: this.form_data.deduction,
          status: this.form_data.status,
        })
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toastr.s("Payroll Created Successfully", "Success!");
          // this.getDepts();
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");

          this.isLoading = false;
        });
    },
    updateDept() {
      //
    },

    getStaffs() {
      this.isLoading = true;
      this.axios
        .get(`/staff/profile`)
        .then((response) => {
          this.staffs = response.data.data;
          this.isLoading = false;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.$toastr.e("Server Error, Refresh page", "Error!");
        });
    },

    getRoles() {
      this.isLoading = false;
      this.selected_facility = JSON.parse(
        localStorage.getItem("selected_facility")
      );

      this.axios
        .get(`/roles`)
        .then((response) => {
          this.roles = response.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.getStaffs();
    this.getRoles();
  },
};
</script>
    