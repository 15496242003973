<template>
  <div>
    <!--  -->
    <div class="col-md-12" id="printDiv">
      <div
        class="col-md-12 mt-4"
        v-if="$route.path == '/specialized-' + $route.params.id"
      >
        <p class="h4 text-center">{{ $selected_facility.title }}</p>
        <p class="h6 text-center mt-3">{{ $selected_facility.address }}</p>
      
      </div>

      <h5 class="my-4 text-center">
          <strong class="text-success mt-4"> Optical Prescription </strong>
        </h5>

      <hr />
      <strong> Diagnosis:</strong>
      <p
        class="my-1"
        v-if="specialized_prescription != ''"
        v-html="specialized_prescription.examination.diagnosis"
      ></p>
      <!-- <p class="my-1" >{{ specialized_prescription.examination }}pp</p> -->

      <div
        class="table-responsive table-bordered"
        v-if="$route.path != '/specialized-' + $route.params.id"
      >
        <table class="table table-responsive-md">
          <thead>
            <tr>
              <th><strong>LEFT</strong></th>
              <th><strong>Examination</strong></th>
              <th><strong>RIGHT</strong></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div
                  class="col-md-12"
                  v-html="specialized_prescription.examination.left_va"
                ></div>
              </td>
              <td>VA</td>
              <td>
                <div
                  class="col-md-12"
                  v-html="specialized_prescription.examination.right_va"
                ></div>
              </td>
            </tr>

            <tr>
              <td>
                <div
                  class="col-md-12"
                  v-html="specialized_prescription.examination.left_iop"
                ></div>
              </td>
              <td>IOP</td>
              <td>
                <div
                  class="col-md-12"
                  v-html="specialized_prescription.examination.right_iop"
                ></div>
              </td>
            </tr>
            <tr>
              <td>
                <div
                  class="col-md-12"
                  v-html="specialized_prescription.examination.left_lids"
                ></div>
              </td>
              <td>Lids</td>
              <td>
                <div
                  class="col-md-12"
                  v-html="specialized_prescription.examination.right_lids"
                ></div>
              </td>
            </tr>
            <tr>
              <td>
                <div
                  class="col-md-12"
                  v-html="specialized_prescription.examination.left_conj"
                ></div>
              </td>
              <td>Conj</td>
              <td>
                <div
                  class="col-md-12"
                  v-html="specialized_prescription.examination.right_conj"
                ></div>
              </td>
            </tr>

            <tr>
              <td>
                <div
                  class="col-md-12"
                  v-html="specialized_prescription.examination.left_va"
                ></div>
              </td>
              <td>VA</td>
              <td>
                <div
                  class="col-md-12"
                  v-html="specialized_prescription.examination.right_va"
                ></div>
              </td>
            </tr>

            <tr>
              <td>
                <div
                  class="col-md-12"
                  v-html="specialized_prescription.examination.left_cornea"
                ></div>
              </td>
              <td>Cornea</td>
              <td>
                <div
                  class="col-md-12"
                  v-html="specialized_prescription.examination.right_cornea"
                ></div>
              </td>
            </tr>

            <tr>
              <td>
                <div
                  class="col-md-12"
                  v-html="specialized_prescription.examination.left_ac"
                ></div>
              </td>
              <td>AC</td>
              <td>
                <div
                  class="col-md-12"
                  v-html="specialized_prescription.examination.right_ac"
                ></div>
              </td>
            </tr>

            <tr>
              <td>
                <div
                  class="col-md-12"
                  v-html="specialized_prescription.examination.left_pupils"
                ></div>
              </td>
              <td>Pupils</td>
              <td>
                <div
                  class="col-md-12"
                  v-html="specialized_prescription.examination.right_pupils"
                ></div>
              </td>
            </tr>

            <tr>
              <td>
                <div
                  class="col-md-12"
                  v-html="specialized_prescription.examination.left_lens"
                ></div>
              </td>
              <td>Lens</td>
              <td>
                <div
                  class="col-md-12"
                  v-html="specialized_prescription.examination.right_lens"
                ></div>
              </td>
            </tr>
            <tr>
              <td>
                <div
                  class="col-md-12"
                  v-html="specialized_prescription.examination.left_fundus"
                ></div>
              </td>
              <td>Fundus</td>
              <td>
                <div
                  class="col-md-12"
                  v-html="specialized_prescription.examination.right_fundus"
                ></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="col-md-12"
        v-if="$route.path != '/specialized-' + $route.params.id"
      >
        <strong> PC:</strong>
        <p class="my-1" v-html="specialized_prescription.ophthalmic.pc"></p>
        <hr />
        <strong> HPC:</strong>
        <p class="my-1" v-html="specialized_prescription.ophthalmic.hpc"></p>
        <hr />
        <strong>POH:</strong>
        <p class="my-1" v-html="specialized_prescription.ophthalmic.poh"></p>
        <hr />
        <strong> PMH:</strong>
        <p class="my-1" v-html="specialized_prescription.ophthalmic.pmh"></p>
        <hr />
        <strong> Drug HX:</strong>
        <p
          class="my-1"
          v-html="specialized_prescription.ophthalmic.drug_hx"
        ></p>
        <hr />
        <strong> FSH:</strong>
        <p class="my-1" v-html="specialized_prescription.ophthalmic.fsh"></p>

       
      
        <hr />
      </div>

      <div>
      

        <p class="mb-2">
          Patient Name:
          <span>
            <b> {{ patient.full_name }}</b>
          </span>
        </p>

        <p class="mb-2">
          Patient Age:
          <span>
            <b> {{ patient.dob | moment("from", "now", true) }} old</b>
          </span>
        </p>

        <p class="mb-2">
          Date:
          <b> {{ record.created_at | moment("MMMM Do YYYY, h:mm:ss a") }} </b>
        </p>
        <p>
          Pupil Distance:
          {{ specialized_prescription.pupil_distance }}
        </p>

        <div class="table-responsive table-bordered">
          <table class="table table-responsive-md">
            <thead>
              <tr>
                <th class="width80"><strong>RX</strong></th>
                <th><strong>Sphere</strong></th>
                <th><strong>Cylinder</strong></th>
                <th><strong>Axis</strong></th>
                <th><strong>Prism</strong></th>
                <th><strong>ADD</strong></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="optical in specialized_prescription.table_data"
                v-bind:key="optical.RX"
              >
                <td>
                  <strong>{{ optical.RX }} </strong>
                </td>
                <td>
                  {{ optical.Sphere }}
                </td>
                <td>
                  {{ optical.Cylinder }}
                </td>
                <td>
                  {{ optical.Axis }}
                </td>
                <td>
                  {{ optical.Prism }}
                </td>
                <td>
                  {{ optical.ADD }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <p
        v-for="(other, index) in specialized_prescription.others"
        :key="other"
        class="mt-2"
      >
        {{ index + 1 }}) {{ other }}
        <i class="las la-check-circle"></i>
      </p>

      <div
        class="form-row"
        v-if="$route.path == '/specialized-' + $route.params.id"
      >
        <div class="col-md-6">
          <label for="">Doctor Signature</label>
          <span class="mt-4 ml-1"> {{ ellipsis }} </span>
        </div>

        <div class="col-md-6">
          <label for="">Date</label>
          <span class="mt-4 ml-1"> {{ record.created_at   | moment("MMMM Do YYYY") }} </span>
        </div>
      </div>

      <hr />
    </div>

    <button
      class="btn btn-success"
      v-if="$route.path == '/specialized-' + $route.params.id"
      @click="printMe"
    >
      Print
    </button>

    <a
      class="btn btn-success ml-2"
      v-if="$route.path == '/specialized-' + $route.params.id"
      :href="`/record-${$route.params.id}`"
    >
      Go Back
    </a>
  </div>
</template>
<script>
// import Header from "@/views/Header.vue";

export default {
  components: {
    // Header
  },
  props: ["specialized_prescription"],
  data() {
    return {
      ellipsis: "..............................",
      record: {
        created_at: "",
      },
      patient: "",
    };
  },
  methods: {
    getRecord() {
      //  if (this.$route.path == `/specialized/${this.$route.params.id}`) {
      this.axios
        .get(`/staff/records/${this.$route.params.id}`)
        .then((response) => {
          this.record = response.data.data;
          this.patient = response.data.data.patient;
          this.specialized_prescription = JSON.parse(
            response.data.data.specialized_prescription
          );
        })
        .catch((error) => {
          console.error(error);
        });

      //  }
      //  else{
      //   console('fetccher')
      //  }
    },
    printMe() {
      var printContents = document.getElementById("printDiv").innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    },
  },
  created() {
    this.getRecord();
  },
};
</script>
