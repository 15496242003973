var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"settings-form"},[_c('div',{staticClass:"card-body"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":"inputPassword4"}},[_vm._v("Select Urgency ")]),_c('v-select',{staticClass:"form-control",attrs:{"label":"Urgency","options":_vm.urgencies},model:{value:(_vm.form_data.urgency),callback:function ($$v) {_vm.$set(_vm.form_data, "urgency", $$v)},expression:"form_data.urgency"}})],1),_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":"inputPassword4"}},[_vm._v("Select Status ")]),_c('v-select',{staticClass:"form-control",attrs:{"label":"Status","options":_vm.statuses},model:{value:(_vm.form_data.status),callback:function ($$v) {_vm.$set(_vm.form_data, "status", $$v)},expression:"form_data.status"}})],1)]),_c('br'),_c('div',{staticClass:"col-md-12"},[_c('p',{staticClass:"h4"},[_vm._v("Sample Details:")]),_c('b-form-group',{attrs:{"label":"Samples:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox-group',{staticClass:"mb-3 mt-3 ml-3 mr-3",attrs:{"id":"samples","options":_vm.samples,"aria-describedby":ariaDescribedby,"name":"flavour-1"},model:{value:(_vm.form_data.selected_samples),callback:function ($$v) {_vm.$set(_vm.form_data, "selected_samples", $$v)},expression:"form_data.selected_samples"}})]}}])})],1),_c('br'),_c('div',{staticClass:"col-md-12"},[_c('p',{staticClass:"h4"},[_vm._v("Examination Requested")]),_c('b-form-group',{attrs:{"label":"Profile Test:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox-group',{staticClass:"mb-3",attrs:{"id":"profile_test","options":_vm.profiles,"aria-describedby":ariaDescribedby,"name":"flavour-1"},model:{value:(_vm.form_data.profile_test),callback:function ($$v) {_vm.$set(_vm.form_data, "profile_test", $$v)},expression:"form_data.profile_test"}})]}}])}),_c('b-form-group',{attrs:{"label":"Biochemistry:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox-group',{staticClass:"mb-3",attrs:{"id":"biochem","options":_vm.biochemistries,"aria-describedby":ariaDescribedby,"name":"flavour-1"},model:{value:(_vm.form_data.biochemistry),callback:function ($$v) {_vm.$set(_vm.form_data, "biochemistry", $$v)},expression:"form_data.biochemistry"}})]}}])}),_c('b-form-group',{attrs:{"label":"Haematology:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox-group',{staticClass:"mb-3",attrs:{"id":"haeme","options":_vm.haematologies,"aria-describedby":ariaDescribedby,"name":"flavour-1"},model:{value:(_vm.form_data.haematology),callback:function ($$v) {_vm.$set(_vm.form_data, "haematology", $$v)},expression:"form_data.haematology"}})]}}])}),_c('b-form-group',{attrs:{"label":"Microbiology:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox-group',{staticClass:"mb-3",attrs:{"id":"microbiology","options":_vm.microbiologies,"aria-describedby":ariaDescribedby,"name":"flavour-1"},model:{value:(_vm.form_data.microbiology),callback:function ($$v) {_vm.$set(_vm.form_data, "microbiology", $$v)},expression:"form_data.microbiology"}})]}}])}),_c('b-form-group',{attrs:{"label":"Anatomical Pathology:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox-group',{staticClass:"mb-3",attrs:{"id":"pathology","options":_vm.pathologies,"aria-describedby":ariaDescribedby,"name":"flavour-1"},model:{value:(_vm.form_data.pathology),callback:function ($$v) {_vm.$set(_vm.form_data, "pathology", $$v)},expression:"form_data.pathology"}})]}}])})],1),_c('div',{staticClass:"col-md-12"},[_c('p',{staticClass:"h4"},[_vm._v("Cervical Cytology")]),_c('b-form-group',{attrs:{"label":"Samples:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox-group',{staticClass:"mb-3",attrs:{"id":"cystology","options":_vm.cystologies,"aria-describedby":ariaDescribedby,"name":"flavour-1"},model:{value:(_vm.form_data.cystology),callback:function ($$v) {_vm.$set(_vm.form_data, "cystology", $$v)},expression:"form_data.cystology"}})]}}])})],1),_c('br'),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('label',[_vm._v(" Additional Tests (Describe below): ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form_data.additional),expression:"form_data.additional"}],staticClass:"form-control",attrs:{"placeholder":"Type your Tests...","rows":"4"},domProps:{"value":(_vm.form_data.additional)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form_data, "additional", $event.target.value)}}})])]),_vm._m(0)])])])])]),_c('div',{staticClass:"vld-parent"},[_c('loading',{attrs:{"active":_vm.isLoading,"loader":"spinner","can-cancel":true,"is-full-page":_vm.fullPage},on:{"update:active":function($event){_vm.isLoading=$event}}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"btn btn-success btn-block",attrs:{"type":"submit"}},[_vm._v(" Submit "),_c('i',{staticClass:"las la-paper-plane"})])}]

export { render, staticRenderFns }