<template>
  <div class="">
   
    <div class="row">
      <div class="col-md-4 text-white">
        <div class="card bg-success">
          <div class="card-body">
            <div class="media align-items-center">
              <div class="media-body mr-3">
                <h2 class="fs-34 text-white font-w600">0</h2>
                <span>Products</span>
              </div>
              <i class="las la-tablets la-2x"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <div class="media align-items-center">
              <div class="media-body mr-3">
                <h2 class="fs-34 text-black font-w600"> {{ metrics.patients }} </h2>
                <span>Patients</span>
              </div>
              <i class="las la-user-injured la-2x"></i>
            </div>
          </div>
        </div>
      </div>

   

      <div class="col-md-4">
        <div class="card bg-warning">
          <div class="card-body">
            <div class="media align-items-center">
              <div class="media-body mr-3">
                <h2 class="fs-34 text-black font-w600">0</h2>
                <span>Low Stock</span>
              </div>
              <i class="las la-percent la-2x"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
        <LowInStockPharmacy/>
     

    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
</template>
  
  <script>
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import LowInStockPharmacy from "@/views/pharmacies/charts/LowInStockPharmacy.vue";



export default {
  components: {
    Loading,
    LowInStockPharmacy
  },
  props:['metrics'],
  data() {
    return {
      user: null,
      checked: false,
      isLoading: false,
      fullPage: true,
      auth_user: "",
    };
  },
  beforeMount() {},

  methods: {},
  created() {},
};
</script>
  