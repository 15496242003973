import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: "vuex", // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: state => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
});

export default new Vuex.Store({
  // plugins: [vuexLocalStorage.plugin],
  plugins: [vuexLocalStorage.plugin],

  state: {
    // app_url: "https://apis.smartmedicare.smartfocuss.com/api",
    app_url: null,
    jwt: null,
    user: {},
    admin: {},
    staff: {},
    user_type: null,
    selected_facility: {},
    password: "olsaql09",
    mode: true, //true is online while false is offline
    syncing: {
      last_sync: "5/11/2023 08:00:00",
    },
  },
  mutations: {
    INITIALIZE_STORE() {
      let admin = JSON.parse(localStorage.getItem("user_admin"));
      let staff = JSON.parse(localStorage.getItem("user_staff"));

      let user = admin == null ? staff : admin;
      this.state.user = user;
      this.state.jwt = localStorage.getItem("jwt");
      this.state.app_url = localStorage.getItem("app_url");
      this.state.user_type = localStorage.getItem("user_type");
      this.state.selected_facility = JSON.parse(
        localStorage.getItem("selected_facility")
      );
    },
    UPDATE_URL(state, url) {
      state.app_url = url;
    },
    UPDATE_TOKEN(state, payload) {
      state.jwt = payload;
    },
    UPDATE_USER(state, payload, user_type) {
      state.user = payload;
      state.user_type = user_type;
    },
  },
  actions: {
    updateURL(context, url) {
      context.commit("UPDATE_URL", url);
    },
    updateToken(context, payload) {
      context.commit("UPDATE_TOKEN", payload);
    },
    updateUser(context, payload, user_type) {
      context.commit("UPDATE_USER", payload, user_type);
    },
  },
  modules: {},
});
