<template>
  <div>
    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="inputPassword4">Relationship</label>
                  <v-select
                    v-model="form_data.relationship_type"
                    class="form-control"
                    :options="relationships"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label>Full Name</label>
                  <input
                    type="text"
                    placeholder="Full Name"
                    class="form-control"
                    v-model="form_data.full_name"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label>Phone Number</label>
                  <input
                    type="text"
                    placeholder="Phone Number"
                    class="form-control"
                    v-model="form_data.phone_no"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label for="inputPassword4">Blood Group </label>
                  <v-select
                    v-model="form_data.blood_group"
                    class="form-control"
                    :options="blood_groups"
                  />
                </div>
              </div>

              <div class="col-md-12 mt-3">
                <button class="btn btn-success btn-block" type="submit">
                  Submit <i class="las la-paper-plane"></i>
                </button>
              </div>

              <hr class="mt-4 mb-4" />
            </form>

          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
 
</template>
    
<script>

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Loading,
  },
  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      edit: false,
      categories: "",
      services: "",
      form_data: {
        patient_id: this.$route.params.id,
        facility_id: this.$selected_facility.id,
        relationship_type: "",
        full_name: "",
        phone_no: "",
        blood_group: "",
      },
      kins: "",
      relationships: ["Parent", "Spouse/Partner", "Child/Ward", "Other"],
      blood_groups: ["A+", "B+", "A-", "B-", "AB+", "AB-", "O+", "0-"],
    };
  },
  beforeMount() {},
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addForm();
      } else {
        this.updateForm();
      }
    },
    addForm() {
      this.isLoading = true;

      this.axios
        .post("/staff/next-of-kin", this.form_data)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toastr.s("Kin Created Successfully", "Success!");
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");
          this.isLoading = false;
        });
    },
    updateDept() {
      //
    },

    getCategories() {
      this.isLoading = true;
      this.selected_facility = JSON.parse(
        localStorage.getItem("selected_facility")
      );

      this.axios
        .get(`/categories`)
        .then((response) => {
          this.patients = response.data;
          this.isLoading = false;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.getCategories();
  },
};
</script>
    