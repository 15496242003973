<template>
  <div>
    <Header />

    <div class="content-body">
      <div class="container-fluid">
        <div class="col-md-12">
          <div class="form-head d-flex mb-sm-4 mb-3">
            <div class="mr-auto">
              <h1 class="text-success font-w700">Download SmartMedicare</h1>
              <p class="mb-0">
                Download the latest version of our solution here
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5" v-for="app in applications" :key="app.name">
            <div class="card bg-dark text-white">
              <div class="card-body">
                <div class="media align-items-center">
                  <div class="media-body mr-3">
                    <p class="text-white font-w600">
                      {{ app.os }}
                    </p>
                    <h3 class="text-white" style="margin-bottom: 50px">
                      {{ app.name }}
                    </h3>

                    <div class="py-4">
                      <a
                        :href="`${app.url}`"
                        class="btn btn-success text-white"
                      >
                        Download
                        <i class="las la-download"></i>
                      </a>
                    </div>
                  </div>
                  <!-- <a :href="`${app.url}`" class="text-white">
                    <i class="las la-download la-3x"></i>
                  </a> -->
                </div>
              </div>
            </div>
          </div>

          <div>
            <p>
              <!-- By installing SmartMedicare, you agree to our Terms & Privacy Policy. -->
            </p>
          </div>
        </div>

        <div class="vld-parent">
          <loading
            :active.sync="isLoading"
            loader="spinner"
            :can-cancel="true"
            :is-full-page="fullPage"
          ></loading>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Header from "@/views/Header.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Header,
    Loading,
  },
  data() {
    return {
      applications: "",
      metrics: "",
    };
  },
  beforeCreate() {},
  beforeMount() {
    this.axios
      .get(`/get-applications`)
      .then((response) => {
        this.applications = response.data;
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
        this.isLoading = false;
      });
  },
  methods: {},
  created() {},
};
</script>
  