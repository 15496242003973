<template>
  <div class="">

    <div class="row">
      <div class="col-xl-3 col-sm-6">
        <div class="card bg-dark text-white">
          <div class="card-body">
            <div class="media align-items-center">
              <div class="media-body mr-3">
                <h2 class="fs-34 text-white font-w600">{{ metrics.patients }}  </h2>
                <span>Total Patients</span>
              </div>
              <a href="/patients-all" class="text-white">
              <i class="las la-user-injured la-3x"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-sm-6">
        <div class="card bg-success text-white">
          <div class="card-body">
            <div class="media align-items-center">
              <div class="media-body mr-3">
                <h2 class="fs-34 text-white font-w600">{{ metrics.visits }} </h2>
                <span>Visits</span>
              </div>
              <i class="las la-calendar la-3x success"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-sm-6">
        <div class="card">
          <div class="card-body">
            <div class="media align-items-center">
              <div class="media-body mr-3">
                <h2 class="fs-34 text-black font-w600">{{ metrics.todays_visits }}  </h2>
                <span>Today's Visits</span>
              </div>
              <i class="las la-calendar-day la-3x"></i>
            </div>
          </div>
        </div>
      </div>
     

      <div class="col-xl-3 col-sm-6">
        <div class="card bg-info text-dark">
          <div class="card-body">
            <div class="media align-items-center">
              <div class="media-body mr-3">
                <h2 class="fs-34 text-dark font-w600">{{ metrics.records }} </h2>
                <span>Patient Records</span>
              </div>
             <a href="/all-records" class="text-black">

            <i class="las la-notes-medical la-3x"></i>
          </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- <div class="col-md-12"> -->
       
          <div class="col-md-6">
            <PatientPercentage />
          </div>

          <div class="col-md-6">
            <div class="card">
              <div class="card-header border-0 pb-0">
                <h3 class="fs-20 text-black mb-0">Patient Engagements</h3>
              </div>
              <div class="card-body pb-0">
                <ul class="users-sm mb-3">
                  <li><img src="images/users/6.png" alt="" /></li>
                  <li><img src="images/users/7.png" alt="" /></li>
                  <li><img src="images/users/8.png" alt="" /></li>
                  <li><img src="images/users/9.png" alt="" /></li>
                  <li><img src="images/users/10.png" alt="" /></li>
                </ul>
                <div class="d-flex align-items-center">
                  <svg
                    class="mr-3"
                    width="30"
                    height="19"
                    viewBox="0 0 30 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M28.8124 0.990788C29.6459 1.71558 29.734 2.97884 29.0092 3.81236L20.2578 13.8765C18.859 15.4851 16.4444 15.7141 14.7681 14.397L10.6176 11.1359L3.37074 17.9564C2.56639 18.7134 1.30064 18.6751 0.543606 17.8707C-0.213427 17.0664 -0.175071 15.8006 0.629276 15.0436L7.8761 8.22306C9.317 6.86692 11.5329 6.76809 13.0888 7.99059L17.2394 11.2517L25.9908 1.18764C26.7156 0.354128 27.9788 0.265993 28.8124 0.990788Z"
                      fill="#007A64"
                    />
                  </svg>
                  <div>
                    <p class="fs-12 mb-0">Customer</p>
                    <span class="fs-22 text-black font-w600">765 Person</span>
                  </div>
                </div>
                <div id="lineChart"></div>
              </div>
            </div>
          </div>
      
      <!-- </div> -->
      <!-- <div class="col-xl-6">
        <div class="row">
          <div class="col-xl-12">
            <div class="card appointment-schedule">
              <div class="card-header pb-0 border-0">
                <h3 class="fs-20 text-black mb-0">Appointment Schedule</h3>
                <div class="dropdown ml-auto">
                  <div class="btn-link p-2 bg-light" data-toggle="dropdown">
                    <i class="las la-ellipsis-v la-2x text-dark"></i>
                  </div>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item text-black" href="javascript:;"
                      >Info</a
                    >
                    <a class="dropdown-item text-black" href="javascript:;"
                      >Details</a
                    >
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-xl-6 col-xxl-12 col-md-6">
                    <div class="appointment-calender">
                      <input
                        type="text"
                        class="form-control d-none"
                        id="datetimepicker1"
                      />
                    </div>
                  </div>
                  <div
                    class="col-xl-6 col-xxl-12 col-md-6 height415 dz-scroll"
                    id="appointment-schedule"
                  >
                    <div class="d-flex pb-3 border-bottom mb-3 align-items-end">
                      <div class="mr-auto">
                        <p class="text-black font-w600 mb-2">
                          Wednesday, June 3th
                        </p>
                        <ul>
                          <li><i class="las la-clock"></i>09:00 - 10:30 AM</li>
                          <li><i class="las la-user"></i>Dr. Samantha</li>
                        </ul>
                      </div>
                      <a
                        href="javascript:void(0)"
                        class="text-success mr-3 mb-2"
                      >
                        <i class="las la-check-circle scale5"></i>
                      </a>
                      <a href="javascript:void(0)" class="text-danger mb-2">
                        <i class="las la-times-circle scale5"></i>
                      </a>
                    </div>
                    <div class="d-flex pb-3 border-bottom mb-3 align-items-end">
                      <div class="mr-auto">
                        <p class="text-black font-w600 mb-2">
                          Tuesday, June 16th
                        </p>
                        <ul>
                          <li><i class="las la-clock"></i>09:00 - 10:30 AM</li>
                          <li><i class="las la-user"></i>Dr. Samantha</li>
                        </ul>
                      </div>
                      <a
                        href="javascript:void(0)"
                        class="text-success mr-3 mb-2"
                      >
                        <i class="las la-check-circle scale5"></i>
                      </a>
                      <a href="javascript:void(0)" class="text-danger mb-2">
                        <i class="las la-times-circle scale5"></i>
                      </a>
                    </div>
                    <div class="d-flex pb-3 border-bottom mb-3 align-items-end">
                      <div class="mr-auto">
                        <p class="text-black font-w600 mb-2">
                          Saturday, June 27th
                        </p>
                        <ul>
                          <li><i class="las la-clock"></i>09:00 - 10:30 AM</li>
                          <li><i class="las la-user"></i>Dr. Samantha</li>
                        </ul>
                      </div>
                      <a
                        href="javascript:void(0)"
                        class="text-success mr-3 mb-2"
                      >
                        <i class="las la-check-circle scale5"></i>
                      </a>
                      <a href="javascript:void(0)" class="text-danger mb-2">
                        <i class="las la-times-circle scale5"></i>
                      </a>
                    </div>
                    <div class="d-flex pb-3 border-bottom mb-3 align-items-end">
                      <div class="mr-auto">
                        <p class="text-black font-w600 mb-2">
                          Wednesday, June 3th
                        </p>
                        <ul>
                          <li><i class="las la-clock"></i>09:00 - 10:30 AM</li>
                          <li><i class="las la-user"></i>Dr. Samantha</li>
                        </ul>
                      </div>
                      <a
                        href="javascript:void(0)"
                        class="text-success mr-3 mb-2"
                      >
                        <i class="las la-check-circle scale5"></i>
                      </a>
                      <a href="javascript:void(0)" class="text-danger mb-2">
                        <i class="las la-times-circle scale5"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->


    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
</template>
  
  <script>
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import PatientPercentage from "./PatientPercentage.vue";
export default {
  components: {
    Loading,
    PatientPercentage,
  },
  props:['metrics'],
  data() {
    return {
      user: null,
      checked: false,
      isLoading: false,
      fullPage: true,
      selected_facility: null,
      auth_user: "",
    };
  },
  beforeMount() {},

  methods: {},
  created() {},
};
</script>
  