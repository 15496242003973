<template>
  <div>
    <div class="">
      <div class="card">
        <div class="card-header py-3">
          <h4 class="card-title">Blood Issued-out</h4>

          <a href="javascript:void(0)" class="btn btn-success mr-3">
            <i class="las la-file-export scale5 mr-3"></i> Export to CSV</a
          >
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-responsive-md">
              <thead>
                <tr>
                  <th class="width80"><strong>#</strong></th>
                  <th><strong>Patient</strong></th>
                  <th><strong>Blood Group</strong></th>
                  <th><strong>Bags</strong></th>
                  <th><strong>Created At</strong></th>
                  <th><strong>Action</strong></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(blood, index) in bloodouts.data" :key="blood.id">
                  <td>
                    <strong> {{ index + 1 }} </strong>
                  </td>
                  <td>
                    {{ blood.patient.first_name }}
                    {{ blood.patient.last_name }}
                  </td>
                  <td>{{ blood.blood_group.blood_group }}</td>
                  <td>{{ blood.blood_group.remaining_bags }}</td>
                  <td>{{ blood.created_at }}</td>
                  <td>
                    <span>
                      <a
                        href="javascript:void()"
                        class="mr-4"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Edit"
                        @click="updateRole(role)"
                        ><i class="fa fa-pencil color-muted text-success"></i>
                      </a>
                      <a
                        href="javascript:void()"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Close"
                        @click="deleteRole(role)"
                        ><i class="fa fa-close color-danger text-success"></i
                      ></a>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
</template>
    
    <script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Loading,
  },
  data() {
    return {
      user: null,
      isLoading: false,
      fullPage: true,
      showForm: true,
      edit: false,
      bloodouts: "",
    };
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addDept();
      } else {
        this.editDept();
      }
    },
    addward() {
      this.isLoading = true;
      this.axios
        .post("/roles", {
          Title: this.role.Title,
          Description: this.role.Description,
        })
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toastr.s("Ward Created Successfully", "Success!");
          this.getRoles();
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");

          this.isLoading = false;
        });
    },
    updateRole(role) {
      this.isLoading = true;
      this.axios
        .put(`/roles`, role)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.getRoles();
          this.isLoading = false;
          this.$toasted.success("Updated Successfully!", {
            position: "top-center",
            duration: 3000,
          });
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    getBloodOuts() {
      this.isLoading = true;

      this.axios
        .get(`/staff/blood-out`)
        .then((response) => {
          this.bloodouts = response.data.data;
          console.log(response);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    deleteRole(role) {
      if (confirm("Are you Sure you want to delete?")) {
        this.isLoading = true;
        this.axios
          .patch(`/roles/${role.Id}`)
          .then((response) => {
            console.log(response);
            this.isLoading = false;
            this.getRoles();
            this.isLoading = false;
            this.$toasted.success("Deleted Successfully!", {
              position: "top-center",
              duration: 3000,
            });
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
          });
      }
    },
  },
  created() {
    this.getBloodOuts();
  },
};
</script>
    