<template>
  <div>
    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header">
                    <h4 class="card-title pt-4 pb-4">Appointments</h4>
                  </div>
                  <div class="card-body">
                    <vue-good-table
                      :columns="columns"
                      :rows="appointments.data"
                      :search-options="{ enabled: true }"
                      @on-row-click="onRowClick"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

   

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
              
              <script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Loading,

  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      columns: [
        {
          label: "Appointment With",
          field: "staff.full_name",
          type: "text",
        },

        {
          label: "Details",
          field: "description",
          type: "text",
        },

        {
          label: "Time",
          field: "time",
          type: "text",
        },

        {
          label: "Date",
          field: "date",
          type: "text",
        },
      ],
      appointments: [],
    };
  },
  beforeMount() {},
  methods: {
    fetchData() {
      this.isLoading = true;

      this.axios
        .get(`/staff/patient-appointments/${this.$route.params.id}`)
        .then((response) => {
          this.appointments = response.data;
          this.isLoading = false;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    onRowClick(params) {
      this.$router.push(`/appointment/${params.row.id}`);
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
              