<template>
  <div>
    <div class="col-md-12">
      <div class="card">
        <div class="settings-form">
          <div class="card-body">
            <form @submit.prevent="submitForm">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Vehicle Number</label>
                  <input
                    type="text"
                    placeholder="VH20292"
                    class="form-control"
                    v-model="form_data.vehicle_number"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label>Vehicle Model</label>
                  <input
                    type="text"
                    placeholder="Hiace, etc"
                    class="form-control"
                    v-model="form_data.vehicle_model"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label>Year Made</label>
                  <input
                    class="form-control"
                    v-model="form_data.year_made"
                    type="number"
                    placeholder="YYYY"
                    min="1980"
                    max="2025"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label for="inputPassword4"
                    >Select Driver
                  </label>
                  <v-select
                    v-model="form_data.staff_id"
                    :reduce="(first_name) => first_name.id"
                    class="form-control"
                    label="full_name"
                    :options="staffs.data"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label for="inputPassword4">OwnerShip Type </label>
                  <v-select
                    v-model="form_data.ownership_type"
                    class="form-control"
                    :options="ownerships"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label for="inputPassword4">Status </label>
                  <v-select
                    v-model="form_data.status"
                    class="form-control"
                    label="FullName"
                    :options="statuses"
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label> Notes</label>
                  <textarea
                    class="form-control"
                    placeholder="Type your message..."
                    rows="5"
                    v-model="form_data.Symptoms"
                  ></textarea>
                </div>
              </div>

              <button class="btn btn-success btn-block" type="submit">
                Submit <i class="las la-paper-plane"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        loader="spinner"
        :can-cancel="true"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
    
    <script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Loading,
  },
  data() {
    return {
      user: null,
      selected_facility: null,
      isLoading: false,
      fullPage: true,
      edit: false,
      patients: "",
      staffs: "",
      form_data: {
        staff_id: "",
        patient_id: "",
        status: "",
        ownership_type: "",
        description: "",
        vehicle_number: "",
        vehicle_model: "",
        year_made: "",
      },
      statuses: ["Active", "Inactive"],
      ownerships: ["Owned", "Contractual"],
    };
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    submitForm() {
      if (this.edit == false) {
        this.addForm();
      } else {
        this.updateForm();
      }
    },
    addForm() {
      this.isLoading = true;

      this.axios
        .post("/staff/ambulances", this.form_data)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$toastr.s("Ambulance Created Successfully", "Success!");
          this.clearForm();
          this.$emit("update");
        })
        .catch((error) => {
          console.log(error.response);
          this.$toastr.e("Error on Adding!");

          this.isLoading = false;
        });
    },
    updateForm() {
      this.isLoading = true;
      this.axios
        .patch(`/staff/ambulances/${this.form_data.id}`, this.form_data)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.clearForm();
          this.$emit("update");
          this.$toastr.s("Ambulance Updated Successfully", "Success!");
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
          this.$toastr.e("not successful", "Error!");
        });
    },

    setValue(value) {
      this.edit = true;
      this.form_data = value;
      console.log(value);
    },

    getPatients() {
      this.isLoading = true;
      this.axios
        .get(`/patients`)
        .then((response) => {
          this.patients = response.data;
          this.isLoading = false;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },

    getStaffs() {
      this.isLoading = true;
      this.axios
        .get(`/staff/profile`)
        .then((response) => {
          this.staffs = response.data.data;
          this.isLoading = false;
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    clearForm() {
      this.form_data.id = "";
      this.form_data.staff_id = "";
      this.form_data.patient_id = "";
      this.form_data.status = "";
      this.form_data.ownership_type = "";
      this.form_data.description = "";
      this.form_data.vehicle_number = "";
      this.form_data.vehicle_model = "";
      this.form_data.year_made = "";
      this.edit = false;
    },
  },
  created() {
    this.getPatients();
    this.getStaffs();
  },
};
</script>
    